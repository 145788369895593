<template>
  <section v-if="filtersToDisplay.length">
    <WebccHeading :level="2" color="txt" :title="title" :space="'mb-6'" class="text-2xl font-medium" />
    <div class="-ml-4 mb-8 flex flex-wrap gap-y-2 text-txt-strongest">
      <WebccLink v-for="(item, i) in filtersToDisplay" :key="i" class="border-r px-4 last:border-r-0 hover:text-cta" :to="item.href">
        {{ item.label }} ({{ item.amount }})
      </WebccLink>
    </div>
  </section>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.views.search.searchTeaser.' })

const facets = ref<Facets>()
const { facets: searchFacets } = storeToRefs(useSearch())

const additionalBody = computed(() => useConfdata().additionalBody as SearchPageBody)
const searchfilterconfs = computed(() => additionalBody.value.searchfilterconfs ?? [])
const destinationName = computed(() => getDestinationName(useConfdata().destination))
const heading = computed(() => translatep('headingDestinationNoPlaceholder'))
const title = computed(() => [destinationName.value, heading.value].filter(Boolean).join(' - '))
const hasFilters = computed(() => !!useConfdata().destination.filters?.length)

const nonEmptyFacets = computed(() => {
  const result: Record<string, Record<string, number>> & { totalHits?: number } = {}
  Object.entries(facets.value ?? {}).forEach(([key, value]) => {
    result[key] = {}
    Object.entries(value as { [key: string]: number }).forEach(([key1, value1]) => {
      if (value1 > 20) {
        result[key][key1] = value1
      }
    })
  })
  return result
})

const filtersToDisplay: Ref<(FilterLink & { amount: number })[]> = computed(() => {
  const result: (FilterLink & { amount: number })[] = []
  additionalBody.value.WithFilters.forEach((filter) => {
    const filterCopy: FilterLink & { amount: number } = JSON.parse(JSON.stringify(filter))
    let found = false
    const path = filterCopy.name
    // https://trello.com/c/OT7fEOZo/87-diskrepanzen-zwischen-filtern-in-conf-api-und-filtern-in-webcc-api
    const filtername = searchfilterconfs.value.find((filterconf) => filterconf.filter === path)?.filter
    // Ist der Filter Teil der Attribute?
    Object.entries(nonEmptyFacets.value.attributes || {}).forEach(([key, value]) => {
      if (key === filtername) {
        filterCopy.amount = value
        result.push(filterCopy)
        found = true
      }
    })
    if (!found) {
      // Ist der Filter Teil der Distanzen?
      if (['sea', 'lake', 'ski', 'center'].includes(filtername || '')) {
        const filterValue = searchfilterconfs.value.find((filterconf) => filterconf.filter === path)?.slug.value
        Object.entries(nonEmptyFacets.value[filtername!] || {}).forEach(([key, value]) => {
          if (key === filterValue) {
            filterCopy.amount = value
            result.push(filterCopy)
          }
        })
        // Ist der Filter ein Pax-Filter?
      } else if (filtername === 'pax') {
        const filterValue = searchfilterconfs.value.find((filterconf) => filterconf.filter === path)?.slug.value || ''
        filterCopy.amount = nonEmptyFacets.value.pax?.[filterValue]
        // Gibt es für die Paxzahl Ergebnisse?
        if (filterCopy.amount > 4) {
          result.push(filterCopy)
        }
      }
    }
  })
  return result.sort((a, b) => (a.label < b.label ? -1 : 1))
})

watch(searchFacets, updateFacets)

onMounted(updateFacets)

async function updateFacets() {
  if (!useParams().iframe && useNavigation().isCanonicalPage && hasFilters.value) {
    // Fetching facets based only on geo params for canonical pages with filters
    const { data } = await useSearchApi().search({
      salesoffice: useConfdata().salesoffice?.code,
      currency: useConfdata().currency,
      language: useL10N().language,
      country: useConfdata().destination.countryCode,
      region: useConfdata().destination.regionCodeFull,
      place: useConfdata().destination.placeCodeFull,
    })

    facets.value = data.value?.facets
  } else {
    facets.value = useSearch().facets
  }
}
</script>
