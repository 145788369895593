<template>
  <div class="flex flex-col gap-4">
    <button
      class="flex flex-row items-center justify-center gap-1 rounded-lg border bg-thm p-2 text-thm-contrast hover:bg-thm-hover"
      @click="showModal = !showModal"
    >
      <Text key-prop="catalog.create" />
    </button>
    <button
      class="flex flex-row items-center justify-center gap-1 rounded-lg border bg-thm p-2 text-thm-contrast hover:bg-thm-hover"
      @click="$emit('clear-favorites')"
    >
      <Text key-prop="removeAll" />
    </button>
    <FavPersonalCatalog
      :show="showModal"
      :privacy-policy="privacyPolicy"
      :accoms="accoms"
      :language="language"
      :sales-office-id="salesOfficeId"
      @close="showModal = false"
    />
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.favorites.Actions.'
defineExpose({ trPrefix })

defineProps<{
  accoms: ResultDocument[]
  language: string
  salesOfficeId: string
  privacyPolicy: string
}>()

defineEmits<{ (e: 'clear-favorites'): void }>()

const showModal = ref(false)
</script>
