import type { TranslationsType } from '~/translations/master'

export enum VacancyDayStatus {
  Clickable = 'Clickable',
  ClickableNoVacancies = 'ClickableNoVacancies',
  Available = 'Available',
  Unavailable = 'Unavailable',
  Neutral = 'Neutral',
  Invalid = 'Invalid',
}

export interface TooltipParams {
  key: keyof typeof TranslationsType.www.components.views.details.Calendar | ''
  count?: number
}

export function useVacancyDays(days: VacancyDay[], selectedStartDate: Ref<Date | undefined>) {
  const firstPossibleStart = daysAdd(new Date(), 1)
  const minDate = computed(() => selectedStartDate.value ?? firstPossibleStart)
  const maxRange = computed(() => DURATION_MAX)

  function dayOf(date?: Date): VacancyDay | undefined {
    return date && days.find((day) => datesEqual(day.date, date))
  }

  function getSpecialDates(): Date[] {
    return days
      .filter((day) => day.specials?.length)
      .map(({ date }) => parse(date))
      .filter(isClickable)
  }

  function getAvailableDates(): Date[] | undefined {
    if (!selectedStartDate.value) return undefined

    return Array.from(Array(maxRange.value + 1).keys())
      .map((i) => daysAdd(minDate.value, i))
      .filter(isClickable)
  }

  function getStatus(date: Date): VacancyDayStatus {
    const day = dayOf(date)
    if (!day) {
      return VacancyDayStatus.Invalid
    }

    if (selectedStartDate.value) {
      if (datesEqual(date, minDate.value)) {
        return VacancyDayStatus.Clickable
      }
      if (!dateBetween(date, minDate.value, daysAdd(minDate.value, maxRange.value))) {
        return VacancyDayStatus.Unavailable
      }
      if (day.isTripEnd && dayOf(minDate.value)?.durations?.includes(diff(date, minDate.value))) {
        return VacancyDayStatus.Clickable
      }
      return VacancyDayStatus.Unavailable
    } else {
      if (day.isTripStart) {
        return VacancyDayStatus.Clickable
      }
      if (day.isTripInner || day.isTripEnd) {
        return VacancyDayStatus.Available
      }
      return VacancyDayStatus.Unavailable
    }
  }
  function isClickable(date: Date): boolean {
    return getStatus(date) === VacancyDayStatus.Clickable
  }

  function getTooltip(date?: Date): TooltipParams {
    if (!date) {
      return { key: '', count: 0 }
    }

    const day = dayOf(date)
    if (!day) {
      return { key: '', count: 0 }
    }

    if (selectedStartDate.value) {
      const minDay = dayOf(minDate.value)!
      const daysDiff = diff(date, minDate.value)
      if (datesEqual(date, minDate.value)) {
        return { key: 'selectedArrival_s', count: minDay.minStay }
      }
      if (dateBefore(date, new Date())) {
        return { key: 'dateInPast' }
      }
      if (dateBefore(date, minDate.value)) {
        return { key: 'arrivalBeforeDeparture' }
      }
      if (minDay.minStay! > daysDiff) {
        return { key: 'noDepartureMinStay_s', count: minDay.minStay }
      }
      if (minDay.maxStay! < daysDiff) {
        return { key: 'noDepartureMaxStay_s', count: minDay.maxStay }
      }
      if (!day.isTripEnd) {
        return { key: 'noDeparture' }
      }
      if (!minDay.durations?.includes(daysDiff)) {
        return { key: 'noDeparture' }
      }
      return { key: 'departureAvailable' }
    } else {
      if (day.isTripStart && day.isTripEnd) {
        return { key: 'arrivalDeparture_s', count: day.minStay }
      }
      if (day.isTripStart) {
        return { key: 'arrival_s', count: day.minStay }
      }
      if (day.isTripInner) {
        return { key: 'noArrival' }
      }
      if (day.isTripEnd) {
        return { key: 'departureOnly' }
      }
      return { key: 'notAvailable' }
    }
  }

  function getDurations(): number[] {
    return dayOf(selectedStartDate.value)?.durations ?? []
  }

  return {
    firstPossibleStart,
    firstPossibleStartDate: firstPossibleStart,
    minDate,
    maxRange,
    getSpecialDates,
    getAvailableDates,
    getStatus,
    isClickable,
    getTooltip,
    getDurations,
  }
}
