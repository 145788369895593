<template>
  <div v-if="apiResponse" class="flex flex-col gap-12">
    <SearchObjectTeaser
      v-for="(entry, i) in apiResponse"
      :key="i"
      :language-prefix="languagePrefix"
      :locale="locale"
      :domain="domain"
      :currency="currency"
      :data="entry"
      :destination="placename || regionname || countryname || ''"
      :filter="relevantFilters[i]"
      :type="filter || 'destination'"
    />
  </div>
</template>

<script setup lang="ts">
const types = {
  destination: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition'],
  pool: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition'],
  sea: ['reviews', 'sustainable', 'wlan', 'parking', 'aircondition'],
  pets: ['reviews', 'sustainable', 'wlan', 'parking', 'hiking'],
  skiing: ['reviews', 'sustainable', 'wlan', 'fireplace', 'cheapcheap'],
}

const filters = {
  reviews: 'sorting:-averagerating,stars:3,pagesize:3',
  sustainable: 'sustainable:true,sorting:-lastbookday,stars:3,pagesize:3',
  wlan: 'wlan:true,sorting:-lastbookday,stars:3,pagesize:3',
  parking: 'parking:true,sorting:-lastbookday,stars:3,pagesize:3',
  aircondition: 'aircondition:true,sorting:-lastbookday,stars:3,pagesize:3',
  hiking: 'hiking:true,sorting:-lastbookday,stars:3,pagesize:3',
  fireplace: 'fireplace:true,sorting:-lastbookday,stars:3,pagesize:3',
  cheapcheap: 'cheapcheap:true,sorting:-lastbookday,stars:3,pagesize:3',
}

const basefilters = {
  pool: ',pool:true',
  sea: ',sea:500',
  pets: ',pets:true',
  skiing: ',skiing:true',
}

const props = defineProps<{
  country?: string
  region?: string
  place?: string
  countryname?: string
  regionname?: string
  placename?: string
  filter?: string
  languagePrefix: string
  locale: string
  domain: string
  salesoffice: string
  currency: string
}>()

const apiResponse: Ref<Result[] | null> = ref(null)

const relevantFilters = computed(() => {
  return types[props.filter as keyof typeof types] || types.destination
})

onMounted(async () => {
  const season = useSeason(useSearch().filters.checkin)
  const params: { locale: string; request: string[] } = { locale: props.locale, request: [] }
  relevantFilters.value.forEach((filter) => {
    const country = props.country ? 'country:' + props.country + ',' : ''
    const region = props.region ? 'region:' + props.region + ',' : ''
    const place = props.place ? 'place:' + props.place + ',' : ''
    let f = filters[filter as keyof typeof filters]
    if (types[props.filter as keyof typeof types]) {
      f += `${basefilters[props.filter as keyof typeof basefilters]}`
    }
    params.request.push(`${country}${region}${place}${f},currency:${props.currency},salesoffice:${props.salesoffice},season:${season}`)
  })

  const { data } = await useSearchApi().searchMulti(params)

  if ((data.value ?? []).filter((entry) => entry.totalHits >= 10).length >= 3) {
    apiResponse.value = data.value!
  }
})
</script>
