<template>
  <section class="flex flex-col gap-4">
    <template v-for="(item, i) in content">
      <article v-if="lso && item.code === 'localOffice_detail_information'" :key="'lso-' + i" class="flex flex-col gap-4 rounded bg-bgr p-4 shadow md:flex-row">
        <div class="md:min-w-[20%] flex flex-row md:justify-center">
          <WebccIcon name="usp/service" class="h-16 w-20 text-thm" />
        </div>
        <div v-if="item.text" class="prose-richtext" v-html="item.text"></div>
        <div v-else class="font-bold">
          {{ item.title }}
        </div>
      </article>

      <article
        v-if="swissTourism && item.code === 'swissTourism_detail_information'"
        :key="'swissTourism-' + i"
        class="flex flex-col gap-4 rounded bg-bgr p-4 shadow md:flex-row"
      >
        <div class="md:min-w-[20%] flex flex-row md:justify-center">
          <WebccIcon name="detailpage/apartment-classification" class="h-20 w-20" filled />
        </div>
        <div v-if="item.text" class="prose-richtext" v-html="item.text"></div>
        <div v-else class="font-bold">
          {{ item.title }}
        </div>
      </article>

      <article
        v-if="swisspeakResort && item.code === 'swisspeak_detail_information'"
        :key="'swisspeakResort-' + i"
        class="flex flex-col gap-4 rounded bg-bgr p-4 shadow md:flex-row"
      >
        <div class="md:min-w-[20%] flex flex-row md:justify-center">
          <div
            v-track:impression="{
              event: `detail:labelImpression`,
              params: 'swisspeak-resort',
            }"
            class="w-20 text-thm"
          >
            <img src="/images/resort.png" alt="resort" class="h-auto" />
          </div>
        </div>
        <div v-if="item.text" class="prose-richtext" v-html="item.text"></div>
        <div v-else class="font-bold">
          {{ item.title }}
        </div>
      </article>

      <article v-if="casa && item.code === 'casa_detail_information'" :key="'casa-' + i" class="flex flex-col gap-4 rounded bg-bgr p-4 shadow md:flex-row">
        <div class="md:min-w-[20%] flex flex-row md:justify-center">
          <WebccIcon v-track:impression="{ event: 'detail:labelImpression', params: 'casa' }" name="detailpage/logo_casa" class="h-12 w-28" filled />
        </div>
        <div v-if="item.text" class="prose-richtext" v-html="item.text"></div>
        <div v-else class="font-bold">
          {{ item.title }}
        </div>
      </article>
    </template>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.LabelInfo.'
defineExpose({ trPrefix })

interface Props {
  casa: boolean
  lso: boolean
  swisspeakResort: boolean
  swissTourism: boolean
  content: Badge[]
}

defineProps<Props>()
</script>
