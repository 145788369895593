<template>
  <span class="flex" :class="colorClasses">
    <WebccIcon
      v-for="(heart, index) in heartIconNames"
      :key="index"
      :class="iconClasses"
      :name="heart"
      role="img"
      aria-hidden="true"
      focusable="false"
      :alt="rating + '/ 5'"
      :filled="false"
    />
  </span>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    rating: number
    color?: 'theme' | 'highlight' | 'grey' | 'white'
    size?: '3' | '5'
  }>(),
  {
    color: 'theme',
    size: '5',
  },
)

const disabled = computed(() => {
  return props.rating === 0
})
const heartIconNames = computed(() => {
  // Math.round verhält sich wie ColdFusion beim Runden (vgl. Hitbox.cfm)
  const roundedRating = Math.round(props.rating)
  return [1, 2, 3, 4, 5].map((elem) => {
    if (elem <= roundedRating) return 'reviews/heart'
    if (elem - props.rating < 1) return 'reviews/heart-half'
    return 'reviews/heart-empty'
  })
  // alte Implementierung, falls man die später mal reaktivieren möchte
  // return [...Array(5).keys()].map(elem => (elem + 0.3 <= this.rating && elem + 0.7 >= this.rating ? "heart-half" : elem < this.rating ? "heart" : "heart-empty"));
})
const colorClasses = computed(() => {
  const colorClass = { theme: 'text-thm', highlight: 'text-hlt', grey: 'text-txt-weak', white: 'text-txt-contrast' }[props.color]
  return disabled.value ? 'text-txt-weak' : colorClass
})
const iconClasses = computed(() => {
  let size = ''
  switch (props.size) {
    case '3':
      size = 'h-3 w-3'
      break
    case '5':
      size = 'h-5 w-5'
      break
  }
  return 'mr-1 last:mr-0 ' + size
})
</script>
