<template>
  <div v-on-click-outside.bubble="onClickOutside" class="w-full bg-bgr md:rounded-lg md:border md:border-bgr-300 md:shadow-lg">
    <header class="relative flex flex-row items-center justify-center space-x-4 border-b border-bgr-300 p-4">
      <div
        class="cursor-pointer border-b duration-100 hover:text-thm-hover"
        :class="flexTrip.flexibleCalendar ? 'border-transparent text-txt-weak' : 'border-thm text-thm'"
        @click.stop="switchFlexible(false)"
      >
        <Text :prefix="prefixSearchDates" key-prop="calendar" />
      </div>
      <div
        class="cursor-pointer border-b duration-100 hover:text-thm-hover"
        :class="flexTrip.flexibleCalendar ? 'border-thm text-thm' : 'border-transparent text-txt-weak'"
        @click.stop="switchFlexible(true)"
      >
        <Text :prefix="prefixSearchDates" key-prop="flexible" />
      </div>
      <div class="absolute right-5 top-5" @click="close">
        <WebccIcon name="site/x" class="h-6 w-6 text-txt-weak" />
      </div>
    </header>

    <main class="mt-2 mb-[.625rem] py-4">
      <div v-if="flexTrip.flexibleCalendar">
        <Text key-prop="h_duration" class="mb-2 text-center text-xl font-medium" component="h2" />
        <div v-if="flexTrip.duration" class="mb-2 text-center text-thm">{{ flexTrip.durationText }}</div>

        <div
          class="mx-auto mb-4 grid w-fit max-w-[95%] grid-cols-1 items-center justify-items-center gap-4 sm:mb-6 sm:grid-cols-2 sm:gap-6 md:flex md:justify-center"
        >
          <WebccButton
            v-for="duration in presetDurations"
            :key="duration"
            class="h-12 w-48 rounded-lg"
            variant="grey"
            framed
            :class="flexTrip.duration === duration ? 'border-bgr-500 bg-bgr-400' : ''"
            @click="selectDuration(duration)"
          >
            <span class="font-medium">{{ flexTrip.getDurationText(duration) }}</span>
          </WebccButton>
          <select
            :value="flexTrip.duration && presetDurations.includes(flexTrip.duration) ? 0 : flexTrip.duration"
            class="h-12 w-48 rounded-lg border border-bgr-300 bg-bgr-100 pr-8 font-medium hover:border-bgr-400 hover:bg-bgr-300 focus:bg-bgr-300 focus:outline-none focus:ring-2 focus:ring-thm focus:ring-offset-2"
            @input="selectDuration(+($event.target as HTMLInputElement).value)"
          >
            <Text component="option" key-prop="custom" disabled hidden class="padding-x-4" selected value="0" />
            <Text v-for="i in DURATION_MAX" :key="i" class="padding-x-4 text-txt" :value="i" key-prop="night_s" component="option" :count="i" />
          </select>
        </div>

        <Text key-prop="h_range" class="mb-2 text-center text-xl font-medium" component="h2" />

        <Text key-prop="tip_range" class="mb-2 text-center text-thm" component="div" />
      </div>

      <div class="flex flex-row justify-center">
        <WebccDateRangepicker
          :key="flexTrip.updateKey"
          v-model="flexTrip.range"
          :locale="useL10N().locale"
          :min-length="flexTrip.duration ?? DURATION_MIN"
          :max-length="flexTrip.flexibleCalendar ? DURATION_MAX_FLEX : DURATION_MAX"
          @select-start="trackArrival"
          @update:model-value="trackDeparture"
        />
      </div>
    </main>

    <footer class="fixed bottom-0 left-0 w-screen pb-4 md:relative md:bottom-auto md:w-auto">
      <div class="flex flex-row items-center justify-between border-t border-bgr-300 p-4">
        <WebccButton variant="grey" :disabled="!flexTrip.valid" @click="reset">
          <Text :prefix="prefixSearchDates" key-prop="reset" class="font-semibold" />
        </WebccButton>
        <WebccButton variant="grey" :disabled="!flexTrip.valid" @click="apply">
          <Text :prefix="prefixSearchDates" key-prop="apply" class="font-semibold" />
        </WebccButton>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'

const prefixSearchDates = 'www.components.elements.SearchDates.'
const prefixFlexibleDates = 'www.components.elements.FlexibleDates.'

defineExpose({ trPrefix: prefixFlexibleDates })

const emit = defineEmits<{ close: [] }>()

const flexTrip = useFlexTrip()

const presetDurations = computed(() => [7, 14])

function switchFlexible(flexible: boolean) {
  flexTrip.flexibleCalendar = flexible

  if (flexible) flexTrip.changeDuration(DURATION_DEFAULT)

  window.sessionStorage.setItem('flexible-search', flexible ? 'true' : 'false')
  useTracking().handlers?.search.datesModeSwitch(flexible)
}

function selectDuration(duration: number) {
  useTracking().handlers?.search.datesDurationSelectFlex(duration)
  flexTrip.changeDuration(duration)
}

function reset() {
  flexTrip.reset()
  useTracking().handlers?.search.datesClear(flexTrip.flexibleCalendar)
}

function apply() {
  useTracking().handlers?.search.datesApply(flexTrip.flexibleCalendar)
  close()
}

function close() {
  emit('close')
}

function onClickOutside() {
  if (useScreen().isSmall) return

  close()
}

function trackArrival(arrival?: Date) {
  if (!arrival) return

  useTracking().handlers?.search.datesArrivalSelect(arrival, flexTrip.flexibleCalendar)
}

function trackDeparture({ start, end }: DateRange) {
  if (!start || !end) return

  useTracking().handlers?.search.datesDurationSelect(calculateDuration(start, end), flexTrip.flexibleCalendar)
}
</script>
