import type { LocationQuery } from 'vue-router'

export default defineNuxtRouteMiddleware((to, _) => {
  const query = { ...to.query, ...parseLegacyParams(to.query) } as LocationQuery

  const parsedParams: Params = {
    ...parseTripParams(query),
    ...parseCoordParams(query),
    pax: tryParseInt(query.pax?.toString()),
    season: query.season?.toString() as Season,
  }

  const otherParams = Object.fromEntries(
    Object.entries(query)
      .filter(([param]) => param in ALLOWED_QUERY_PARAMS && !(param in parsedParams))
      .map(([param, value]) => [param, value === 'true' ? true : value?.toString()]),
  )

  useParams().init(removeFalsy({ ...parsedParams, ...otherParams }))
})

function parseTripParams(query: LocationQuery): Params {
  const checkin = (query.checkin || query.arrivalDate || query.arrivaldate)?.toString()
  const checkout = (query.checkout || query.departureDate || query.departuredate)?.toString()
  const duration = tryParseInt(query.duration?.toString())

  if (!checkin || (!checkout && !duration)) return { checkin: undefined, checkout: undefined, duration: undefined }

  const params: Params = {}
  params.checkin = checkin
  params.checkout = checkout ?? toDate(calculateCheckoutDate(checkin, duration!))
  params.duration = duration && duration <= calculateDuration(checkin, params.checkout) ? duration : undefined
  return params
}

function parseCoordParams(query: LocationQuery): Params {
  const [lat, lon] = [query.lat, query.lon].map((value) => tryParseFloat(value?.toString()))
  const radius = query.radius && /^\d+km/.test(query.radius.toString()) ? query.radius.toString() : '20km'
  const name = query.name?.toString()
  const heading = query.heading?.toString()

  if (!lat || !lon) return { lat: undefined, lon: undefined, radius: undefined, name: undefined, heading: undefined }

  return { lat, lon, radius, name, heading }
}

function parseLegacyParams(query: LocationQuery): Params {
  const distanceParams = {
    sea: true,
    lake: true,
    ski: true,
    center: true,
  }
  const legacyParams: Record<string, string | boolean> = {
    golf: 'golfing',
    skiarea: 'skiing',
    biking_plains: 'cycling',
    crosscountry_skiing: 'crosscountryskiing',
    balcony_or_terrace: 'balcony-or-terrace',
    whirlpool: 'hottub',
    sorting: true,
    ...distanceParams,
  }
  const sortingMap: Record<string, SortingOptions> = {
    ascAge: 'age',
    descLastBookDay: '-lastbookday',
    descAverageRating: '-averagerating',
    ascPrice: 'price',
    descPrice: '-price',
    ascPerson: 'pax',
    descPerson: '-pax',
  }
  const distanceMap: Record<string, string> = {
    '0-100m': '100',
    '0-500m': '500',
    '0-1km': '1000',
    '0-2km': '2000',
    '0-5km': '5000',
    '0-10km': '10000',
  }

  return Object.fromEntries(
    Object.entries(query)
      .filter(([param]) => legacyParams[param])
      .map(([param, value]) => {
        if (typeof legacyParams[param] === 'string') return [legacyParams[param], true]
        if (param in distanceParams && isNaN(Number(value))) return [param, distanceMap[value?.toString() ?? '']]
        if (param === 'sorting') return ['sorting', sortingMap[value?.toString() ?? ''] ?? value?.toString()]
        return [param, value?.toString()]
      }),
  )
}
