<template>
  <main class="container-fluid-md my-8">
    <WebccHeading :level="1" :title="translatep('title')" space="mb-4" class="text-center" />

    <section class="relative min-h-1/4-screen rounded bg-bgr p-4 text-txt shadow">
      <div v-if="success === true" class="">
        <WebccHeading :level="2" :title="translatep('success.heading')" space="my-2" />
        <Text key-prop="success.text" component="p" />
      </div>

      <div v-else-if="success === false" class="">
        <WebccHeading :level="2" :title="translatep('error.heading')" />
        <Text key-prop="error.text" component="p" />
      </div>

      <WebccLoaderOverlay v-else absolute rounded="md" />
    </section>
  </main>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.pages.emails._hash.optin.' })

const success = ref<boolean>()

onMounted(async () => {
  const { error } = await useEmails().optIn()

  success.value = !error.value

  if (!success.value) return

  useTracking().handlers?.newsletter.optIn()
  useTracking().handlers?.newsletter.optInHeID(useEmails().hash.value)
})
</script>
