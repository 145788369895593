<template>
  <div class="pr-2">
    <!-- <div class="mt-4 rounded bg-white p-4 shadow"> -->

    <div v-if="numReviews" itemscope itemtype="http://schema.org/Product">
      <span itemprop="name" :content="code"></span>
      <div itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
        <span itemprop="worstRating" content="1"></span>
        <span itemprop="bestRating" content="5"></span>
        <span itemprop="ratingCount" :content="numReviews"></span>
        <span itemprop="ratingValue" :content="rating"></span>
      </div>
    </div>

    <div class="flex flex-wrap items-center justify-between">
      <RatingHearts :rating="rating" :title="rating + ' / 5'" :color="rating > 0 ? 'theme' : 'grey'" size="5" />
      <Text v-if="rating > 0" :key-prop="'labels.' + roundedRating" component="span" class="text-thm" />
    </div>
    <div class="mt-2 flex cursor-pointer flex-wrap items-center justify-between" @click="ratingClick">
      <WebccLink :class="numReviews > 0 ? 'text-base' : 'text-sm'" class="mr-2 block" hash="#reviews">
        <Text :prefix="trPrefix" key-prop="review_s" :count="numReviews" />
      </WebccLink>
      <span v-if="rating > 0" class="flex-shrink-0 text-center">
        <span class="text-thm">{{ formatNumber(rating, '1-digit', undefined) }}</span>
        <span class="text-weak"> / 5</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
const trPrefix = 'www.components.views.details.Rating.'
defineExpose({ trPrefix })

interface Props {
  code: string
  rating: number
  numReviews: number
}

const props = withDefaults(defineProps<Props>(), {
  code: '',
  rating: 0,
  numReviews: 0,
})

const emit = defineEmits<{ (e: 'scroll-to-reviews'): void }>()

const roundedRating: Ref<number> = computed(() => {
  return Math.round(props.rating)
})

function ratingClick(): void {
  if (props.rating > 0) {
    useTracking().handlers?.detail.ratingHeartsClick(props.rating)
    emit('scroll-to-reviews')
  }
}
</script>
