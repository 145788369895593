<template>
  <section v-if="enabled" v-track:impression="'search:seoContentImpression'" class="container-fluid-lg mb-14">
    <WebccHeading :level="2" :space="'mt-0 mb-6'" class="text-txt-stronger font-medium">
      <Text key-prop="introHeading" :params="{ destination }" />
    </WebccHeading>
    <p class="text-base">{{ text }}</p>
  </section>
</template>

<script setup lang="ts">
const ENABLED_LANGUAGES = new Set(['nl', 'fr', 'de'])

defineExpose({ trPrefix: 'www.components.views.search.content.SightsAndContent.' })

const props = defineProps<{
  modelValue: ContentAssetsIntro
}>()

const { text } = props.modelValue

const enabled = computed(() => ENABLED_LANGUAGES.has(useL10N().language))
const destination = computed(() => getDestinationName(useConfdata().destination))
</script>
