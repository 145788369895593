const SEARCH_API_ALLOWED_PARAMS = Object.freeze<SearchApiParams>({
  language: undefined,
  currency: undefined,
  season: undefined,
  page: undefined,
  pagesize: undefined,
  sorting: undefined,
  brand: undefined,
  salesoffice: undefined,
  q: undefined,
  checkin: undefined,
  checkout: undefined,
  duration: undefined,
  country: undefined,
  region: undefined,
  place: undefined,
  code: undefined,
  geo: undefined,
  n: undefined,
  w: undefined,
  s: undefined,
  e: undefined,
  zoom: undefined,
  lat: undefined,
  lon: undefined,
  radius: undefined,
  pax: undefined,
  pets: undefined,
  house: undefined,
  apartment: undefined,
  villa: undefined,
  chalet: undefined,
  farmhouse: undefined,
  detached: undefined,
  stars: undefined,
  rating: undefined,
  reviews: undefined,
  bedrooms: undefined,
  bathrooms: undefined,
  sea: undefined,
  lake: undefined,
  ski: undefined,
  center: undefined,
  wlan: undefined,
  aircondition: undefined,
  parking: undefined,
  garage: undefined,
  'balcony-or-terrace': undefined,
  dishwasher: undefined,
  washingmachine: undefined,
  tv: undefined,
  sea_or_lake_view: undefined,
  bbq: undefined,
  boat: undefined,
  cots: undefined,
  hottub: undefined,
  fireplace: undefined,
  sauna: undefined,
  wheelchair: undefined,
  charging_station: undefined,
  pool: undefined,
  pool_private: undefined,
  pool_indoor: undefined,
  pool_children: undefined,
  'min-price': undefined,
  maxPrice: undefined,
  discount: undefined,
  special_offer: undefined,
  last_minute: undefined,
  early_booker: undefined,
  'discount-20': undefined,
  cheapcheap: undefined,
  familyfriendly: undefined,
  holiday_resort: undefined,
  residence: undefined,
  citytrips: undefined,
  utoring: undefined,
  casa: undefined,
  swiss_peak: undefined,
  workation: undefined,
  sustainable: undefined,
  skiing: undefined,
  hiking: undefined,
  golfing: undefined,
  cycling: undefined,
  wellness: undefined,
  tennis: undefined,
  surfing: undefined,
  sailing: undefined,
  mountainbiking: undefined,
  riding: undefined,
  crosscountryskiing: undefined,
  fishing: undefined,
  fishing_certified: undefined,
  studio: undefined,
  lso: undefined,
  bo: undefined,
  rooms: undefined,
  facet: undefined,
})

const SEARCH_API_PAGESIZE_DEFAULT = 20

export default function useSearchApi() {
  async function search(query: SearchApiParams) {
    const { data, error, pending } = await useFetch<Result>('/search-api/v1/engines/accomtrips', { params: mapSearchApiParams(query) })

    data.value?.docs.forEach((doc, index) => {
      // Filling in the global doc position - mainly used for tracking.
      // Not the best place for this, but at least centralized.
      doc.position = calcPosition(index, query.page ?? 1, query.pagesize ?? SEARCH_API_PAGESIZE_DEFAULT)
    })

    return { data, error, pending }
  }

  async function searchMulti(params: { request: string[]; locale?: string }) {
    const result = await useFetch<LegacyResult[]>(`/webcc-api/v1/search-multi/${params.locale ?? '-'}/v5`, { params })

    result.data.value?.forEach((entry) => {
      const season = entry._params?.season ?? getSeason(entry._params?.checkin)
      entry.docs.forEach((doc) => {
        doc.image = mapLegacyImage(doc, season)
        doc.trip = doc.trip && mapLegacyTrip(doc.trip)
      })
    })

    return result
  }

  return { search, searchMulti }
}

/**
 * Maps param values and leaves only those params that the new Search API can accept:
 * - converts dates
 * - removes redundant params because otherwise the API will return "Bad Request"
 *
 * @param params Raw params.
 * @returns Only paras that the new Search API can accept.
 */
function mapSearchApiParams(params: SearchApiParams): SearchApiParams {
  return Object.fromEntries(
    Object.entries({
      ...params,
      checkin: params.checkin ? toServerDate(params.checkin) : undefined,
      checkout: params.checkout ? toServerDate(params.checkout) : undefined,
    }).filter(([key, value]) => key in SEARCH_API_ALLOWED_PARAMS && value),
  )
}

function mapLegacyImage(accom: LegacyResultDocument, season: Season): MediaItem {
  return {
    id: accom[`image_${season}`],
    main: true,
    season,
    type: accom[`image_${season}_type`],
    caption: '',
    order_s: 1,
    order_w: 1,
  }
}

function mapLegacyTrip(trip: Trip): Trip {
  return { ...trip, reduction: calcLegacyTripDiscount(trip) }
}

/**
 * Calculates a discount for legacy trip format.
 *
 * The legacy search API returns `reduction` in money rather than as a percentage.
 *
 * @param trip Trip with `price` and `reduction` as money.
 * @returns Discount in percentage.
 */
function calcLegacyTripDiscount({ reduction, price }: Trip): number {
  return reduction && price ? (reduction / (price + reduction)) * 100 : 0
}
