import * as Sentry from '@sentry/vue'
import { beforeSend } from '~/utils/errors'

export default defineNuxtPlugin((nuxtApp) => {
  const { enabled, dsn, environment } = useRuntimeConfig().public.sentry

  if (!enabled) return
  if (!dsn) return console.warn('Sentry DSN not set, skipping Sentry initialization from client')

  console.log('Sentry DSN set, initializing Sentry from client')

  Sentry.init({
    beforeSend,
    dsn,
    environment,
    app: nuxtApp.vueApp,
    integrations: [Sentry.browserTracingIntegration({ router: useRouter() }), Sentry.replayIntegration()],
    // tracesSampleRate: 1.0, // Change in prod
    // tracePropagationTargets: ["localhost"], // to control for which URLs distributed tracing should be enabled
    // replaysSessionSampleRate: 0.1, // Change in prod
    // replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
