<template>
  <div v-if="price">
    <div class="text-thm">
      <Text key-prop="from" class="mr-1" />
      <span class="text-lg font-bold">{{ formatCurrency(price, undefined, currency) }}</span>
      <span
        v-if="discount"
        v-tooltip="translatep('saved', { amount: formatCurrency(discount, undefined, currency) })"
        class="ml-2 text-sm text-hlt line-through"
      >
        {{ formatCurrency(origPrice, undefined, currency) }}
      </span>
    </div>
    <!-- pax -->
    <div class="flex flex-row gap-1 text-xs">
      <Text v-if="pax.length" key-prop="upToPax" :count="Math.max(...pax)" />
      <span v-else>Missing Pax Information</span> |
      <span>{{ formatDateRange(checkin, checkout) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Price.'
defineExpose({ trPrefix })

const props = defineProps<{
  trip: Trip
  currency: string
}>()

const { checkin, checkout, pax, price, reduction, duration } = toRefs(props.trip)
const origPrice = computed(() => price.value / ((100 - (reduction?.value ?? 0)) / 100))
const discount = computed(() => origPrice.value - price.value)
</script>
