<template>
  <div
    class="md:container-fluid-lg fixed bottom-0 flex h-full w-full max-w-full justify-center overflow-y-auto px-6 sm:h-auto sm:-translate-y-9 sm:bg-transparent z-[9]"
    :class="{ 'bg-bgr-800 bg-opacity-80': !configIsOpen }"
  >
    <div class="consent-banner my-8 max-h-[85vh] overflow-y-auto rounded bg-bgr p-6" tabindex="-1" role="dialog" aria-hidden="true">
      <Text key-prop="cookiesTitle" component="p" class="mb-4 text-lg font-medium sm:text-xl" />
      <Text key-prop="cookiesInfotextBefore" />&nbsp;<Text
        key-prop="cookiesConfigureHere"
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="() => openConfig()"
      /><Text key-prop="cookiesInfotextAfter" />&nbsp;<Text key-prop="cookiesMoreInfoBefore" />&nbsp;<Text
        key-prop="cookiesMoreInfoHere"
        class="inline-block cursor-pointer font-medium underline hover:no-underline"
        @click="() => openPrivacypolicy()"
      />&nbsp;<Text key-prop="cookiesMoreInfoAfter" />
      <div class="mt-6 flex flex-col items-center justify-start gap-4 sm:flex-row">
        <WebccButton class="h-11 w-48 font-semibold" variant="theme" @click="sendChoice({ statistics: true, other: true })">
          <Text key-prop="cookiesAccept" />
        </WebccButton>
        <Text
          key-prop="cookiesDecline"
          class="cursor-pointer underline transition-all hover:no-underline"
          @click="sendChoice({ statistics: false, other: false })"
        />
        <Text key-prop="cookiesConfigureLink" class="cursor-pointer underline transition-all hover:no-underline" @click="() => openConfig()" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'components.cookieConsent.' })
defineProps<{
  configIsOpen: boolean
  openConfig: Function
  openPrivacypolicy: Function
}>()

const emit = defineEmits<{
  (e: 'save-choice', value: Choice): void
  (e: 'close'): void
}>()

// body scroll lock
const screen = useScreen()
const xs = screen.breakpoints.smaller('sm')
const windowScrollLock = useScrollLock(window)
watch(xs, (isScreenSmall) => (windowScrollLock.value = isScreenSmall))

function sendChoice(values: Choice) {
  console.log('save Choice aus Banner', { statistics: values.statistics, other: values.other })
  emit('save-choice', { statistics: values.statistics, other: values.other })
}
</script>

<style scoped>
.consent-banner {
  /* TODO: How to do this in tailwind? */
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.12);
}
</style>
