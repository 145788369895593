<template>
  <div class="relative inline-block w-full text-left">
    <button
      type="button"
      class="inline-flex w-full items-center justify-start rounded border-0 border-bgr-300 bg-bgr px-4 py-1.5 text-left text-sm font-medium text-txt shadow hover:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-thm active:bg-bgr-50"
      aria-expanded="true"
      aria-haspopup="true"
      @click.stop="useToggler().toggle(filter, { closeOthers: true })"
    >
      <WebccIcon v-if="iconPath?.length" :name="iconPath" class="mr-2 h-4 w-4 shrink-0" />
      <div v-if="label" class="mr-auto">{{ label }}</div>
      <div v-else class="mr-auto text-txt-weak">
        <Text :key-prop="filter" />
      </div>

      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
      </svg>
    </button>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-0"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-0"
    >
      <div
        v-if="useToggler().toggles(filter).value"
        class="absolute right-0 z-10 w-full origin-top-right border border-bgr-500 bg-bgr shadow-lg focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <!--
      current styling of pseudo-select
      class="absolute right-0 z-10 mt-0.5 hidden w-full origin-top-right rounded-md bg-bgr shadow-lg ring-1 ring-txt ring-opacity-5 focus:outline-none"
       -->

        <div class="h-fit max-h-96 overflow-y-auto overflow-x-hidden py-1" role="none">
          <button
            type="button"
            role="menuitem"
            class="flex w-full items-center justify-start px-4 py-1 text-left text-sm text-txt hover:bg-bgr-100 hover:text-txt-stronger focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none"
            @click="select()"
          >
            &#8212;
          </button>
          <button
            v-for="o in sortedOptions"
            :key="o.code"
            type="button"
            role="menuitem"
            class="flex w-full items-center justify-start px-4 py-1 text-left text-sm text-txt hover:bg-bgr-100 hover:text-txt-stronger focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none"
            @click="select(o)"
          >
            <WebccIcon v-if="o?.code && hasIcons" :name="'flags/' + o.code.toLowerCase()" filled class="mr-2 h-4 w-4 shrink-0" />
            <div class="mr-auto">{{ o.name }}</div>
            <small v-if="o.count">({{ o.count }})</small>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.views.search.filter.' })
const props = withDefaults(defineProps<{ filter: string; options: DestFacet[]; hasIcons?: boolean }>(), {
  hasIcons: false,
})
const emit = defineEmits<{ (e: 'change', value?: string): void }>()
useToggler().register(props.filter)

const iconPath = computed(() => {
  return `searchpage/${props.filter}`
})

const sortedOptions = computed(() => {
  return Array.from(props.options).sort((a, b) => a.name.localeCompare(b.name))
})

const model = computed({
  get() {
    return useSearch().filters[props.filter as keyof Params] as string
  },
  set(value: string | undefined) {
    emit('change', value)
  },
})

const label = computed((): string | undefined => {
  return props.options.find((o) => o.code === model.value)?.name
})

function select(o?: DestFacet) {
  model.value = o?.code
  useToggler().toggle(props.filter, { closeOthers: false })
}
</script>
