<template>
  <div
    class="absolute z-[500] -ml-[8.7rem] -mt-96 w-[280px] origin-[center_bottom] cursor-default bg-bgr text-sm text-txt opacity-100"
    @click.stop
    @dblclick.stop
    @touchstart.stop
  >
    <div class="relative h-0 w-full overflow-hidden pb-[75%] opacity-100 [background:url(/map/placeholder-object.jpg)_50%/cover_no-repeat]">
      <img v-if="image" class="!max-w-full" :src="image" />
      <div class="absolute bottom-4 left-4 flex flex-row gap-2 text-txt-contrast">
        <WebccIcon v-for="index in stars" :key="index" name="detailpage/evaluation-star" class="h-4 w-4" />
      </div>
    </div>

    <header
      class="p-2 after:absolute after:left-1/2 after:top-full after:-ml-[10px] after:border-x-[10px] after:border-t-[10px] after:border-x-transparent after:border-t-white after:content-['']"
    >
      <div class="flex flex-row items-center justify-between">
        <p v-if="title" class="pr-1 text-base">{{ title }}</p>

        <p v-if="rating" class="ml-1 flex flex-row items-center">
          <WebccIcon name="feather/heart" class="h-4 w-4 text-thm" />
          <span class="ml-1 text-thm">{{ formatRating(rating) }}</span>
          <span class="object-card__rating__total">&nbsp;/&nbsp;{{ RATING_MAX }}</span>
        </p>
      </div>

      <strong v-if="price" class="text-thm">
        <span class="font-bold text-lg"><Text key-prop="priceFrom" />&nbsp;{{ formatCurrency(price) }}</span>
        <span v-if="duration" class="object-card__pricing__duration">&nbsp;/&nbsp;<Text key-prop="night_s" :count="duration" /> </span>
      </strong>
    </header>

    <footer class="flex flex-row justify-between border border-t p-2">
      <nav v-if="count > 1" class="flex w-full flex-row items-center justify-between pr-2">
        <button class="p-2" @click="prevAccommodation">
          <WebccIcon name="site/chevron-left" class="h-6 w-6" filled />
        </button>
        <span>
          <span>{{ currentIndex + 1 }}</span>
          <span> / <Text key-prop="objects" :count="count" /> </span>
        </span>
        <button class="p-2" @click="nextAccommodation">
          <WebccIcon name="site/chevron-right" class="h-6 w-6" filled />
        </button>
      </nav>

      <WebccButton variant="cta" @click="openAccommodation">
        <Text key-prop="discover" />
      </WebccButton>
    </footer>

    <button class="absolute -right-4 -top-4 rounded-full bg-bgr p-1 hover:bg-thm hover:text-txt-contrast" @click="close">
      <WebccIcon name="site/x" class="h-6 w-6" />
    </button>
  </div>
</template>

<script setup lang="ts">
const accommodations = ref<ResultDocument[]>([])
const currentIndex = ref(0)

defineExpose({ trPrefix: 'www.components.views.search.InfoWindowLegacy.', accommodations })

const emit = defineEmits<{
  close: []
}>()

const count = computed(() => accommodations.value.length ?? 0)
const accommodation = computed(() => accommodations.value[currentIndex.value])
const stars = computed(() => Math.round(accommodation.value?.stars ?? 0))
const rating = computed(() => accommodation.value?.rating)
const price = computed(() => accommodation.value?.trip?.price)
const duration = computed(() => accommodation.value?.trip?.duration)
const image = computed(() => accommodation.value?.image && useURLs().buildImageUrl(accommodation.value?.image.id, 'tr:n-srp_hitbox_l'))
const url = computed(() => accommodation.value && useURLs().buildAccommodationUrl(accommodation.value).toString())

const title = computed(() => {
  return Object.entries({ pax_s: accommodation.value?.pax, room_s: accommodation.value?.rooms })
    .filter(([, count]) => count)
    .map(([key, count]) => translatep(key, {}, count))
    .join(' | ')
})

watch(accommodations, () => (currentIndex.value = 0))

function openAccommodation() {
  useTracking().handlers?.ecommerce.selectItem(accommodation.value)
  useTracking().handlers?.eec.productClick(accommodation.value!)
  window.open(url.value, useParams().iframe ? '_self' : '_blank')
}

function nextAccommodation() {
  currentIndex.value = ++currentIndex.value % count.value
}

function prevAccommodation() {
  currentIndex.value += currentIndex.value ? -1 : count.value - 1
}

function close() {
  emit('close')
}
</script>
