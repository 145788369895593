export default {
  searchTeaser,
  recommendation,
}

function searchTeaser() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Search Results',
    eventAction: 'Scroll Depth',
    eventLabel: 'Header viewed',
    eventNonInteraction: true,
  })
}

// recommendations is the list of recommended accommodations given by webcc-api recommendations endpoint
function recommendation({ data, entry }: { data: PreparedItem[]; entry: { target: string } }) {
  useTracking().send({
    event: 'recommender',
    items: data.map((r) => r.accom),
    target: entry.target,
    _clear: true,
  })
}
