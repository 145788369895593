<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="flex items-center">
      <WebccIcon name="site/note" class="mr-4 h-12 w-12 shrink-0" :class="color(alertType)" />
      <div>
        <Text v-if="title" :key-prop="title" :class="color(alertType)" component="div" />
        <Text v-if="contentType === 'tr'" :key-prop="content" component="div" />
        <div v-else-if="contentType === 'text'" v-text="content"></div>
        <div v-else :class="styles" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  alertType?: string
  contentType?: string
  title?: string
  content?: string
  styles?: string
}

withDefaults(defineProps<Props>(), {
  alertType: 'info',
  contentType: 'text',
  title: '',
  content: 'Text',
  styles: '',
})

function color(mType: string) {
  return mType === 'error' ? 'text-err' : 'text-thm'
}
</script>
