<template>
  <header class="container-fluid-lg flex items-center justify-between py-4">
    <WebccCompanyLogo
      target="_self"
      :anchor="false"
      :caption="logoCaption"
      :company="company"
      class="font-light"
      :href="logoHref"
      @click="useTracking().handlers?.header.headerItemClick('homepage')"
    />
    <div class="flex items-center md:items-end self-end">
      <HeaderSelect
        v-if="sites && sites?.length > 1"
        :selected-value="language!"
        :all-options="sites"
        :has-key="true"
        object-key="language"
        :to-upper-case="true"
        @on-change="changeLanguage"
      />
      <HeaderSelect
        v-if="currencies && currencies.length > 1"
        :selected-value="confCurrency!"
        :all-options="currencies"
        :has-key="false"
        :to-upper-case="true"
        @on-change="changeCurrency"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.site.Header.' })

const { company, currencies, language, sites, logoCaption, currency: confCurrency } = storeToRefs(useConfdata())

const logoHref = computed(() => useRequestURL().pathname)

function changeLanguage(lang: string) {
  useTracking().handlers?.header.languageSwitch(lang)

  const url = useRequestURL()
  url.pathname = [lang, ...url.pathname.split('/').slice(2)].join('/')
  window.location.href = url.href
}

function changeCurrency(currency: string) {
  useTracking().handlers?.header.currencySwitch(currency)

  const url = useRequestURL()
  url.searchParams.set('currency', currency)
  window.location.href = url.href
}
</script>
