<template>
  <main class="flex w-full flex-col items-center bg-bgr-100">
    <Text component="h1" key-prop="title" class="mb-4 mt-4 text-center text-3xl font-normal leading-normal text-thm" />
    <article class="mx-2 my-4 w-full max-w-screen-md rounded bg-bgr p-4 text-txt shadow-md md:w-full">
      <Text component="h4" key-prop="messages.notFound" class="text-xl text-err" />
    </article>
    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'errors.' })
</script>
