<template>
  <section :key="compKey" class="group relative w-full overflow-hidden rounded-tl">
    <LazyImageSlider v-if="showSlider" :images="images" :cover-full="coverFull" @handle-click="onClick" />
    <div class="absolute flex h-full w-full items-center justify-center bg-bgr-100">
      <WebccIcon v-if="!useParams().iframe" :name="company + '/logo'" class="w-1/4 md:w-1/5" />
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Gallery.'
defineExpose({ trPrefix })

interface Image {
  id: string
  src: string
  alt: string
  title: string
}

const props = withDefaults(
  defineProps<{
    accom: TranslatedAccommodation
    screenSize?: string
    checkin?: Date
    coverFull?: boolean
  }>(),
  {
    screenSize: 'sm',
    checkin: undefined,
    coverFull: false,
  },
)

const emit = defineEmits<{ 'open-gallery': [id: string] }>()

const { company } = useConfdata()

const showSlider = ref(false)

const imageTransformation = computed(() => {
  return props.screenSize === 'xl' ? 'tr:w-1200,pr-true,c-at_max' : props.screenSize === 'lg' ? 'tr:w-755,pr-true,c-at_max' : 'tr:w-600,pr-true,c-at_max'
})
const compKey = computed(() => {
  return Date.now() + props.screenSize
})

const season = computed(() => {
  return useSeason(props.checkin)
})

const images = computed(() => {
  const images: Array<Image> = []
  type imagesKeys = 'images_s' | 'images_w'
  const imageKey: imagesKeys = `images_${season.value}`
  if (props.accom.media[imageKey].length > 0) {
    props.accom.media[imageKey].forEach((image: string) => {
      const title = translatep(props.accom.media.items[image].type)
      const alt = `${title}|${props.accom.name}|${props.accom.region}|${props.accom.place}`
      images.push({
        id: image,
        src: `${useRuntimeConfig().public.imagekitBase}/${image}/${imageTransformation.value}`,
        alt,
        title,
      })
    })
  }
  return images
})
onMounted(() => {
  showSlider.value = true
})
function onClick(index: number) {
  emit('open-gallery', images.value[index].id)
}
</script>
