/**
 * Calculates the global index of an item among all pages.
 *
 * @param index Item index on the page.
 * @param page Page number.
 * @param pagesize Number of items on the page.
 * @returns
 */
export function calcPosition(index: number, page: number, pagesize: number) {
  return pagesize * (page - 1) + index + 1
}
