<template>
  <WebccBreadcrumb :lead="lead" :items="items" class="py-3" />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.favorites.Breadcrumbs.'
defineExpose({ trPrefix })

const lead = computed<BreadcrumbItem>(() => {
  return {
    target: '/',
    title: 'Home',
    tr: 'home',
    content: 'Home',
  }
})

const items = computed<BreadcrumbItem[]>(() => {
  return [
    {
      target: '',
      title: translatep('favorites'),
      tr: 'favorites',
      content: translatep('favorites'),
    },
  ]
})
</script>
