export const usePartner = defineStore('partner', () => {
  const id = ref('')
  const extra = ref('')
  const jwt = ref('')
  const directEncashment = ref(false)
  const language = ref('')
  const salesoffice = ref('')

  // see https://jira.migros.net/browse/HHDWEBCC-4617
  async function init() {
    // 1. JWT-Partner
    // 2. GET-Parameter
    // 3. check: req.headers.referer (wenn keine SEA-PartnerID)
    //           partnerIDfromReferrer(referrer)
    // 4. aus Cookie übernehmen
    // jetzt haben wir eine PartnerID
    // Bei Abweichung vom Cookie-Wert wird Cookie nach folgenden Regeln überschrieben: HHDWEBCC-3696

    const partner: { id?: string; extra?: string; directEncashment?: boolean; language?: string; salesoffice?: string } = {}
    const params = useParams().persistent
    const cookies = useCookies()
    jwt.value = cookies.jwt || params.jwt?.toString()
    if (jwt.value) {
      const { data } = await useWebccApi().partner(jwt.value)

      Object.assign(partner, {
        id: data.value?.id,
        directEncashment: data.value?.directEncashment,
        language: data.value?.language,
        salesoffice: data.value?.salesoffice,
        jwt: jwt.value,
      })
    } else {
      if (!partner.id && params.partnerid) {
        // PartnerID aus GET-Param lesen
        partner.id = params.partnerid.toString()
        if (params.partnerextra) {
          partner.extra = params.partnerextra.toString()
        }
      }

      if (!partner.id || !PARTNER_ID_WHITELIST.includes(partner.id)) {
        partner.id = getPartnerIdForReferrer(useRequestHeaders().referer) ?? partner.id
      }

      const partnerCookie = (cookies.partner || '').split('&')

      if (!partner.id && cookies.partner) {
        partner.id = partnerCookie[0]
        if (partnerCookie.length > 1) {
          partner.extra = partnerCookie[1]
        }
      }
    }

    id.value = partner.id || ''
    extra.value = partner.extra || ''
    directEncashment.value = partner.directEncashment || false
    language.value = partner.language || ''
    salesoffice.value = partner.salesoffice || ''
  }

  return { id, extra, directEncashment, salesoffice, language, jwt, init }
})

function getPartnerIdForReferrer(referrer: string): string | undefined {
  if (!referrer) return

  return Object.entries({
    IH1000116: ['google.'],
    IH1000117: ['bing.', 'ecosia.org', 'duckduckgo.', 'yahoo.', 'baidu.', 'seznam.', 'qwant.com', 'yandex.'],
  }).find(([, substrings]) => substrings.some((substring) => referrer.includes(substring)))?.[0]
}

/**
 * @see https://jira.migros.net/browse/HHDWEBCC-4679
 */
const PARTNER_ID_WHITELIST = [
  'IH1000019',
  'IH1000020',
  'IH1000021',
  'IH1000022',
  'IH1000023',
  'IH1000024',
  'IH1000025',
  'IH1000026',
  'IH1000027',
  'IH1000028',
  'IH1000029',
  'IH1000030',
  'IH1000031',
  'IH1000032',
  'IH1000033',
  'IH1000034',
  'IH1000035',
  'IH1000036',
  'IH1000223',
  'CH1001778',
]
