<template>
  <select
    v-model="model"
    class="w-full rounded border-0 border-bgr-300 bg-bgr px-4 py-1.5 text-sm text-txt shadow focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-strongest focus:outline-none focus:ring-2 focus:ring-thm active:bg-bgr-50"
  >
    <option :value="undefined">&mdash;</option>
    <option v-for="{ key, count } in options" :key="key" :value="key">
      {{ translate(`${trPrefix}${key}`) }}<template v-if="count"> ({{ count }})</template>
    </option>
  </select>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    filter: string[]
    counts: { [key: string]: number }
    showEmpty?: boolean
    showUncounted?: boolean
  }>(),
  {
    showEmpty: false,
    showUncounted: false,
  },
)

const trPrefix = `www.components.views.search.filter.`

defineExpose({ trPrefix })

const options = computed(() => {
  return props.filter
    .map((key) => ({ key, count: props.counts[key] }))
    .filter(({ count }) => count || (count === 0 && props.showEmpty) || (!count && count !== 0 && props.showUncounted))
})

const model = computed({
  get() {
    return props.filter.find((key) => useSearch().filters[key as keyof Params])
  },
  set(value?: string) {
    const filters = Object.fromEntries(props.filter.map((key) => [key, key === value]))
    useSearch().setFilter(filters)
  },
})
</script>
