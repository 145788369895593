<template>
  <div v-if="usePartner().jwt && !useParams().iframe" class="bg-hlt-light">
    <div class="container-fluid-xl text-txt flex justify-between py-3.5 text-sm">
      <div>
        <Text key-prop="id" />: <strong>{{ usePartner().id }}</strong>
      </div>
      <div>
        <WebccLink anchor class="mr-10 underline hover:no-underline" to="https://partners.interhome.group/portal">
          <Text key-prop="portal" />
        </WebccLink>
        <WebccLink anchor class="mr-10 underline hover:no-underline" to="/booking/logout/">
          <Text key-prop="logout" />
        </WebccLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'plugins.components.B2BInfobar.' })
</script>
