<template>
  <main class="flex flex-wrap">
    <!-- views -->
    <div v-if="views.length > 0" :class="viewsWidth">
      <WebccHeading bold color="txt" :level="5" :title="translatep('views.heading')" />
      <ul :class="viewClasses">
        <li v-for="view in views" :key="view" class="inline-flex w-full self-start py-1 pr-4 tracking-tight">
          <WebccIcon name="attributes/view" class="mr-2 h-5 w-5 text-thm" />
          <Text :prefix="trPrefix" :key-prop="'views.' + view" component="span" />
        </li>
      </ul>
    </div>
    <!-- distances-->
    <div v-if="sortedDistances.length > 0" :class="distWidth">
      <WebccHeading bold color="txt" :level="5" :title="translatep('distances.heading')" />
      <ul :class="distClasses">
        <li v-for="d in sortedDistances" :key="d.name" class="inline-flex w-full self-start py-1 pr-4 tracking-tight">
          <WebccIcon :name="`attributes/${d.icon}`" class="mr-2 h-5 w-5 text-thm" />
          <Text :key-prop="`distances.${d.name}`" :params="{ distance: distanceWithUnit(d.name) }" component="span" />
        </li>
      </ul>
    </div>
    <!-- attributes -->
    <div v-if="attrs.length > 0" class="order-3 w-full">
      <WebccHeading bold color="txt" :level="5" :title="translatep('equipment.heading')" />
      <ul class="gap-0 sm:columns-2 lg:flex lg:flex-wrap">
        <li v-for="a in attrs" :key="a.name" class="inline-flex w-full self-start py-1 pr-4 tracking-tight lg:w-1/3">
          <WebccIcon :name="`attributes/${a.icon}`" class="mr-2 h-5 w-5 text-thm" />
          <Text :key-prop="'equipment.' + a.name" component="span" />
        </li>
      </ul>
    </div>
    <!-- sustainability attributes -->
    <div v-if="sustattrs.length > 2" class="order-4 w-full">
      <WebccHeading bold color="txt" :level="5" :title="translatep('sustainability.heading')" />
      <ul class="gap-0 sm:columns-2 lg:flex lg:flex-wrap">
        <li v-for="a in sustattrs" :key="a.name" class="inline-flex w-full self-start py-1 pr-4 tracking-tight lg:w-1/3">
          <WebccIcon :name="`attributes/${a.icon}`" class="mr-2 h-5 w-5 text-thm" />
          <Text :key-prop="'sustainability.' + a.name" component="span" />
        </li>
      </ul>
    </div>
  </main>
</template>

<script setup lang="ts">
const icons: { [key: string]: any } = {
  lake: 'dist-sea-lake-water',
  patio: 'balcony',
  pets_max: 'pets',
  sea: 'dist-sea-lake-water',
  single_garage: 'garage',
  small_pets_max: 'pets',
  small_pets: 'pets',
  terrace: 'balcony',
  whirlpool: 'hottub',
}

const displaySchemes: { [key: string]: any } = {
  '1and1': {
    viewsWidth: 'w-full sm:w-1/2 lg:w-1/3',
    distWidth: 'w-full sm:w-1/2 lg:w-2/3',
    viewClasses: 'gap-0',
    distClasses: 'gap-0',
  },
  '1and2': {
    viewsWidth: 'w-full sm:w-1/2 lg:w-1/3',
    distWidth: 'w-full sm:w-1/2 lg:w-2/3',
    viewClasses: 'gap-0',
    distClasses: 'gap-0 lg:flex',
  },
  '1and3+': {
    viewsWidth: 'w-full sm:w-1/2 lg:w-1/3',
    distWidth: 'w-full sm:w-1/2 lg:w-2/3',
    viewClasses: 'gap-0',
    distClasses: 'gap-0 lg:columns-2',
  },
  '2and1': {
    viewsWidth: 'w-full sm:w-1/2 lg:w-2/3',
    distWidth: 'w-full sm:w-1/2 lg:w-1/3',
    viewClasses: 'columns-1 lg:flex gap-0',
    distClasses: 'gap-0',
  },
  '2and2': {
    viewsWidth: 'w-full sm:w-1/2 lg:w-1/3',
    distWidth: 'w-full sm:w-1/2 lg:w-1/3',
    viewClasses: 'gap-0',
    distClasses: 'gap-0',
  },
  '2and3+': {
    viewsWidth: 'w-full lg:w-1/3',
    distWidth: 'w-full lg:w-2/3',
    viewClasses: 'gap-0 sm:flex lg:block',
    distClasses: 'gap-0 sm:columns-2',
  },
  '2and0': {
    viewsWidth: 'w-full lg:w-2/3',
    distWidth: 'w-full lg:w-2/3',
    viewClasses: 'gap-0 sm:flex',
    distClasses: 'gap-0 sm:flex',
  },
  '3and0': {
    viewsWidth: 'w-full',
    distWidth: 'w-full',
    viewClasses: 'gap-0 sm:columns-2 lg:flex',
    distClasses: 'gap-0 sm:columns-2 lg:flex',
  },
}

const viewsWidth = ref('w-full')
const distWidth = ref('w-full')
const viewClasses = ref('gap-0 sm:columns-2 lg:columns-3')
const distClasses = ref('gap-0 sm:columns-2 lg:columns-3')

const trPrefix = 'www.components.views.details.Attributes.'
defineExpose({ trPrefix })

interface Props {
  attributes?: TranslatedAccommodation['attributes']
  distances?: TranslatedAccommodation['distances']
  themes?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  attributes: () => {
    return {}
  },
  distances: () => {
    return {}
  },
  themes: () => [],
})

const views = computed(() => {
  return ['country_view', 'lake_view', 'mountain_view', 'sea_view'].filter((v) => props.attributes?.[v])
})

const sortedDistances = computed(() => {
  return ['center', 'public_transport', 'beach', 'sea', 'lake', 'golf', 'ski']
    .filter((d) => props.distances?.[d] || props.distances?.[d] === 0)
    .map((d) => ({ name: d, distance: props.distances?.[d], icon: icons?.[d] || `dist-${d}` }))
})

const attrs = computed(() => {
  const allowedAttrs = [
    ['pets', 'small_pets_max', 'small_pets', 'pets_max'],
    ['certified_fishing'],
    [props.attributes?.fishing && props.attributes?.certified_fishing ? '' : 'fishing'],
    ['wlan'],
    ['pool'],
    ['dishwasher'],
    ['washingmachine'],
    ['tv'],
    ['balcony', 'terrace', 'patio'],
    ['aircondition'],
    ['bbq'],
    ['boat'],
    ['fireplace'],
    ['cots'],
    ['sauna'],
    ['hottub', 'whirlpool'],
    ['garage'],
    ['single_garage'],
    ['parking'],
    ['suitable_for_wheelchairs'],
    ['nonsmoking'],
    ['no_pets_allowed'],
    ['workation'],
  ]
  return (
    allowedAttrs
      .filter((alist) => {
        return alist.reduce((acc, allowedAttrs) => {
          return acc || !!props.attributes[allowedAttrs] || props.themes?.includes(allowedAttrs)
        }, false)
      }) // accom muss mind. eines der attribute enthalten
      // TODO: filter fishing if certified fishing
      .map((alist) => ({ name: alist[0], icon: icons[alist[0]] || alist[0] }))
  )
})

const sustattrs = computed(() => {
  const sustattrs = [
    ['charging_station'],
    ['double_glazed_windows'],
    ['ecological_heating_system'],
    ['green_space_garden'],
    ['led_bulbs'],
    ['solar_panels'],
    ['recycling_station'],
    ['renewable_electricity'],
    ['water_efficient_showers', 'water_efficient_toilets'],
  ]
  return sustattrs
    .filter((alist) => {
      return alist.reduce((acc, sustattr) => {
        return acc || !!props.attributes?.[sustattr] || props.themes?.includes(sustattr)
      }, false)
    })
    .map((alist) => ({ name: alist[0], icon: icons[alist[0]] || alist[0] }))
})

let scheme = ''

if (views.value.length === 1 && sortedDistances.value.length === 1) scheme = '1and1'
if (views.value.length === 1 && sortedDistances.value.length <= 2) scheme = '1and2'
if (views.value.length === 1 && sortedDistances.value.length >= 3) scheme = '1and3+'
if (views.value.length === 2 && sortedDistances.value.length === 1) scheme = '2and1'
if (views.value.length === 2 && sortedDistances.value.length === 2) scheme = '2and2'
if (views.value.length === 2 && sortedDistances.value.length >= 3) scheme = '2and3+'
if ((views.value.length === 2 && sortedDistances.value.length === 0) || (views.value.length === 0 && sortedDistances.value.length === 2)) scheme = '2and0'
if ((views.value.length === 3 && sortedDistances.value.length === 0) || (views.value.length === 0 && sortedDistances.value.length === 3)) scheme = '3and0'

if (scheme !== '') {
  const activeScheme = displaySchemes[scheme]

  viewsWidth.value = activeScheme.viewsWidth
  distWidth.value = activeScheme.distWidth
  viewClasses.value = activeScheme.viewClasses
  distClasses.value = activeScheme.distClasses
}

function distanceWithUnit(distance: string) {
  const number = props.distances[distance]
  if (number === 0) {
    return translate('www.components.views.details.Attributes.distances.closeby')
  }
  if (number < 1000) {
    return `${formatNumber(number)}\xA0m`
  }
  return `${formatNumber(number / 1000)}\xA0km`
}
</script>
