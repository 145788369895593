import { nanoid } from 'nanoid'

export const useAB = defineStore('ab', () => {
  const allABs: Ref<AB[]> = ref([])
  const sessionStorageKeys = { flexibleDateSearch: 'flexible-search' } as { [key: string]: string }

  function isActive(key: string) {
    return allABs.value.find((ab) => ab.key === key)?.active
  }

  function toggle(key: string, value: boolean) {
    allABs.value = allABs.value.map((ab) => {
      if (ab.key === key) ab.active = value
      if (Object.keys(sessionStorageKeys).includes(key)) {
        window.sessionStorage.setItem(sessionStorageKeys[key], value.toString())
      }
      return ab
    })
  }

  function init(tests: AB[]) {
    const testsFromQuery = Object.fromEntries(
      (useParams().all.ab?.toString().split(',') || []).map((entry) => {
        const [key, value] = entry.split(':')
        return [key, value !== 'false']
      }),
    )

    if (!useConfdata().domain) console.warn('AB-tests are initialized with no domain information in conf response. Assuming single domain application.')
    if (!useUseragent().useragent?.device) console.error('AB-tests are initialized with no browser information. Ignoring device based restrictions.')

    allABs.value = tests.map((test) => {
      if (test.key in testsFromQuery) return createAB(test, testsFromQuery[test.key])

      const validDomain = !useConfdata().domain || test.domains[0] === '*' || test.domains.includes(useConfdata().domain)
      const validDevice = !useUseragent().useragent?.device || test.devices[useUseragent().useragent!.device]

      if (!validDomain || !validDevice) return createAB(test, test.default)

      if (!useIDs().user) console.error('No user ID found, substituting random non-persistent value.')

      const numericalId = useIDs().user ? stringToNumber(useIDs().user + test.key) : nanoid()
      const weightedVariants = Object.entries(test.variants).flatMap(([value, count]) => Array(count).fill(value === 'true'))
      const active = weightedVariants[+numericalId % weightedVariants.length]

      return createAB(test, active)
    })
  }

  return { all: allABs, toggle, isActive, init }
})

function createAB(test: AB, active: boolean): AB {
  return { ...test, active, value: active.toString() }
}

function stringToNumber(string: string) {
  // Create a byte-array representation from the given string
  const buffer = Buffer.from(string, 'utf8')

  // Add up all the array values
  return buffer.reduce((prev, curr) => {
    return (prev += curr)
  }, 0)
}
