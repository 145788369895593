import type { IconProps } from 'vue3-toastify'
import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import './vue3-toastify.css'

export default defineNuxtPlugin(async (nuxtApp) => {
  const [errorWarning, check, information, cross] = await Promise.all(
    ['error-warning', 'check', 'information', 'x'].map((name) => import(`~/assets/icons/site/${name}.svg?raw`)),
  )
  const closeButton = { svg: cross.default, class: 'h-6 w-6 scale-75 text-gray-500 ml-4 mr-2 hover:opacity-60 transition-opacity' }
  const icons = {
    error: { svg: errorWarning.default, class: 'text-err' },
    warning: { svg: errorWarning.default, class: 'text-wrn' },
    success: { svg: check.default, class: 'text-suc' },
    info: { svg: information.default, class: 'text-thm' },
    default: undefined,
    loading: undefined,
  }

  nuxtApp.vueApp.use(Vue3Toastify, {
    transition: 'slide',
    hideProgressBar: true,
    dangerouslyHTMLString: true,
    autoClose: 4000,
    containerClassName: 'webcc-toast',
    icon: ({ type }: IconProps) => icons[type] && renderIcon(icons[type]!),
    closeButton: () => renderIcon(closeButton),
  })

  return {
    provide: { toast },
  }
})

function renderIcon(icon: { svg: string; class: string }) {
  return h('span', { innerHTML: icon.svg, class: `flex fill-current ${icon.class}` })
}
