<template>
  <section class="flex flex-wrap items-start justify-between divide-x divide-gray-300 text-xs sm:text-sm">
    <div class="flex flex-grow flex-col items-center">
      <WebccIcon name="attributes/feature-pax" class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8" />
      <Text component="span" :count="accom.pax" key-prop="pax_s" />
    </div>

    <div class="flex flex-grow flex-col items-center">
      <WebccIcon name="attributes/feature-rooms" class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8" />
      <Text component="span" :count="accom.rooms" key-prop="room_s" />
    </div>

    <div class="flex flex-grow flex-col items-center">
      <WebccIcon name="attributes/feature-bedrooms" class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8" />
      <Text component="span" :count="accom.bedrooms" key-prop="bedroom_s" />
    </div>

    <div class="flex flex-grow flex-col items-center">
      <WebccIcon name="attributes/feature-bathrooms" class="h-6 w-6 fill-current text-thm sm:h-8 sm:w-8" />
      <Text component="span" :count="accom.bathrooms" key-prop="bathroom_s" />
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Features.'
defineExpose({ trPrefix })

defineProps<{
  accom: ResultDocument
}>()
</script>
