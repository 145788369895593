<template>
  <section id="teaser-top" class="container-fluid-lg bg-thm">
    <div class="flex items-stretch">
      <div class="w-2/3 flex-auto flex-col p-6 text-txt-contrast md:w-1/2">
        <h1>
          <Text key-prop="title" component="span" class="text-xl font-bold md:text-3xl" />
          <Text key-prop="niceObjects" component="div" class="mt-5 text-lg" />
        </h1>
        <Text key-prop="chooseFromObjects" component="h2" class="mb-4 font-bold" />

        <WebccLink
          class="cursor-pointer rounded bg-cta px-3 py-1.5 text-sm font-light text-cta-contrast hover:bg-cta-hover focus:outline-none disabled:cursor-not-allowed disabled:shadow-none md:px-4 md:text-base"
          variant="cta"
          :to="searchUrl"
        >
          <Text key-prop="cta" component="span" :prefix="trPrefix" />
        </WebccLink>
      </div>

      <img class="hidden object-cover sm:w-1/4 md:block" src="/images/teaser_kundenbewertungen.jpg" alt="" />
    </div>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.reviews.SearchTeaserTop.'
defineExpose({ trPrefix })

defineProps<{
  count: number
  destination: string
  searchUrl: string
}>()
</script>
