<template>
  <h4 class="flex flex-wrap items-baseline">
    <WebccIcon v-for="s in accom.stars" :key="s" name="favorites/evaluation-star" :class="iconclasses" />
    <WebccLink anchor target="_blank" :to="targetUrl" :class="classes" @click="$emit('to-detailpage')">
      <Text component="span" :prefix="trPrefix" :key-prop="'type.' + accom.type" />
      {{ accom.slug ? accom.name : accom.code }}
    </WebccLink>
  </h4>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Title.'
defineExpose({ trPrefix })

const props = withDefaults(
  defineProps<{
    targetUrl: URL
    accom: ResultDocument
    size?: string
  }>(),
  {
    size: 'lg',
  },
)

defineEmits<{ (e: 'to-detailpage'): void }>()

const classes = computed(() => {
  let size = ''
  switch (props.size) {
    case 'sm':
      size = 'text-sm'
      break
    case 'md':
      size = 'text-md'
      break
    case 'lg':
      size = 'text-lg'
      break
    case 'xl':
      size = 'text-xl'
      break
  }
  return `cursor-pointer px-1 font-semibold lg:text-xl ${size}`
})
const iconclasses = computed(() => {
  return props.size === 'sm' ? 'mr-0.5 h-3 w-3' : 'mr-1 h-4 w-4'
})
</script>
