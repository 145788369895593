<template>
  <form ref="form" novalidate @submit.prevent.stop="submit">
    <slot />
  </form>
</template>

<script setup lang="ts">
import type { Formdata } from './InjectionKeys'
import { fieldErrorsKey, formdataKey, registerKey, resetKey, setFieldErrorsKey, setKey, submitKey } from './InjectionKeys'

const props = defineProps<{ modelValue: Formdata }>()

const emit = defineEmits<{ (e: 'update:modelValue', value: Formdata): void; (e: 'input', value: Formdata): void; (e: 'submit', value: Formdata): void }>()

const formdata = ref(Object.assign({}, props.modelValue))
const fieldErrors: Ref<Record<string, string[]>> = ref({})
const formFields: Ref<Record<string, any>> = ref({})

const form: Ref<HTMLFormElement | null> = ref(null)

defineExpose({ trPrefix: getCurrentInstance()?.parent?.exposed?.trPrefix || '', form, formdata })

function scrollToError() {
  // get allErrors
  const errors = Object.entries(fieldErrors.value)
    .filter((entry) => entry.every((entry) => entry.length > 0))
    .map((entry) => entry[0])

  for (const error of errors) {
    const el = form.value?.querySelector(`[name=${error}]`)
    if (el) {
      el.scrollIntoView({ block: 'start', behavior: 'smooth' })
      ;(el.querySelector('input, select, textarea') as HTMLInputElement)?.focus({ preventScroll: true })
      break
    }
  }
}
function registerFormField(name: string, formField: any) {
  formFields.value[name] = formField
}
function setField(field: string, value: any) {
  formdata.value[field as keyof typeof formdata.value] = value
  emit('update:modelValue', Object.assign({}, formdata.value))
}
function reset() {
  formdata.value = Object.assign({}, props.modelValue)
  fieldErrors.value = {}
}
async function validate() {
  for (const field in formFields.value) {
    const validationState = await formFields.value[field].exposed.validate()
    setFieldErrors(field, validationState.value.errors)
  }
  return !hasErrors()
}
function setFieldErrors(field: keyof typeof fieldErrors.value, errors: string[]) {
  fieldErrors.value[field] = errors
}
function hasErrors() {
  for (const field in fieldErrors.value) {
    if (fieldErrors.value[field]?.length > 0) {
      return true
    }
  }
  return false
}
async function submit() {
  if (await validate()) {
    emit('submit', Object.assign({}, formdata.value))
    return true
  } else {
    scrollToError()
    return false
  }
}

provide(setKey, setField)
provide(resetKey, reset)
provide(submitKey, submit)
provide(formdataKey, formdata.value)
provide(fieldErrorsKey, fieldErrors.value)
provide(setFieldErrorsKey, setFieldErrors)
provide(registerKey, registerFormField)
</script>
