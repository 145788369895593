<template>
  <main class="container-fluid-md my-8">
    <WebccHeading :level="1" :title="translatep('title')" space="mb-4" class="text-center" />

    <section class="relative min-h-1/4-screen rounded bg-bgr p-4 shadow">
      <template v-if="success === undefined">
        <Text key-prop="heading" class="mt-8 text-lg font-light text-thm" />

        <div class="mb-4 mt-4 flex flex-col items-stretch justify-center space-y-4">
          <label
            v-for="{ value, label } in options"
            :key="value"
            class="mt-3 inline-flex items-center rounded bg-bgr-50 px-4 py-2 text-txt-contrast shadow hover:bg-bgr-100 active:bg-bgr-200"
          >
            <input v-model="reason" type="radio" class="h-6 w-6 cursor-pointer rounded-3xl text-thm focus:ring-bgr active:ring-thm" :value="value" />

            <div class="ml-4 text-txt">
              <p class="text-txt-weak">{{ label }}</p>
            </div>
          </label>
        </div>
        <WebccButton variant="cta" size="lg" class="" @click="submit">
          <Text key-prop="submit" />
        </WebccButton>

        <WebccLoaderOverlay v-if="loading" absolute rounded="md" />
      </template>

      <template v-else>
        <template v-if="success === true">
          <WebccHeading :level="2" :title="translatep('success.heading')" space="my-2" />
          <Text key-prop="success.text" component="p" />
        </template>
        <article v-else class="text-center">
          <WebccHeading :level="2" :title="translatep('error.heading')" space="my-2" />
          <Text key-prop="error.note" component="p" class="text-lg font-light text-txt" />
        </article>
      </template>
    </section>
  </main>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.pages.emails._hash.unsubscribe.' })

const reason = ref(1)
const loading = ref(false)
const success = ref<boolean>()

const { hash } = useEmails()

const options = computed(() => [
  { value: 3, label: translatep('options.frequency') },
  { value: 4, label: translatep('options.topics') },
  { value: 5, label: translatep('options.needs') },
  { value: 6, label: translatep('options.newsletters') },
  { value: 2, label: translatep('options.noreason') },
])

onMounted(() => {
  useTracking().handlers?.newsletter.unsubscribe(hash.value)
})

async function submit() {
  const { error, pending } = await useEmails().unsubscribe(reason.value)

  loading.value = pending.value
  success.value = !error.value

  if (!success.value) return

  useTracking().handlers?.newsletter.unsubscribeSubmit(hash.value)
}
</script>
