<template>
  <div>
    <footer class="flex flex-col bg-bgr-100 p-4">
      <div class="flex flex-wrap justify-center">
        <button v-for="(item, index) in menu" :key="index" class="m-4 hover:text-thm" @click="showModal(item)">
          <span>{{ item.title }}</span>
        </button>
        <button class="m-4 hover:text-thm" @click="useConsent().configOpen = true">
          <Text component="span" key-prop="cookieSettings" />
        </button>
      </div>
    </footer>

    <WebccModal v-if="activeItem" @close="hideModal">
      <div class="max-h-[80vh] max-w-xl overflow-y-auto bg-bgr p-6 rounded-md">
        <header class="relative flex min-w-[300px] shrink-0 items-center justify-between pb-8">
          <h1 class="text-xl font-medium">{{ activeItem.title }}</h1>
          <button
            class="pointer-events-auto relative inline-block h-6 w-6 shrink-0 grow-0 cursor-pointer select-none appearance-none rounded-xl border-0 bg-bgr-300 p-1 align-top outline-none"
            aria-label="close"
            @click="hideModal"
          >
            <svg class="h-4 w-4 text-txt-contrast" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </header>

        <Blocks :blocks="activeItem.content" />
      </div>
    </WebccModal>
  </div>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.footer.' })

interface MenuItem {
  title: string
  content: Block[]
}

const MENU_BLOCKS = [
  1, // Conditions
  0, // Imprint
  2, // Privacy policy
]

const activeItem = ref<MenuItem>()

const body = computed(() => useConfdata().body ?? [])
const menu = computed(() => MENU_BLOCKS.map((index) => toMenuItem(body.value[index].content.content)))

function toMenuItem(blocks: Block[]): MenuItem {
  return {
    title: blocks.find(({ component }) => component === 'Heading')?.content.title,
    content: blocks.filter(({ component }) => component !== 'Heading'),
  }
}

function showModal(item: MenuItem) {
  activeItem.value = item
}

function hideModal() {
  activeItem.value = undefined
}
</script>
