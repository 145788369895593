import { differenceInMilliseconds } from 'date-fns'

const STORAGE_KEY = 'userSurvey'

export const useUserSurvey = defineStore('userSurvey', () => {
  const state = useLocalStorage<UserSurvey>(STORAGE_KEY, _createState('scheduled'))

  const visible = computed(() => state.value.status === 'visible')
  const url = computed(() => useRuntimeConfig().public.userSurvey.url)

  function _createState(status: UserSurveyStatus): UserSurvey {
    return { status, session: useIDs().session, updatedAt: new Date().toISOString() }
  }

  function schedule() {
    if (state.value.status !== 'postponed' || state.value.session === useIDs().session) return

    setStatus('scheduled')
  }

  function show() {
    if (!['scheduled', 'visible'].includes(state.value.status)) return

    const lifetime = differenceInMilliseconds(new Date(), new Date(state.value.updatedAt))
    const delay = Math.max(useRuntimeConfig().public.userSurvey.delay - lifetime, 0)
    setTimeout(() => setStatus('visible'), delay)
  }

  function setStatus(status: UserSurveyStatus) {
    state.value = _createState(status)
    if (status !== 'scheduled') useTracking().handlers?.detail.externalUserSurvey(status)
  }

  return { visible, url, schedule, show, setStatus }
})
