<template>
  <WebccSelect v-cloak v-model="model" icon="attributes/feature-pax" :label="label" :disabled="disabled" :options="options" />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: number
    max: number
    prefix: string
    label?: string
    disabled?: boolean
  }>(),
  {},
)

defineExpose({ trPrefix: props.prefix })

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): number
}>()

const model = computed({
  get() {
    return props.modelValue || props.max
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const options = computed(() => {
  return [...Array(props.max).keys()].map((index) => ({ label: translatep('person_s', {}, index + 1), value: index + 1 }))
})
</script>

<style scoped></style>
