<template>
  <aside class="w-64 rounded border border-transparent">
    <div class="flex flex-row items-center gap-2 px-1">
      <WebccIcon name="searchpage/settings" class="h-5 w-5 text-thm" />
      <Text key-prop="labels.refine" class="text-lg text-thm" />
    </div>

    <FilterGroup :title="translatep('labels.where')">
      <FilterDropdown filter="country" has-icons :options="countries || []" class="mb-1.5" @change="onCountryChange" />
      <FilterDropdown filter="region" :options="regions || []" class="mb-1.5" @change="onRegionChange" />
      <FilterDropdown filter="place" :options="places || []" @change="onPlaceChange" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.when')">
      <FilterDateRange />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.pax')">
      <Text key-prop="pax" :prefix="trPrefix" class="mt-2 text-sm font-medium" component="p" />
      <FilterSelect filter="pax" :buckets="pax || {}" />

      <Text key-prop="bedrooms" :prefix="trPrefix" class="mt-2 text-sm font-medium" component="p" />
      <FilterSelect filter="bedrooms" :buckets="bedrooms || {}" />

      <Text key-prop="bathrooms" :prefix="trPrefix" class="mt-2 text-sm font-medium" component="p" />
      <FilterSelect filter="bathrooms" :buckets="bathrooms || {}" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.accomtype')">
      <FilterSelectMulti :filter="['house', 'apartment', 'detached', 'villa', 'chalet', 'farmhouse', 'studio']" :counts="counts" />
    </FilterGroup>

    <FilterGroup v-show="counts.casa || counts.swiss_peak || counts.workation || counts.sustainable" :title="translatep('labels.particular')">
      <FilterCheckboxes :filter="['casa', 'swiss_peak', 'workation', 'sustainable']" :counts="counts" />
    </FilterGroup>

    <FilterGroup :title="translatep('stars')">
      <FilterSelect filter="stars" :buckets="stars || {}" type="atleast" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.pool')">
      <FilterSelectMulti :filter="['pool', 'pool_children', 'pool_indoor', 'pool_private']" :counts="counts" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.pet')">
      <FilterCheckboxes :filter="['pets']" :counts="counts" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.distances')" :open="true">
      <Text key-prop="sea" :prefix="trPrefix" component="p" class="px-1 pb-1 pt-2 text-xs font-semibold text-txt" />
      <FilterSelect filter="sea" :buckets="facets?.sea || {}" type="distance" />

      <Text key-prop="lake" :prefix="trPrefix" component="p" class="px-1 pb-1 pt-2 text-xs font-semibold text-txt" />
      <FilterSelect filter="lake" :buckets="facets?.lake || {}" type="distance" />

      <Text key-prop="ski" :prefix="trPrefix" component="p" class="px-1 pb-1 pt-2 text-xs font-semibold text-txt" />
      <FilterSelect filter="ski" :buckets="facets?.ski || {}" type="distance" />

      <Text key-prop="center" :prefix="trPrefix" component="p" class="px-1 pb-1 pt-2 text-xs font-semibold text-txt" />
      <FilterSelect filter="center" :buckets="facets?.center || {}" type="distance" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.facilities')" :frame="false" :open="true">
      <FilterCheckboxes
        :filter="[
          'wlan',
          'aircondition',
          'parking',
          'garage',
          'balcony-or-terrace',
          'dishwasher',
          'washingmachine',
          'tv',
          'sea_or_lake_view',
          'bbq',
          'boat',
          'cots',
          'hottub',
          'fireplace',
          'sauna',
          'charging_station',
        ]"
        :counts="counts"
      />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.specials')">
      <FilterSelectMulti :filter="['last_minute', 'special_offer', 'discount-20', 'cheapcheap', 'early_booker']" :counts="counts" show-uncounted />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.maxPrice')">
      <FilterStepper filter="maxPrice" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.holidaytype')">
      <FilterSelectMulti :filter="['familyfriendly', 'holiday_resort', 'residence', 'citytrips', 'utoring']" :counts="counts" />
    </FilterGroup>

    <FilterGroup v-show="counts.fishing || counts.fishing_certified" :title="translatep('labels.fishing')">
      <FilterCheckboxes :filter="['fishing', 'fishing_certified']" :counts="counts" />
    </FilterGroup>

    <FilterGroup :title="translatep('labels.activities')">
      <FilterSelectMulti
        :filter="['skiing', 'hiking', 'golfing', 'cycling', 'wellness', 'tennis', 'surfing', 'sailing', 'mountainbiking', 'riding', 'crosscountryskiing']"
        :counts="counts"
      />
    </FilterGroup>
  </aside>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.filter.'
defineExpose({ trPrefix })

const props = defineProps<{
  facets?: Facets
  countries?: DestFacet[]
  regions?: DestFacet[]
  places?: DestFacet[]
}>()

const counts = computed(() => {
  const counts = Object.assign({}, props.facets?.activities, props.facets?.attributes, props.facets?.themes, props.facets?.types)
  delete counts._other
  return counts
})

const pax = computed(() => {
  if (!props.facets?.pax) {
    return {}
  }
  const base = props.facets.pax
  const result: Record<string, number> = {}
  const min = 1
  const max = Math.max(
    ...Object.keys(base)
      .filter((e) => !isNaN(parseInt(e)))
      .map((e) => parseInt(e)),
  )
  for (let index = min; index <= max; index++) {
    result[index] =
      Object.entries(base)
        .filter(([key]) => parseInt(key) > index)
        .reduce((prev, curr) => (prev += curr[1]), base[index] || 0) + (base._other || 0)
  }
  if (base._other) {
    result.more = base._other
  }
  return result
})
// const rooms = computed(() => {
//   if (!props.facets?.rooms) {
//     return {}
//   }
//   const base = props.facets.rooms
//   const result: Record<string, number> = {}
//   const min = 1
//   const max = Math.max(
//     ...Object.keys(base)
//       .filter((e) => !isNaN(parseInt(e)))
//       .map((e) => parseInt(e)),
//   )
//   for (let index = min; index <= max; index++) {
//     result[index] =
//       Object.entries(base)
//         .filter(([key]) => parseInt(key) > index)
//         .reduce((prev, curr) => (prev += curr[1]), base[index] || 0) + (base._other || 0)
//   }
//   if (base._other) {
//     result.more = base._other
//   }
//   return result
// })
const bedrooms = computed(() => {
  if (!props.facets?.bedrooms) {
    return {}
  }
  const base = props.facets.bedrooms
  const result: Record<string, number> = {}
  const min = 1
  const max = Math.max(
    ...Object.keys(base)
      .filter((e) => !isNaN(parseInt(e)))
      .map((e) => parseInt(e)),
  )
  for (let index = min; index <= max; index++) {
    result[index] =
      Object.entries(base)
        .filter(([key]) => parseInt(key) > index)
        .reduce((prev, curr) => (prev += curr[1]), base[index] || 0) + (base._other || 0)
  }
  if (base._other) {
    result.more = base._other
  }
  return result
})
const bathrooms = computed(() => {
  if (!props.facets?.bathrooms) {
    return {}
  }
  const base = props.facets.bathrooms
  const result: Record<string, number> = {}
  const min = 1
  const max = Math.max(
    ...Object.keys(base)
      .filter((e) => !isNaN(parseInt(e)))
      .map((e) => parseInt(e)),
  )
  for (let index = min; index <= max; index++) {
    result[index] =
      Object.entries(base)
        .filter(([key]) => parseInt(key) > index)
        .reduce((prev, curr) => (prev += curr[1]), base[index] || 0) + (base._other || 0)
  }
  if (base._other) {
    result.more = base._other
  }
  return result
})
const stars = computed(() => {
  if (!props.facets?.stars) {
    return {}
  }
  const base = props.facets.stars
  const result: Record<string, number> = {}
  const min = 1
  const max = Math.max(
    ...Object.keys(base)
      .filter((e) => !isNaN(parseInt(e)))
      .map((e) => parseInt(e)),
  )
  for (let index = min; index <= max; index++) {
    result[index] =
      Object.entries(base)
        .filter(([key]) => parseInt(key) > index)
        .reduce((prev, curr) => (prev += curr[1]), base[index] || 0) + (base._other || 0)
  }
  if (base._other) {
    result.more = base._other
  }
  return result
})

function onCountryChange(country?: string) {
  useSearch().setFilter({ country, region: undefined, place: undefined })
}

function onRegionChange(region?: string) {
  useSearch().setFilter({ region, place: undefined })
}

function onPlaceChange(place?: string) {
  if (place) {
    const fullPlace = (props.places || []).find((c) => c.code === place)
    useSearch().setFilter({ place, region: fullPlace?.parentRegionCode })
  } else {
    useSearch().setFilter({ place })
  }
}
</script>
