<template>
  <select
    v-model="model"
    class="w-full rounded border-0 bg-bgr px-4 py-1.5 text-sm text-txt shadow focus:bg-bgr-100 focus:text-txt-stronger focus:outline-none focus:ring-2 focus:ring-thm active:bg-bgr-50"
    :placeholder="filter"
  >
    <option :value="undefined">&#8212;</option>
    <option v-for="o in preparedOptions" :key="o.value" :value="o.value">
      {{ o.label }}
      {{
        !hideCount && o.count
          ? parseInt(o.value) >= parseInt(model) || !model || isNaN(parseInt(o.value)) || type === 'distance'
            ? ' (' + o.count + ')'
            : ' (' + o.count + '+)'
          : ''
      }}
    </option>
  </select>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.views.search.SearchFilterSelect.' })
const props = withDefaults(
  defineProps<{
    filter: string
    buckets: { [key: string]: number }
    type?: string
    hideCount?: boolean
  }>(),
  {
    type: 'atleast',
    hideCount: false,
  },
)

const distmap: { [key: string]: string } = {
  '100': '100m',
  '500': '500m',
  '1000': '1km',
  '2000': '2km',
  '5000': '5km',
  '10000': '10km',
}

const model = computed({
  get() {
    return useSearch().filters[props.filter as keyof Params] as string
  },
  set(value: string) {
    if (value === '_other') {
      console.log('more', value)
    } else {
      useSearch().setFilter({ [props.filter]: value })
    }
  },
})

const preparedOptions = computed(() => {
  if (!props.buckets) {
    return []
  }
  if (props.type === 'distance') {
    const result = []
    let cumSum = 0
    for (const [key, value] of Object.entries(props.buckets)) {
      if (cumSum + value > cumSum) {
        cumSum += value
        const option = { value: key, label: distmap[key] || '', count: cumSum }
        result.push(option)
      }
    }
    return result.filter((d) => distmap[d.value])
  }
  if (props.type === 'atleast') {
    return Object.entries(props.buckets)
      .filter((b) => b[1])
      .map(([value, count]) => ({
        value,
        label: !isNaN(parseInt(value))
          ? translate('www.components.views.search.filter.labels.atleast', { value })
          : value === 'more'
            ? translatep('more')
            : value,
        count,
      }))
  }
})
</script>
