<template>
  <section class="container-fluid-lg flex-col items-start justify-between md:flex">
    <main class="md:w-2/3">
      <div v-if="rating?.numReviews === 0" class="relative rounded border-0 bg-bgr p-4 text-sm leading-4 shadow md:leading-5">
        <Alert alert-type="info" content-type="tr" title="www.components.ui.alert.note" :content="trPrefix + 'review_s'"></Alert>
      </div>
      <template v-if="rating?.overall">
        <ReviewSummary
          :rating="rating.overall"
          :condition="rating.avgDetails!.avgCondition"
          :cleanliness="rating.avgDetails!.avgCleanliness"
          :service="rating.avgDetails!.avgService"
          :review-count="rating.numReviews"
        />
        <ReviewFilter :filter-travel-with="filter" :reviews="reviews" @update="onFilterChange" />
      </template>
      <template v-if="loading"><Text key-prop="loading" /></template>
      <template v-else>
        <ReviewItem
          v-for="(review, index) in filteredReviews"
          :key="'review' + index"
          :locale="locale"
          :company="company"
          class="mt-8 last:mb-8"
          :review="review"
        />
      </template>
    </main>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Reviews.'
defineExpose({ trPrefix })

const props = defineProps<{
  code: string
}>()

const rating = ref<Rating>()
const filter = ref('all')
const loading = ref(false)

const locale = computed(() => useL10N().locale)
const company = computed(() => getCompanyName(useConfdata().company))
const reviews = computed(() => rating.value?.reviews?.sort((review1, review2) => (review1.arrivalDate > review2.arrivalDate ? -1 : 1)) ?? [])
const filteredReviews = computed(() => (filter.value === 'all' ? reviews.value : reviews.value.filter((r) => r.travelWith === filter.value)))

onMounted(async () => {
  await fetch()
})

async function fetch() {
  loading.value = true

  rating.value = (await useAccommodation(props.code).getRating()) || undefined

  loading.value = false
}

function onFilterChange(travelWith: string) {
  filter.value = travelWith
}
</script>
