<template>
  <div class="flex flex-row flex-wrap items-center justify-end gap-x-2 text-sm font-bold text-thm">
    <Text v-if="overallRating > 0" :key-prop="'labels.' + roundedRating" />
    <RatingHearts :rating="overallRating" :title="overallRating + ' / 5'" color="theme" size="3" />
    <Text component="span" class="flex flex-row justify-end text-xs text-txt" key-prop="review_s" :count="reviewCount" />
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Rating.'
defineExpose({ trPrefix })

const props = defineProps<{
  code: string
  overallRating: number
  reviewCount: number
}>()

const roundedRating = computed(() => {
  return Math.round(props.overallRating)
})
</script>
