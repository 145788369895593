<template>
  <section class="bg-thm">
    <div :class="{ 'container-fluid-lg': !fullWidth }" class="flex flex-row gap-2 py-4">
      <label class="relative flex w-3/4 flex-col">
        <input
          v-model="currentSearch.label"
          class="h-12 rounded border border-bgr-300 px-8 placeholder:text-txt-weak focus:outline-none focus:ring-2 focus:ring-thm focus:ring-offset-2 md:px-7 lg:px-8"
          :placeholder="translatep('placeholders.geo')"
          autocomplete="off"
          @click.stop
          @focus="useToggler().toggle(TOGGLE_KEY, { state: true })"
          @input="onChange"
          @keydown="handleKeydown"
        />
        <div class="pointer-events-none absolute left-2 top-[0.9rem]">
          <WebccIcon name="site/search" class="h-5 w-5 fill-current text-txt" />
        </div>
        <div v-if="currentSearch.label" class="absolute right-2 top-[0.9rem] cursor-pointer opacity-20 hover:opacity-60" @click="clearWhere()">
          <WebccIcon name="site/x" class="h-5 w-5 fill-current text-txt" />
        </div>
        <div
          v-if="showSuggestions"
          class="absolute top-14 z-[2] flex w-full flex-col overflow-y-visible rounded bg-bgr shadow-md"
          :class="suggestions.length > 0 ? 'border border-bgr-300' : ''"
        >
          <div
            v-for="(suggestion, i) in suggestions"
            :key="i"
            :class="{ 'mb-2': i === suggestions.length - 1, 'bg-bgr-300': suggestion.selected, 'even:bg-bgr-100': !suggestion.selected }"
            class="flex cursor-pointer justify-between py-2 pl-8 pr-2 text-xs hover:bg-bgr-300"
            @click.prevent.stop="selectSuggestion(suggestion)"
          >
            <WebccIcon :name="'flags/' + suggestion.flag" class="absolute left-2 h-4 w-4 shrink-0" filled />
            <span :title="suggestion.code">{{ suggestion.label }}</span>
            <Text :key-prop="suggestion.levelLabel" />
          </div>
        </div>
      </label>
      <WebccButton variant="cta" class="h-12 w-1/4" @click="performSearch">
        <Text class="font-semibold" key-prop="search" />
      </WebccButton>
    </div>
  </section>
</template>

<script setup lang="ts">
const TOGGLE_KEY = 'searchbar'

export interface Item {
  type: string
  label: string
  code: string
  flag: string
  path: string
  levelLabel: string
  selected: boolean
}

const trPrefix = 'www.components.elements.SearchBox.'
defineExpose({ trPrefix })

withDefaults(
  defineProps<{
    fullWidth: boolean
  }>(),
  {
    fullWidth: true,
  },
)

const datepicker: Ref<HTMLInputElement | null> = ref(null)

const selectedItem: Ref<Item | undefined> = ref()
const suggestions: Ref<Item[]> = ref([])
const lastTimestamp = ref(new Date().getTime())
const currentSearch = ref({ path: useConfdata().raw?.conf?.prefixSearchpage, label: '' })

const showSuggestions = computed(() => {
  return useToggler().toggles(TOGGLE_KEY).value
})

onBeforeMount(() => {
  useToggler().register(TOGGLE_KEY)
})

function clearWhere() {
  currentSearch.value.label = ''
  currentSearch.value.path = useConfdata().raw?.conf?.prefixSearchpage
  suggestions.value = []
}

function setSuggestions(items: Suggestion[]) {
  const typeOrder = ['C', 'R', 'P', 'H', 'A', 'B', 'G']
  const sortedItems = items
    .filter((i) => {
      return typeOrder.includes(i.type)
    })
    .sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type))
  // console.log('sortedItems', sortedItems)
  suggestions.value = []
  // filter houses / residences - only one suggestion per house / residence
  // const houses = new Set()
  sortedItems
    // .filter((item) => {
    //   const regex = /[A-Z]{2}[0-9]{4}.[0-9]{1,4}.[0-9]{1,4}/
    //   if (item.code.match(regex)) {
    //     const houseCode = item.code.substring(0, item.code.lastIndexOf('.'))
    //     const house = houses.has(houseCode)
    //     houses.add(houseCode)
    //     return !house
    //   } else {
    //     return true
    //   }
    // })
    .forEach((item) => {
      suggestions.value.push({
        type: item.type,
        label: item.label,
        code: item.code,
        flag: item.country.toLowerCase(),
        path: item.slug,
        levelLabel: item.type,
        selected: false,
      })
    })
}

async function sendSuggestSearch(value: string) {
  try {
    // console.log('input value', value)
    const result = await useAutosuggest().suggest({
      query: value,
      language: useL10N().language,
      // TODO: Use values from settings
      type: 'search_as_you_type',
      source: 'focus',
      size: 20,
      fuzziness: 5,
    })
    setSuggestions(result!.Suggestions)
  } catch (error) {
    console.error('error during suggest search', error)
    return []
  }
}

async function onChange(event: Event) {
  const input = (event.target as HTMLInputElement).value
  currentSearch.value.label = input
  const timestamp = new Date().getTime()
  const diff = timestamp - lastTimestamp.value
  if (!input) {
    suggestions.value = []
  } else if (diff > 256) {
    try {
      await sendSuggestSearch(input)
      lastTimestamp.value = timestamp
    } catch (error) {
      console.error(error)
    }
  }
  if (suggestions.value.length > 0) {
    useToggler().toggle(TOGGLE_KEY, { state: true })
  } else {
    useToggler().toggle(TOGGLE_KEY, { state: false })
  }
}

async function selectSuggestion(item: Item) {
  useTracking().handlers?.qs.autosuggestItemSelected(item)
  Object.assign(currentSearch.value, item)
  suggestions.value = []
  if (item.type === 'H' || item.type === 'A' || item.type === 'B' || item.type === 'G') {
    await performSearch()
  } else {
    // target.value.countryCode = item.geo.country || ''
    // target.value.regionCode = item.geo.region || ''
    // target.value.placeCode = item.geo.place || ''
    nextTick(() => {
      datepicker.value?.click()
    })
  }
}

async function performSearch() {
  useTracking().handlers?.qs.submitSearch({ text: currentSearch.value.label })
  try {
    const query = {
      ...useParams().persistent,
    } as Record<string, string>

    await navigateTo({ path: useConfdata().languagePrefix + '/' + currentSearch.value.path, query })
  } catch (error) {
    console.error(error)
  }
}

async function handleKeydown(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowDown':
      if (!useToggler().toggles(TOGGLE_KEY)) {
        return
      }
      event.preventDefault()
      if (suggestions.value.length > 0) {
        let prev = -1
        suggestions.value.forEach((item, index) => {
          if (item.selected) {
            prev = index
            item.selected = false
          }
        })

        if (suggestions.value[prev + 1]) {
          selectedItem.value = suggestions.value[prev + 1]
          suggestions.value[prev + 1].selected = true
        } else {
          selectedItem.value = suggestions.value[0]
          suggestions.value[0].selected = true
        }
      } else {
        selectedItem.value = undefined
        // suggestions.value[0].selected = true
      }
      // this.$forceUpdate()
      break
    case 'ArrowUp':
      if (!useToggler().toggles(TOGGLE_KEY)) {
        return
      }
      event.preventDefault()
      if (suggestions.value.length > 0) {
        let prev = -1
        suggestions.value.forEach((item, index) => {
          if (item.selected) {
            prev = index
            item.selected = false
          }
        })

        if (suggestions.value[prev - 1]) {
          selectedItem.value = suggestions.value[prev - 1]
          suggestions.value[prev - 1].selected = true
        } else {
          selectedItem.value = suggestions.value[suggestions.value.length - 1]
          suggestions.value[suggestions.value.length - 1].selected = true
        }
      } else {
        selectedItem.value = undefined
        // suggestions.value[0].selected = true
      }
      // this.$forceUpdate()
      break
    case 'Enter':
      event.preventDefault()
      if (selectedItem.value) {
        selectSuggestion(selectedItem.value)
        useToggler().toggle(TOGGLE_KEY, { state: false })
      } else {
        // TODO: In den bedingten Block verschieben, wenn wir keine reguläre Quicksearch benötigen
        await performSearch()
      }
      break
    case 'Escape':
      event.preventDefault()
      if (suggestions.value.length > 0) {
        selectedItem.value = undefined
        useToggler().toggle(TOGGLE_KEY, { state: false })
      } else {
        ;(document.activeElement as HTMLInputElement).blur()
      }
      break
    default:
      break
  }
}
</script>
