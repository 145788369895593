<template>
  <nav class="-left-4 text-txt md:relative md:flex md:flex-wrap">
    <!-- Desktop menu -->
    <ul class="hidden md:flex">
      <li v-for="item in menuItems" :key="item.id + 'link'" class="whitespace-no-wrap cursor-pointer rounded text-txt-strongest hover:bg-bgr-100">
        <WebccLink
          anchor
          :to="item.href"
          :hash="item.hash"
          :target="item.target"
          class="block px-4 py-2 md:text-center"
          @click="tracking.handlers?.header.sitenavigationClick(item.id as 'partner' | 'lso' | 'topcountries' | 'inspirations' | 'homeowner')"
          >{{ item.label }}</WebccLink
        >
      </li>
    </ul>
    <transition name="fly">
      <MobileNavigation v-if="toggling.toggles('menu').value" :menu="menuItems" :links="props.links" />
    </transition>
  </nav>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.site.Header.' })
const toggling = useToggler()
const tracking = useTracking()

const props = defineProps<{
  menu: MenuNavItem[]
  links: Links
}>()

onBeforeMount(() => {
  toggling.register('menu')
})

onMounted(() => {
  transformMenuItems()
})

const menuItems = ref<HeaderMenuItem[]>([])

// Transforms the menu prop array to fit as a menu item
function transformMenuItems() {
  menuItems.value = props.menu.map((item, index) => {
    const { label, link } = item
    let { href } = link

    // If the id is not set, set it to the index
    const id = index.toString()

    // If the href has a hash, extract it and remove it with the  from the href and store it in the hash variable
    let hash = ''
    if (href && href.includes('#')) {
      hash = href.split('#')[1]
      // Remove the hash from the href
      href = href.split('#')[0]
    }

    if (!href) {
      href = ''
    }

    // If the id is homeowner, set the target to _blank // TODO: maybe change later?
    let target = ''
    if (id === 'homeowner') {
      target = '_blank'
    }

    return {
      id,
      hash,
      label,
      href,
      target,
    }
  })
}
</script>

<style>
/* Menu transition */
.fly-enter-active {
  animation: fly 0.3s ease-in-out;
}

.fly-leave-active {
  animation: fly 0.3s ease-in-out reverse;
}

@keyframes fly {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
