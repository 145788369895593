<template>
  <div ref="topRef" class="bg-bgr-50">
    <div v-if="!isIFrame && !useAB().isActive('dp-quicksearch')" class="bg-bgr-50 mb-2">
      <SearchBox :full-width="false" />
    </div>

    <div v-if="isIFrame" class="container-fluid-lg flex flex-row items-center justify-end pt-4">
      <BookmarkLink />
    </div>

    <DetailsAccommodation />

    <div class="container-fluid-lg mb-4">
      <DetailsTrustPilot :domain="domain" :resource="sites![0]" />
    </div>

    <TeaserSubDestinations v-if="accommodation" class="container-fluid-lg mb-4" :destination="getAccommodationDestination(accommodation)" />

    <BlocksUSPCorona v-if="additionalBody.USPBar" boxed :icons="additionalBody.USPBar" />

    <USPCards :why="additionalBody.USPCards.cards" />

    <USPWhy :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]" />

    <client-only v-if="useAB().isActive('userSurveyJotform')">
      <DetailsUserSurvey />
    </client-only>

    <FooterNewsletterSignup />
  </div>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.views.PageDetails.' })

const topRef = ref()

const screenSize = ref<ScreenSize>('sm')

const { domain, sites } = storeToRefs(useConfdata())
const additionalBody = computed(() => useConfdata().additionalBody as DetailpageBody)
const accommodation = computed(() => useConfdata().baseData)
const isIFrame = computed(() => useParams().iframe)

onMounted(() => {
  if (!window.location.hash) {
    window.scrollTo({ top: 0 })
  }
  nextTick(() => {
    document.documentElement.style.scrollBehavior = 'auto' // 'smooth'
    if (isIFrame.value && !window.location.hash) {
      topRef.value?.scrollIntoView({ behavior: 'smooth' })
    }
    setScreenSize()
    window.addEventListener('resize', setScreenSize)
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('resize', setScreenSize)
})

function setScreenSize() {
  screenSize.value = useScreen().current
}
</script>
