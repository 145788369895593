<template>
  <div class="rounded bg-bgr p-5 shadow">
    <WebccFreeCancellationInfo
      v-if="trip.checkin"
      v-track:impression="{ event: 'search:freeCancellationImpression' }"
      :checkin-date="trip.checkin"
      :free-cancellation-range="freeCancellationRange"
      class="mb-3"
      :locale="useL10N().locale"
    />
    <div v-for="condition in sortedConditions" :key="condition.daysBefore" class="text-sm md:text-base">
      <span>{{ formatPeriod(condition) }} {{ formatDescription(condition) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.CancelConditions.'
defineExpose({ trPrefix })

const props = defineProps<{
  conditions: CancelCondition[]
  trip: { checkin: Date }
}>()

const sortedConditions = computed(() => props.conditions.toSorted((a, b) => b.daysBefore - a.daysBefore))

const freeCancellationRange = computed(() => {
  return sortedConditions.value[0].percent === 0 ? sortedConditions.value[0].to : 0
})

function formatPeriod({ from, to, daysBefore }: CancelCondition) {
  if (!to) return daysBefore.toString()
  if (!from) return `${translatep('moreThan')} ${to}`
  if (!daysBefore) return `${translatep('until')} ${to}`
  return `${from} - ${to}`
}

function formatDescription({ to, percent }: CancelCondition) {
  if (!to) return translatep('full')
  return `${translatep('daysBefore')}: ${percent}% ${translatep('ofRental')}`
}
</script>
