<template>
  <div class="relative flex flex-col">
    <WebccLoaderOverlay v-if="loading" type="blocks" absolute />
    <button v-if="count > 1" class="my-6 inline-block max-w-full rounded border border-thm p-4 text-thm hover:bg-thm hover:text-thm-contrast" @click="toggle">
      <Text key-prop="showAllAccommodations" :count="count" />
    </button>
    <div v-if="open" class="flex flex-row flex-wrap justify-center gap-2">
      <WebccLink v-for="{ code, slug, name } in accommodations" :key="code" :to="`/${slug ?? ''}`" anchor class="snap-start rounded border-2 border-thm p-2">
        <div class="p-2 text-sm">
          <strong class="mb-2 text-black">{{ name }}</strong>
          <span class="flex flex-auto items-center text-xs font-bold leading-none text-[#232323]">
            {{ code }}
          </span>
        </div>
      </WebccLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Residence.'
defineExpose({ trPrefix })

const props = defineProps<{
  accom: TranslatedAccommodation
}>()

const open = ref(false)
const loading = ref(false)
const accommodations = ref<ResultDocument[]>([])

const codes = computed(() => props.accom.house?.accommodations ?? [])
const count = computed(() => codes.value.length)

function toggle() {
  useTracking().handlers?.detail.houseAccommodationsClick(props.accom.code)

  open.value = !open.value

  if (open.value) fetch()
}

async function fetch() {
  if (!codes.value.length || accommodations.value.length) return

  loading.value = true

  const { data, pending } = await useSearchApi().search({
    code: codes.value,
    pagesize: codes.value.length,
    language: useConfdata().language,
  })

  loading.value = pending.value
  accommodations.value = data.value?.docs ?? []
}
</script>
