<template>
  <footer v-if="!iframe" class="bg-bgr-300">
    <main class="container-fluid-lg grid grid-cols-1 gap-8 py-16 sm:grid-cols-2 md:grid-cols-3 md:gap-16">
      <section>
        <div v-if="siteSettings?.includes('contactinfo')" class="mb-8 md:mb-16">
          <Text component="h4" class="mb-2 text-xl font-light text-thm" key-prop="contact.heading" />
          <Text component="p" class="mt-2 text-base font-light leading-normal" key-prop="contact.support" />
          <Text component="p" class="mt-2 text-base font-light leading-normal" key-prop="contact.availability" />

          <WebccLink
            v-if="useFlag('footer-phone-number').value"
            anchor
            class="mt-4 flex items-center font-light hover:text-thm"
            :to="'tel:' + trimmedPhoneNumber"
          >
            <WebccIcon name="site/telefon-alt" class="mr-2 h-6 w-6 border-0 border-dotted border-txt" filled />
            {{ salesoffice?.phone }}
          </WebccLink>
          <WebccLink anchor class="mt-2 flex items-center font-light hover:text-thm" :to="'mailto:' + salesoffice?.email" :title="salesoffice?.email">
            <WebccIcon name="site/mail" class="mr-2 h-6 w-6 border-0 border-dotted border-txt" filled />
            {{ salesoffice?.email }}
          </WebccLink>
        </div>
        <div>
          <Text component="h4" :key-prop="company === 'ih' ? 'about.heading.ih' : 'about.heading.ic'" class="mb-2 text-xl font-light text-thm" />
          <ul class="mt-2 w-full">
            <li v-for="({ label, link }, i) in footer?.menu" :key="i">
              <WebccLink
                :anchor="link!.anchor"
                :to="link!.href"
                class="group mb-2 flex items-center justify-between border-b border-bgr-400 py-1 text-txt hover:border-thm hover:text-thm"
              >
                {{ label }}
                <WebccIcon :filled="true" name="site/chevron-right" class="-mr-1.5 h-5 w-5 text-txt group-hover:text-thm" />
              </WebccLink>
            </li>
            <li>
              <button
                v-if="useConfdata().siteCode === 'icDEde'"
                class="group mb-2 flex w-full items-center justify-between border-b border-bgr-400 py-1 text-txt hover:border-thm hover:text-thm"
                @click="feedback = true"
              >
                <Text component="span" key-prop="siteFeedback" />
                <WebccIcon :filled="true" name="site/chevron-right" class="-mr-1.5 h-5 w-5 text-txt group-hover:text-thm" />
              </button>
            </li>
            <li>
              <button
                v-if="storage.available"
                class="group mb-2 flex w-full items-center justify-between border-b border-bgr-400 py-1 text-txt hover:border-thm hover:text-thm"
                @click="openConfig"
              >
                <Text component="span" key-prop="cookieSettings" />
                <WebccIcon :filled="true" name="site/chevron-right" class="-mr-1.5 h-5 w-5 text-txt group-hover:text-thm" />
              </button>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div class="mb-8 md:mb-16">
          <Text component="h4" key-prop="social.heading" class="mb-2 text-xl font-light text-thm" />
          <div class="flex gap-5">
            <SocialMediaLink v-for="({ icon, link }, i) in footer?.socialMedia" :key="i + 'sm'" :target="icon" :link="link.href" />
          </div>
        </div>
        <div>
          <Text component="h4" key-prop="payment.heading" class="mb-2 text-xl font-light text-thm" />
          <Text class="mt-2 text-base font-light leading-normal" component="p" key-prop="payment.encrypted" />

          <ul class="mt-4 space-y-4">
            <li v-for="(pm, i) in paymentForms" :key="i + 'pm'" class="flex h-12 items-center justify-end gap-4 rounded border border-bgr-400 px-4 shadow">
              <WebccIcon
                v-if="paymentMethods[pm.type].icon"
                v-show="pm.type"
                :name="paymentMethods[pm.type].icon"
                :class="paymentMethods[pm.type].classes"
                filled
              />
              <Text component="div" class="ml-auto font-light" :key-prop="'payment.' + paymentMethods[pm.type].textkey" />
            </li>
          </ul>
        </div>
      </section>
      <section class="col-span-1 flex flex-col sm:col-span-2 md:col-span-1">
        <div class="grid grid-cols-1 gap-0 sm:grid-cols-2 sm:gap-8 md:grid-cols-1 md:gap-0">
          <div class="mb-8 flex flex-col md:mb-16">
            <Text component="h4" class="mb-2 text-xl font-light text-thm" key-prop="partner.migros" />
            <WebccIcon name="site/migros" class="h-12 w-32 rounded bg-bgr p-2 shadow-sm" filled />
          </div>

          <div class="mb-8 flex flex-col md:mb-16">
            <Text component="h4" class="mb-2 text-xl font-light text-thm" key-prop="partner.heading" />

            <div v-for="({ image, label }, i) in footer?.partnerships" :key="i + 'ps'" class="flex flex-col">
              <img :src="image?.asset" :alt="image?.alt" :height="image?.height" :width="image?.width" />
              <p class="text-sm font-light">{{ label }}</p>
            </div>
          </div>
        </div>
        <div v-if="!siteSettings?.includes('hideDomainSwitcher') && footer?.localeURLs" class="mt-auto flex flex-col">
          <Text component="h4" class="mb-2 text-xl font-light text-thm" key-prop="partner.domains" />

          <WebccButton
            :icon="'flags/' + currentDomain.flag"
            variant="white"
            class="flex items-center gap-2 self-start rounded text-lg shadow-sm"
            icon-filled
            @click="openDomainSwitcher"
          >
            {{ currentDomain.name }}
            <b class="ml-6 font-bold">{{ currentDomain.lang }}</b>
          </WebccButton>
        </div>
      </section>
    </main>

    <section class="flex h-16 items-center justify-center bg-bgr-200">
      <Text component="span" :params="{ year: new Date().getFullYear().toString() }" key-prop="copyright" />
    </section>
    <DomainLanguageSwitcher
      v-if="footer?.localeURLs"
      :domains="domains"
      :pagetype="pagetype || ''"
      :urls="footer.localeURLs ?? {}"
      :open="domLangOpen"
      @close="closeDomainSwitcher"
    />
  </footer>

  <footer v-else-if="showFooterLinks" class="mt-4 flex flex-col bg-bgr-100 p-4">
    <div class="flex flex-wrap justify-center">
      <WebccLink target="_blank" :to="links?.conditions?.href" :title="links?.conditions?.label" class="block w-max text-txt m-3 hover:text-thm">
        {{ links?.conditions?.label }}
      </WebccLink>
      <WebccLink target="_blank" :to="links?.privacyPolicy?.href" :title="links?.privacyPolicy?.label" class="block w-max text-txt m-3 hover:text-thm">
        {{ links?.privacyPolicy?.label }}
      </WebccLink>
      <WebccLink target="_blank" :to="links?.imprint?.href" :title="links?.imprint?.label" class="block w-max text-txt m-3 hover:text-thm">
        {{ links?.imprint?.label }}
      </WebccLink>
    </div>
  </footer>

  <WebccModal v-if="feedback" @close="feedback = false">
    <div class="relative flex flex-col overflow-hidden w-[95vw] h-[95vh] max-h-[648px] max-w-[600px] bg-bgr rounded">
      <Text
        component="div"
        class="h-10 px-4 py-2 font-bold text-left bg-[#ffa500] transition-all text-txt-contrast rounded-t"
        key-prop="siteFeedback"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      />
      <div class="absolute flex items-center justify-center h-5 w-5 top-2.5 right-4 rounded-full bg-bgr cursor-pointer" @click="feedback = false">
        <WebccIcon name="site/cross" class="h-4 w-auto"></WebccIcon>
      </div>
      <iframe src="https://form.jotform.com/232913817871362" class="w-full h-full overflow-scroll rounded-b" />
    </div>
  </WebccModal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { type Domains } from './DomainLanguageSwitcher.vue'

defineExpose({ trPrefix: 'www.components.footer.' })

const { pagetype, salesoffice, company, links, siteSettings, siteCode, footer } = storeToRefs(useConfdata())

const trimmedPhoneNumber = salesoffice.value?.phone.replace(' ', '')

const iframe = ref(useParams().iframe)

const storage = { available: true }
const feedback = ref(false)

const domains: Domains = {
  ic: {
    eur: [],
    int: [],
  },
  ih: {
    eur: [
      { flag: 'be', lang: 'NL', path: 'ihBEnl', name: 'België' },
      { flag: 'be', lang: 'FR', path: 'ihBEfr', name: 'Belgique' },
      { flag: 'cz', lang: 'CS', path: 'ihCZcs', name: 'Česko' },
      { flag: 'cz', lang: 'EN', path: 'ihCZen', name: 'Czech Republic' },
      { flag: 'dk', lang: 'DA', path: 'ihDKda', name: 'Danmark' },
      { flag: 'dk', lang: 'EN', path: 'ihDKen', name: 'Denmark' },
      { flag: 'de', lang: 'DE', path: 'ihDEde', name: 'Deutschland' },
      { flag: 'es', lang: 'ES', path: 'ihESes', name: 'España' },
      { flag: 'ee', lang: 'EN', path: 'ihEEen', name: 'Estonia' },
      { flag: 'ee', lang: 'FI', path: 'ihEEfi', name: 'Viro' },
      { flag: 'fr', lang: 'FR', path: 'ihFRfr', name: 'France' },
      { flag: 'gb', lang: 'EN', path: 'ihUKen', name: 'Great Britain' },
      { flag: 'hr', lang: 'HR', path: 'ihHRhr', name: 'Hrvatska' },
      { flag: 'ie', lang: 'EN', path: 'ihIEen', name: 'Ireland' },
      { flag: 'it', lang: 'IT', path: 'ihITit', name: 'Italia' },
      { flag: 'nl', lang: 'NL', path: 'ihNLnl', name: 'Nederland' },
      { flag: 'no', lang: 'NO', path: 'ihNOno', name: 'Norge' },
      { flag: 'no', lang: 'EN', path: 'ihNOen', name: 'Norway' },
      { flag: 'at', lang: 'DE', path: 'ihATde', name: 'Österreich' },
      { flag: 'at', lang: 'EN', path: 'ihATen', name: 'Austria' },
      { flag: 'pl', lang: 'PL', path: 'ihPLpl', name: 'Polska' },
      { flag: 'pt', lang: 'PT', path: 'ihPTpt', name: 'Portugal' },
      { flag: 'ch', lang: 'DE', path: 'ihCHde', name: 'Schweiz' },
      { flag: 'ch', lang: 'FR', path: 'ihCHfr', name: 'Suisse' },
      { flag: 'ch', lang: 'IT', path: 'ihCHit', name: 'Svizzera' },
      { flag: 'ch', lang: 'EN', path: 'ihCHen', name: 'Switzerland' },
      { flag: 'fi', lang: 'FI', path: 'ihFIfi', name: 'Suomi' },
      { flag: 'se', lang: 'SV', path: 'ihSEsv', name: 'Sverige' },
      { flag: 'se', lang: 'EN', path: 'ihSEen', name: 'Sweden' },
    ],
    int: [
      { flag: 'ih', lang: 'EN', path: 'ihCOMen', name: 'Interhome.com' },
      { flag: 'au', lang: 'EN', path: 'ihAUen', name: 'Australia' },
      { flag: 'ca', lang: 'EN', path: 'ihCAen', name: 'Canada' },
      { flag: 'ca', lang: 'FR', path: 'ihCAfr', name: 'Canada' },
      { flag: 'in', lang: 'EN', path: 'ihINen', name: 'India' },
      { flag: 'us', lang: 'EN', path: 'ihUSen', name: 'United States of America' },
      // { flag: 'ru', lang: 'RU', path: 'ihRUru', name: 'Росси́я' },
    ],
  },
}

const paymentMethods: Record<string, { icon: string; textkey: string; classes: string }> = {
  MAST: { icon: 'site/mastercard', textkey: 'MAST', classes: 'h-12 w-12' },
  VISA: { icon: 'site/visa', textkey: 'VISA', classes: 'h-12 w-12' },
  BLEU: { icon: '', textkey: '', classes: 'h-12 w-12' },
  CHECK: { icon: '', textkey: '', classes: 'h-12 w-12' },
  ECV: { icon: 'site/ecv', textkey: 'ECV', classes: 'h-8 w-24' },
  IDEAL: { icon: 'site/ideal', textkey: 'ID', classes: 'h-12 w-12' },
  TWINT: { icon: 'site/twint', textkey: 'TW', classes: 'h-20 w-16' },
  '': { icon: '', textkey: 'INV', classes: 'h-12 w-12' },
  PAYPAL: { icon: 'site/paypal', textkey: 'PAYPAL', classes: 'h-8 w-20' },
}

const showFooterLinks = computed(() => {
  return iframe.value && ['footer', 'full'].includes(iframe.value.layout.toString())
})

const currentDomain: ComputedRef<{ flag?: string; lang?: string; path?: string; name?: string }> = computed(() => {
  return company.value
    ? Object.values(domains[company.value])
        .flat()
        .find((domain) => {
          return domain.path === siteCode.value
        }) || {}
    : {}
})

const paymentForms = computed(() => {
  return salesoffice.value?.paymentForms
    .filter((p) => Object.keys(paymentMethods).includes(p.type))
    .sort((p1, p2) => {
      if (p2.mode === 'INV') {
        return -1
      }
      if (p1.mode === 'INV') {
        return 1
      }
      return 0
    })
})

const domLangOpen = ref(false)

function openConfig() {
  useConsent().configOpen = true
}
function openDomainSwitcher() {
  domLangOpen.value = true
}
function closeDomainSwitcher() {
  domLangOpen.value = false
}
</script>
