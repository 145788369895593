import { nanoid } from 'nanoid'

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) {
    let user = nanoid()
    let session = nanoid()
    const cycle = nanoid()
    const userFromCookie = useCookie('User-ID')
    if (userFromCookie.value) {
      user = userFromCookie.value
    }
    const sessionFromCookie = useCookie('Session-ID')
    if (sessionFromCookie.value) {
      session = sessionFromCookie.value
    }
    useCookie('User-ID', { expires: monthsAdd(new Date(), 3), sameSite: 'none', secure: true, path: '/' }).value = user
    useCookie('Session-ID', { sameSite: 'none', secure: true, path: '/' }).value = session
    useIDs().session = session
    useIDs().user = user
    useIDs().cycle = cycle
  }
})
