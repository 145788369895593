<template>
  <div v-if="show" class="flex flex-col rounded border-2 border-thm shadow-lg">
    <div class="flex flex-row bg-thm py-2 pl-2">
      <WebccIcon name="site/info" class="h-6 w-6 text-bgr" />
    </div>
    <div class="flex flex-col bg-bgr p-2">
      <Text key-prop="heading" class="text-thm" />
      <Text key-prop="info" />
    </div>
    <div class="flex flex-row justify-center bg-bgr p-2">
      <WebccButton @click="$emit('hide')">
        <Text key-prop="ok" />
      </WebccButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.favorites.Infobox.'
defineExpose({ trPrefix })

defineProps<{
  show?: boolean
}>()

defineEmits<{ (e: 'hide'): void }>()
</script>
