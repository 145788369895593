/*
 *   Spezifikation:
 *   https://wiki.hotelplan.com/display/TSICHDDWEBCC/09+-+Quick+Search
 */

export default {
  submitSearch,
  autosuggestItemSelected,
  numberPax,
}

// 1a, 1b
function submitSearch({ text, date, duration, pax }: { text?: string; date?: string; duration?: string | number; pax?: number }) {
  useTracking().send({
    event: 'quick search',
    eventCategory: 'Quick Search',
    eventAction: 'Destination',
    eventLabel: text || 'empty',
    eventNonInteraction: false,
    Date: date || 'empty',
    duration: duration || 'empty',
    Persons: pax || 'empty',
  })
}

// 1c
function autosuggestItemSelected(item: AutosuggestItem) {
  const label = item.label + (item.levelLabel ? ` / ${item.levelLabel}` : '')
  // console.log("autosuggest", item.destinationLevelText, item.LEVEL, item.PLACECODE, item.SEO, item.DESTINATION)
  useTracking().send({
    event: 'quick search',
    eventCategory: 'Quick Search',
    eventAction: 'Search Suggest',
    eventLabel: label,
    eventNonInteraction: false,
  })
}
// 4
function numberPax(number: string) {
  useTracking().send({
    event: 'quick search',
    eventCategory: 'Quick Search',
    eventAction: 'Persons',
    eventLabel: number,
    eventNonInteraction: false,
  })
}
