<template>
  <Transition name="fade-slide" appear>
    <div v-if="visible" class="fixed bottom-16 z-20 w-full px-4 sm:bottom-auto sm:right-4 sm:top-16 sm:w-auto sm:px-0">
      <div class="group flex cursor-pointer gap-4 rounded-lg border bg-bgr p-4 shadow-xl transition-all hover:shadow-2xl" @click="toBooking">
        <div>
          <img :src="image" class="h-20 grow rounded-lg" />
        </div>
        <div class="flex grow flex-col gap-1 sm:w-64">
          <p class="line-clamp-1 w-full font-medium">{{ accom?.type.translation }} {{ accom?.name }}</p>
          <p class="text-txt-weak">{{ dates }} &bull; <Text :prefix="trPrefix" key-prop="pax_s" :count="trip?.pax" /></p>
          <div class="mt-1 flex items-center gap-2">
            <Text :prefix="trPrefix" key-prop="completeBooking" component="p" class="font-medium group-hover:underline" />
            <WebccIcon name="site/chevron-right" class="h-6 w-6 text-txt" filled />
          </div>
        </div>
        <div class="flex" @click.stop="close">
          <div class="flex h-7 w-7 items-center justify-center rounded-full hover:bg-bgr-200">
            <WebccIcon name="site/x" class="h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.ui.BookingTeaser.'

defineExpose({ trPrefix })

const lastBooking = ref<BookingPending>()

const enabled = computed(() => useAB().isActive('dialogInterruptedBooking') && useConfdata().siteSettings?.includes('bookingTeaser'))
const visible = computed(() => enabled.value && lastBooking.value)

const accom = computed(() => lastBooking.value?.accom)
const trip = computed(() => lastBooking.value?.trip)
const image = computed(() => accom.value && useURLs().buildImageUrl(accom.value.image, 'tr:n-srp_hitbox_sm'))

const dates = computed(() => {
  const { checkin, checkout } = trip.value ?? {}
  return checkin && checkout ? [checkin, checkout].map((date) => toShortDate(date, useL10N().language)).join(' - ') : undefined
})

watch(enabled, init)

onMounted(init)

async function init() {
  if (!enabled.value) return

  lastBooking.value = await useBookingsPending().getLast()

  if (!lastBooking.value) return

  useTracking().handlers?.start.showedTeaserToBooking()
}

function toBooking() {
  useTracking().handlers?.start.clickedTeaserToBooking()

  if (!lastBooking.value?.url) return

  window.open(useURLs().buildValidUrl(lastBooking.value.url), useParams().iframe ? '_self' : undefined)
}

function close() {
  lastBooking.value = undefined
  useBookingsPending().dismissLast()
  useTracking().handlers?.start.closedTeaserToBooking()
}
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
@media screen and (max-width: 575px) {
  .fade-slide-enter-from,
  .fade-slide-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>
