export function createBookingPending(accommodation: TranslatedAccommodation, trip: UpdateTrip, options?: { isOption: boolean }): BookingPending {
  return {
    accom: {
      code: accommodation.code,
      name: accommodation.name,
      slug: accommodation.slug,
      country: { code: accommodation.country?.code },
      type: { translation: accommodation.type.translation },
      evaluation: { stars: accommodation.evaluation.stars },
      image: ((useSeason() === 's' ? accommodation.media?.images_s : accommodation.media?.images_w) ?? [])[0],
    },
    trip:
      trip.checkin && trip.checkout && trip.pax
        ? {
            checkin: toDate(trip.checkin),
            checkout: toDate(trip.checkout),
            duration: calculateDuration(trip.checkin, trip.checkout),
            pax: trip.pax,
          }
        : undefined,
    isOption: options?.isOption,
    timestamp: new Date().toISOString(),
  }
}
