export const useUser = defineStore('user', () => {
  const raw = ref<UserData>()

  const signedIn = computed(() => !!raw.value)
  const email = computed(() => raw.value?.contacts.email)
  const firstName = computed(() => raw.value?.contacts.firstName)
  const lastName = computed(() => raw.value?.contacts.lastName)
  const name = computed(() => [firstName.value, lastName.value].filter(Boolean).join(' '))
  const initials = computed(() => (firstName.value && lastName.value ? `${firstName.value[0]}${lastName.value[0]}` : ''))
  const avatarUrl = computed(() => raw.value?.avatarUrl)

  async function load() {
    if (!useAuth().isAuthorized) return

    raw.value = (await useGuestWorldApi().user()).data.value ?? undefined
  }

  return { signedIn, email, name, initials, avatarUrl, load }
})
