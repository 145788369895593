<template>
  <div />
</template>

<script setup lang="ts">
const trPrefix = 'www.components.notifications.'

onMounted(() => {
  if (useAB().isActive('guestWorld') && useParams().all.signedIn) notifySignedIn()
})

function notifySignedIn() {
  if (!useUser().signedIn) return

  useToaster().success(`${translate(`${trPrefix}signedIn.text`)} <strong>${useUser().email}</strong>`, { delay: 300 })
}
</script>
