import { toast } from 'vue3-toastify'

export default function useToaster() {
  function _toast(type: ToastType, text: string, options?: ToastOptions) {
    toast(text, { type, delay: options?.delay, autoClose: options?.duration ?? 4000 })
  }

  function error(text: string, options?: ToastOptions) {
    _toast('error', text, options)
  }

  function warning(text: string, options?: ToastOptions) {
    _toast('warning', text, options)
  }

  function success(text: string, options?: ToastOptions) {
    _toast('success', text, options)
  }

  function info(text: string, options?: ToastOptions) {
    _toast('info', text, options)
  }

  return { error, warning, success, info }
}
