<script lang="ts">
export default {
  props: {
    tag: { type: String, default: 'span' },
    path: { type: String, required: true },
    prefix: { type: String, default: '' },
  },
  setup(props) {
    const slots = useSlots()

    const translation = translate((props.prefix + props.path) as TranslationKey)

    const children: VNode[] = []
    const combined: (string | VNode | undefined)[] = []

    const splitTranslation = translation.split(/{.+?}/g) // split translation so we only have the text parts left
    const matches = translation.matchAll(/{(.+?)}/g) // gather the parts to be replaced

    ;[...matches].forEach((match) => {
      // Use content of capture group for VNode creation. Do nothing if placeholder is missing
      if (slots[match[1]]) {
        children.push(slots[match[1]]!()[0])
      } else {
        const text = `An issue occurred with textresource '${props.path}' in locale '${useL10N().locale}'. An unknown placeholder '${
          match[1]
        }' was passed in, but only these placeholders '${Object.keys(slots)}' are defined.`
        console.error(text)
        // $sentry.captureException(new Error(text))
      }
    })

    splitTranslation.forEach((t) => {
      combined.push(t) // insert one VNode after each text part
      combined.push(children.shift()) // This will insert undefined for the last iteration, which will be ignored in the output.
    })

    return () => h(props.tag, [combined])
  },
}
</script>
