<template>
  <div class="mb-4 grid aspect-[2/1] w-full grid-cols-4 grid-rows-2 gap-2 rounded">
    <div class="col-start-1 col-end-3 row-start-1 row-end-3 h-full cursor-pointer bg-bgr-100" @click="openGalleryModal(images[0].id)">
      <img class="h-full w-full rounded-l object-cover" :src="images[0].src + imageTransformation.left" :alt="images[0].alt" :title="images[0].title" />
    </div>
    <div class="col-start-3 col-end-5 row-start-1 row-end-2 h-full cursor-pointer bg-bgr-100" @click="openGalleryModal(images[1].id)">
      <img class="h-full w-full rounded-tr object-cover" :src="images[1].src + imageTransformation.rightTop" :alt="images[1].alt" :title="images[1].title" />
    </div>
    <div class="col-start-3 col-end-5 row-start-2 row-end-3 cursor-pointer bg-bgr-100 md:col-start-3 md:col-end-4" @click="openGalleryModal(images[2].id)">
      <img
        class="h-full w-full rounded-br object-cover md:rounded-none"
        :src="images[2].src + imageTransformation.rightBottomLeft"
        :alt="images[2].alt"
        :title="images[2].title"
      />
    </div>
    <div class="row-start-2 row-end-3 hidden cursor-pointer bg-bgr-100 md:col-start-4 md:col-end-5 md:block" @click="openGalleryModal(images[3].id)">
      <img
        class="h-full w-full rounded-br object-cover"
        :src="images[3].src + imageTransformation.rightBottomRight"
        :alt="images[3].alt"
        :title="images[3].title"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Gallery.'
defineExpose({ trPrefix })

const emit = defineEmits<{ 'open-gallery-modal': [id: string] }>()

interface Props {
  accom?: TranslatedAccommodation
  checkin?: Date
  windowWidth?: number
  screenSize?: string
}

const props = withDefaults(defineProps<Props>(), {
  accom: undefined,
  checkin: undefined,
  windowWidth: 0,
  screenSize: 'md',
})

const transformations = {
  md: {
    left: '/tr:h-480,cm-pad_resize,pr-true',
    rightTop: '/tr:w-480,cm-pad_resize,pr-true',
    rightBottomLeft: '/tr:w-480,cm-pad_resize,pr-true',
    rightBottomRight: '/tr:w-480,cm-pad_resize,pr-true',
  },
  lg: {
    left: '/tr:h-640,cm-pad_resize,pr-true',
    rightTop: '/tr:w-640,cm-pad_resize,pr-true',
    rightBottomLeft: '/tr:h-320,cm-pad_resize,pr-true',
    rightBottomRight: '/tr:h-320,cm-pad_resize,pr-true',
  },
}

interface DetailsImage {
  id: string
  src: string
  alt: string
  title: string
}

const imageTransformation = computed(() => {
  return props.screenSize === 'xl' || props.screenSize === 'lg' ? transformations.lg : transformations.md
})

const season = computed(() => {
  return useSeason(props.checkin)
})

const images = computed(() => {
  const images: DetailsImage[] = []
  const imageKey = `images_${season.value}`

  if ((props.accom?.media[imageKey as keyof Media] as string[]).length > 0) {
    ;(props.accom?.media[imageKey as keyof Media] as string[]).forEach((image) => {
      const title = translatep(props.accom!.media.items[image].type)
      const alt = `${title}|${props.accom?.name}|${props.accom?.region.name}|${props.accom?.place.name}`
      images.push({
        id: image,
        src: `${useRuntimeConfig().public.imagekitBase}/${image}`,
        alt,
        title,
      })
    })
  }
  return images
})

function openGalleryModal(imgId: string) {
  emit('open-gallery-modal', imgId)
}
</script>
