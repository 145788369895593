<template>
  <button class="rounded focus:outline-none focus:ring-2 focus:ring-offset-2" :class="classes" :disabled="disabled || loading">
    <div class="flex items-center justify-center" :class="loading ? 'opacity-0' : 'opacity-100'">
      <WebccIcon v-if="icon" :class="iconclasses" :name="icon" :filled="iconFilled" />
      <slot>
        <Text v-if="tr" component="span" key-prop="tr" />
        <span v-else>{{ label }}</span>
      </slot>
    </div>
    <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <WebccLoader :cta="variant === 'cta'" type="circle" size="sm" />
    </div>
  </button>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: getCurrentInstance()?.parent?.exposed?.trPrefix || '' })

export type Variant = 'theme' | 'cta' | 'white' | 'grey' | ''

const variants: Record<Variant, { outline: string; framed: string; default: string }> = {
  theme: {
    outline: 'bg-bgr text-thm border border-thm transition hover:bg-thm hover:text-thm-contrast focus:bg-thm focus:text-thm-contrast focus:ring-thm',
    framed: 'bg-thm text-thm-contrast border border-thm-contrast transition hover:bg-thm-hover focus:bg-thm-hover focus:ring-thm-hover',
    default: 'bg-thm text-thm-contrast hover:bg-thm-hover transition focus:bg-thm-hover disabled:grayscale-50 focus:ring-thm-hover',
  },
  cta: {
    outline: 'bg-bgr text-cta border border-cta hover:bg-cta transition hover:text-cta-contrast focus:bg-cta focus:text-cta-contrast focus:ring-cta',
    framed: 'bg-cta text-cta-contrast border border-cta-contrast transition hover:bg-cta-hover focus:bg-cta-hover focus:ring-cta-hover',
    default: 'bg-cta text-cta-contrast hover:bg-cta-hover transition focus:bg-cta-hover focus:ring-cta-hover',
  },
  white: {
    outline:
      'bg-bgr text-txt border border-bgr-300 transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    framed:
      'bg-bgr text-txt border border-txt hover:bg-bgr-100 transition hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    default: 'bg-bgr text-txt hover:bg-bgr-100  transition focus:bg-bgr-100 focus:text-txt-stronger focus:ring-bgr-100',
  },
  grey: {
    outline:
      'bg-bgr-200 text-txt border border-bgr-300 transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    framed:
      'text-txt transition focus:text-txt-stronger focus:ring-bgr-100 border border-bgr-300 bg-bgr-100 hover:border-bgr-400 hover:bg-bgr-300 focus:bg-bgr-300',
    default:
      'bg-bgr-200 text-txt transition hover:bg-bgr-400 focus:bg-bgr-400 focus:text-txt-weak focus:ring-bgr-100 border border-bgr-500 disabled:border-transparent disabled:bg-bgr-100 disabled:hover:bg-bgr-100',
  },
  '': {
    outline:
      'bg-bgr-200 text-txt border border-bgr-300 transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    framed:
      'bg-bgr-200 text-txt border border-txt transition hover:bg-bgr-100 hover:text-txt-stronger hover:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger focus:border-bgr-700 focus:ring-bgr-100',
    default: 'bg-bgr-200 text-txt transition hover:bg-bgr-100  focus:bg-bgr-100 focus:text-txt-stronger focus:ring-bgr-100',
  },
}

interface Props {
  label?: string
  variant?: Variant
  size?: Size
  icon?: string
  iconFilled?: boolean
  disabled?: boolean
  loading?: boolean
  tr?: string
  outline?: boolean
  framed?: boolean
  round?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  variant: '',
  size: 'md',
  icon: undefined,
  iconFilled: false,
  tr: '',
})

const iconNoText = computed(() => {
  return props.icon && !props.label && Object.keys(useSlots()).length === 0
})

const colorclasses = computed(() => {
  const variant = Object.keys(variants).includes(props.variant) ? props.variant : 'white'
  if (props.outline) {
    return variants[variant].outline
  } else if (props.framed) {
    return variants[variant].framed
  } else {
    return variants[variant].default
  }
})

const sizeclasses = computed(() => {
  if (iconNoText.value) {
    return 'px-2 py-2'
  }
  switch (props.size) {
    case 'sm':
      return 'py-1 px-2 md:px-3 md:py-1.5 text-sm'
    case 'md':
      return 'px-3 md:px-4 py-1.5 text-sm md:text-base font-light'
    case 'lg':
      return ' px-4 py-2 text-lg font-light'
  }
})

const classes = computed(() => {
  let base = 'disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-70'
  if (props.icon || props.loading) {
    base += ' flex items-center'
  }
  if (props.loading) {
    base += ' opacity-70 relative'
  }
  if (props.round) {
    base += ' rounded-full'
  }
  if (props.disabled) {
    base += ' disabled'
  }
  return sizeclasses.value + ' ' + colorclasses.value + ' ' + base
})

const iconclasses = computed(() => {
  if (iconNoText.value) {
    return 'h-5 w-5'
  }
  switch (props.size) {
    case 'sm':
      return '-ml-1 h-4 w-4 mr-1'
    case 'md':
      return '-ml-1.5 h-5 w-5 mr-2'
    case 'lg':
      return '-ml-1.5 h-6 w-6 mr-2'
  }
})
</script>
