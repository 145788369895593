export default defineNuxtRouteMiddleware(async () => {
  if (!import.meta.server) return

  await usePartner().init()

  const { id, jwt, extra } = usePartner()
  if (jwt) {
    setCookie('jwt', jwt, { expires: hoursAdd(new Date(), 12) })
  } else if (id && id !== useCookies().partner?.split('&')?.[0]) {
    setCookie('partner', id, { expires: daysAdd(new Date(), 30) })
  }

  // partner id and extra should be presented in persisted params
  const params = useParams()

  if (id) {
    params.persistent.partnerid = id
    if (extra) {
      params.persistent.partnerextra = extra
    }
  }
})

function setCookie(name: string, value: any, options?: { expires?: Date }) {
  useCookie(name, { secure: true, sameSite: 'none', path: '/', httpOnly: true, ...(options ?? {}) }).value = value
}
