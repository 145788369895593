<template>
  <section>
    <WebccHeading :title="translatep('headings.destination') + ' ' + place.name" :level="4" />

    <template v-if="ih">
      <p v-if="general" class="prose-md prose max-w-none" v-text="general"></p>

      <template v-if="summer">
        <Text key-prop="headings.summer" component="h6" class="mt-2 text-thm" />
        <p class="prose-md prose max-w-none" v-text="summer"></p>
      </template>

      <template v-if="winter">
        <Text key-prop="headings.winter" component="h6" class="mt-2 text-thm" />
        <p class="prose-md prose max-w-none" v-text="winter"></p>
      </template>
    </template>

    <template v-if="ic">
      <p v-if="prosa" class="prose-md prose mt-2 max-w-none" v-text="prosa"></p>
    </template>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.details.Description.'
defineExpose({ trPrefix })

const props = withDefaults(defineProps<{ place?: TransPlace }>(), {
  place: () => ({}) as TransPlace,
})

const desc = computed(() => {
  return props.place.desc || {}
})

const general = computed(() => {
  if (!desc.value.general || desc.value.general === 'text missing') {
    return ''
  }
  try {
    return desc.value.general
    // return decodeURIComponent(desc.value.general) // .replace(/\+/g, '%20')) : ''
  } catch (e) {
    console.log('error decoding dec.general', e)
    return desc.value.general
  }
})

const summer = computed(() => {
  if (desc.value.summer && desc.value.summer !== 'text missing') {
    return desc.value.summer
    // return decodeURIComponent(desc.value.summer) // .replace(/\+/g, '%20')) : ''
  }
  return ''
})

const winter = computed(() => {
  if (desc.value.winter && desc.value.winter !== 'text missing') {
    // let w
    try {
      return desc.value.winter
      // w = decodeURIComponent(desc.value.winter) // .replace(/\+/g, '%20')) : ''
      // return w
    } catch (e) {
      console.log('error decoding desc.winter', desc.value.winter)
    }
    return desc.value.winter
  }
  return ''
})

const prosa = computed(() => {
  // console.log('PROSA:', desc.value.prosa)
  if (desc.value.prosa && desc.value.prosa !== 'text missing') {
    try {
      return desc.value.prosa
      // return decodeURIComponent(desc.value.prosa) // .replace(/\+/g, '%20')) : ''
    } catch (e) {
      return desc.value.prosa
    }
  }
  return ''
})

const generalOrSummerOrWinter = computed(() => {
  return general.value || summer.value || winter.value
})

const ih = computed(() => {
  // true wenn ih und ih-Daten
  return useConfdata().company === 'ih' || (useConfdata().company === 'ic' && !prosa.value)
})

const ic = computed(() => {
  return useConfdata().company === 'ic' || (useConfdata().company === 'ih' && !generalOrSummerOrWinter.value)
})
</script>
