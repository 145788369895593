export const ALLOWED_SEARCH_PARAMS = Object.freeze({
  country: true,
  region: true,
  place: true,
  bedrooms: true,
  bathrooms: true,
  house: true,
  apartment: true,
  detached: true,
  villa: true,
  chalet: true,
  farmhouse: true,
  studio: true,
  casa: true,
  swiss_peak: true,
  workation: true,
  sustainable: true,
  stars: true,
  pool: true,
  pool_children: true,
  pool_indoor: true,
  pool_private: true,
  pets: true,
  sea: true,
  lake: true,
  ski: true,
  center: true,
  wlan: true,
  aircondition: true,
  parking: true,
  garage: true,
  'balcony-or-terrace': true,
  dishwasher: true,
  washingmachine: true,
  tv: true,
  sea_or_lake_view: true,
  bbq: true,
  boat: true,
  cots: true,
  hottub: true,
  fireplace: true,
  sauna: true,
  // wheelchair: true,
  charging_station: true,
  last_minute: true,
  special_offer: true,
  'discount-20': true,
  cheapcheap: true,
  early_booker: true,
  'min-price': true,
  maxPrice: true,
  familyfriendly: true,
  holiday_resort: true,
  residence: true,
  citytrips: true,
  utoring: true,
  fishing: true,
  fishing_certified: true,
  skiing: true,
  hiking: true,
  golfing: true,
  cycling: true,
  wellness: true,
  tennis: true,
  surfing: true,
  sailing: true,
  mountainbiking: true,
  riding: true,
  crosscountryskiing: true,

  page: true,
  sorting: true,
  q: true,

  checkin: true,
  checkout: true,
  duration: true,
  pax: true,
  season: true,

  lat: true,
  lon: true,
  radius: true,
})

export const ALLOWED_QUERY_PARAMS = Object.freeze({
  ...ALLOWED_SEARCH_PARAMS,
  signedIn: true,
})

export function unpack(obj: Params): PlainParams {
  const result: PlainParams = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, unpack(value))
    } else if (value) {
      result[key] = value
    }
  })

  return result
}

export function asQueryParams(params: PlainParams): Record<string, string> {
  return Object.fromEntries(Object.entries(params).map(([key, value]) => [key, value.toString()]))
}

export function removeFalsy(source: Params): Params {
  return Object.fromEntries(Object.entries(source).filter(([, value]) => !!value))
}
