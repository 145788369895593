<template>
  <div ref="mapRef"><slot /></div>
</template>

<script setup lang="ts">
declare global {
  interface Window {
    initMap: () => void
    google: {
      maps: {
        Map: google.maps.Map
        Marker: google.maps.Marker
        version: string
      }
    }
  }
}

const props = withDefaults(
  defineProps<{
    domain?: string
    locale: string
    zoom?: number
    center?: { lat: number; lng: number }
    marker?: string
    ui?: boolean
  }>(),
  {
    zoom: 12,
    domain: 'www.interhome.com',
    marker: '',
    ui: true,
    center: () => {
      return {
        lat: 45,
        lng: 10,
      }
    },
  },
)

const emit = defineEmits<{
  (e: 'loaded'): void
}>()

const map: Ref<google.maps.Map | null> = ref(null)
const mapRef: Ref<HTMLDivElement | null> = ref(null)

defineExpose({ zoom: changeZoom, wrapper: mapRef })

onMounted(async () => {
  await loadGoogleMaps()
})

async function loadGoogleMaps() {
  if (!window.google?.maps?.version) {
    console.log(' --- loading google maps ---')
    const options = {
      language: props.locale,
      apiKey: useRuntimeConfig().public.googlemaps.apiKey || '',
    }
    const Loader = (await import('@googlemaps/js-api-loader')).Loader

    const loader = new Loader(options)

    await loader.importLibrary('core')
    console.log(' --- loaded google maps ---', window.google.maps.version)
  }
  const newmap = new window.google.maps.Map(mapRef.value!, {
    center: props.center,
    zoom: props.zoom || 12,
    gestureHandling: 'greedy',
    scrollwheel: false,
  })
  const marker = new window.google.maps.Marker({
    position: props.center,
    title: props.marker,
  })
  marker.setMap(newmap)
  map.value = newmap
  emit('loaded')
}

function changeZoom(delta: number) {
  map.value?.setZoom(map.value.getZoom()! + delta)
}
</script>
