<template>
  <main class="flex w-full flex-col items-center bg-bgr-100">
    <Text component="h1" key-prop="errors.title" class="mb-4 mt-4 text-center text-3xl font-normal leading-normal text-[#0096db]" />
    <article class="mx-2 my-4 w-full max-w-screen-md rounded bg-bgr p-4 text-black shadow-md md:w-full">
      <Text component="h4" class="text-xl text-[#e43956]" key-prop="errors.messages.notFound" />
    </article>
  </main>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: string
  clearError: Function
}>()

watch(
  () => useConfdata().pagetype,
  () => {
    props.clearError()
  },
)
</script>
