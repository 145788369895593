import type { IncomingMessage } from 'http'
import type { Context, Unleash, Variant } from 'unleash-client'

export const PERSISTED_UNLEASH_STATE = '__unleash' as const

export function getUnleashDataForContext(client: Unleash, userCtx: Context) {
  const features: Record<string, unknown> = {}
  const payloads: Record<string, unknown> = {}
  client.getFeatureToggleDefinitions().forEach((toggleDefinition: { name: any }) => {
    const { name } = toggleDefinition
    const isEnabled = client.isEnabled(name, userCtx)
    const variant = client.getVariant(name, userCtx)
    features[name] = isEnabled && (variant?.enabled ? variant.name : isEnabled)
    if (variant?.enabled) {
      payloads[name] = getVariantPayloadValue(variant)
    }
  })

  return {
    features,
    payloads,
  }
}

export function getVariantPayloadValue(variant: Variant): string | number | object | null {
  if (!variant.payload) return null
  const { type, value } = variant.payload
  if (type === 'json') return JSON.parse(value)
  if (type === 'number') return Number(value)
  return value
}

export function getUnleashContextFromRequest(req: IncomingMessage) {
  return {
    userId: getUserId(req),
    domain: getDomain(req),
    remoteAddress: getRemoteAddress(req),
    properties: {
      language: getUserLanguage(req),
    },
  }
}

export function getUserId(req: IncomingMessage) {
  return getRequestCookies(req)['User-ID']
}

export function getRemoteAddress(req: IncomingMessage) {
  return (req.headers['x-real-ip'] as string) || (req.headers['x-forwarded-for'] as string) || req.socket.remoteAddress || req.connection.remoteAddress
}

export function getUserLanguage(req: IncomingMessage) {
  try {
    const cookies = getRequestCookies(req)
    if ('i18n_redirected' in cookies && cookies.i18n_redirected.length >= 2) {
      return cookies.i18n_redirected.substr(0, 2).toLowerCase()
    }
  } catch (e) {
    console.error(`Unleash.utils getUserLanguage error`, e)
    // no throw
  }
  return req?.headers?.['accept-language']?.split(',')[0] || 'en'
}

function getRequestCookies(req: IncomingMessage) {
  return Object.fromEntries(req?.headers.cookie?.split(';').map((c) => c.trim().split('=')) || [])
}

function getDomain(req: IncomingMessage): string | undefined {
  return req.headers.host
    ?.replace(/-/g, '.')
    .match(/((book)?interhome|interchalet)\.(co.uk|com.au|\w+)/i)?.[0]
    .toLowerCase()
}
