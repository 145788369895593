import * as Sentry from '@sentry/vue'

export const IGNORED_ERROR_CODES = [401, 403, 500]

/**
 * Sentry callback to skip sending certain types of errors to Sentry
 * @param event
 * @param hint
 * @returns
 */
export function beforeSend(event: Sentry.Event, hint: Sentry.EventHint, errorCodes?: number[]) {
  const error = hint && hint.originalException
  if (error && (error as any).response) {
    const response = (error as any).response
    const statusCode = response.status
    if ((errorCodes ?? IGNORED_ERROR_CODES).includes(statusCode)) {
      // Discard event
      return null
    }
  }
  return event
}
