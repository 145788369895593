<template>
  <div
    :class="[{ 'border-txt-weak bg-bgr-100': onFocus, 'rounded-full': round, rounded: !round, border, shadow }, [sizeclasses]]"
    class="relative rounded border-txt-weaker text-txt transition-all duration-200 focus:border-txt-weak"
    @mouseover="labelHover = true"
    @mouseleave="labelHover = false"
  >
    <select
      v-bind="$attrs"
      :id="name"
      :name="name"
      :value="modelValue"
      class="form-select h-full w-full border-none py-0 leading-5 outline-none ring-0 focus:bg-bgr-100 focus:outline-none focus:ring-0"
      :class="{
        'bg-none': checkmarks && modelValue,
        'pt-4': label !== '',
        'px-3': size !== 'sm',
        'pl-8 pr-6': icon && !label,
        'rounded-full': round,
        rounded: !round,
        'text-xs': size === 'sm',
      }"
      @change="$emit('update:modelValue', ($event.target as HTMLSelectElement).value)"
      @focus="
        () => {
          onFocus = true
          delayedResize()
        }
      "
      @blur="
        () => {
          onFocus = false
          delayedResize()
        }
      "
    >
      <option v-for="(o, i) in options" :key="i" :value="valueOf(o)" :selected="valueOf(o) === modelValue">
        {{ o.label !== undefined ? o.label : o }}
      </option>
    </select>
    <label
      v-if="label"
      ref="input"
      v-tooltip="{ content: translatep(label), triggers: [], shown: showTooltip && labelHover }"
      :for="name"
      class="pointer-events-none absolute left-0 w-full overflow-hidden text-ellipsis whitespace-nowrap pl-3 pr-8 font-medium text-txt-weak transition-all duration-300 hover:bg-thm-hover"
      :class="labelSizeclasses"
    >
      <WebccIcon v-if="icon" :name="icon" class="pointer-events-none absolute top-0 z-1 h-full w-4 text-txt opacity-60" aria-hidden="true" />
      <span ref="labelText" :class="{ 'pl-6': icon }">
        <Text :key-prop="label" />
        <sup v-if="required">*</sup>
      </span>
    </label>
    <WebccIcon v-else-if="icon" :name="icon" class="pointer-events-none absolute left-2 top-0 z-1 h-full w-4 text-thm opacity-60" aria-hidden="true" />
    <WebccIcon
      v-if="checkmarks && modelValue && valid === true"
      name="site/tick"
      class="absolute right-5 top-1/2 h-4 w-4 -translate-y-1/2 text-suc"
      fill="currentColor"
      stroke-color="none"
      stroke-width="2"
    />
    <WebccIcon
      v-if="checkmarks && modelValue && valid === false"
      name="site/cross"
      class="absolute right-5 top-1/2 h-4 w-4 -translate-y-1/2 text-err"
      fill="none"
      stroke-color="currentColor"
      stroke-width="2"
    />
  </div>
</template>

<script setup lang="ts">
type OptionValue = string | number
type Option = { label: string; value: OptionValue }

interface Props {
  modelValue?: string | string[] | number | number[]
  options: Option[]
  required?: boolean
  checkmarks?: boolean
  valid?: boolean
  round?: boolean
  border?: boolean
  shadow?: boolean
  name?: string
  type?: string
  icon?: string
  label?: string
  size?: 'md' | 'sm'
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  name: '',
  type: '',
  icon: '',
  label: '',
  border: true,
  shadow: false,
  size: 'md',
})

defineEmits<{ (e: 'update:modelValue', value: string): void }>()

const onFocus = ref(false)
const labelHover = ref(false)
const labelWidth = ref(-1)
const inputWidth = ref(-1)

const labelText: Ref<HTMLSpanElement | null> = ref(null)
const input: Ref<HTMLInputElement | null> = ref(null)

const sizeclasses = computed(() => {
  switch (props.size) {
    case 'md':
      return props.label === '' ? 'h-11 text-base' : 'h-14 text-base'
    case 'sm':
      return props.label === '' ? 'h-8 w-fit text-xs' : 'h-12 w-fit text-xs'
  }
})
const labelSizeclasses = computed(() => {
  if (onFocus.value || props.modelValue || props.options.find((o) => o.value === props.modelValue)) {
    return 'top-2 translate-y-0 text-xs'
  } else {
    return 'top-1/2 -translate-y-1/2 text-sm'
  }
})
const showTooltip = computed(() => {
  return labelWidth.value > inputWidth.value - 44
})

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

function handleResize() {
  if (labelText.value && input.value) {
    labelWidth.value = labelText.value.offsetWidth
    inputWidth.value = input.value.offsetWidth
  }
}

function delayedResize() {
  setTimeout(() => {
    if (labelText.value && input.value) {
      labelWidth.value = labelText.value.offsetWidth
      inputWidth.value = input.value.offsetWidth
    }
  }, 310)
}

function valueOf(option: Option | OptionValue): OptionValue {
  return typeof option === 'object' && 'value' in option ? option.value : option
}
</script>
