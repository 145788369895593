<template>
  <NuxtIcon class="flex" :name="props.name" :filled="filled" />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    filled?: boolean
  }>(),
  {
    filled: false,
  },
)
</script>

<style scoped>
.nuxt-icon :deep(svg) {
  width: initial;
  height: initial;
  margin-bottom: initial;
  vertical-align: initial;
}
</style>
