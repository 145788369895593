<template>
  <nav id="breadcrumb" class="overflow-x-auto whitespace-nowrap text-xs sm:text-sm md:text-base" aria-label="breadcrumbs">
    <ul itemscope itemtype="https://schema.org/BreadcrumbList" class="flex items-center">
      <li v-if="lead" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem">
        <WebccLink itemprop="item" :to="lead.target" :title="lead.title" class="mr-1 text-txt sm:mr-2">
          <Text v-if="lead.tr" :prefix="trPrefix" :key-prop="lead.tr" component="span" />
          <span v-else>{{ lead.text }}</span>
        </WebccLink>
        <span v-if="lead.content" itemprop="name" :content="lead.content"></span>
        <meta itemprop="position" content="1" />
      </li>
      <li
        v-for="(item, i) in items"
        :key="i"
        class="flex items-center text-txt-weak"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <WebccIcon v-if="i > 0 || lead" filled name="site/chevron-right" class="h-6 w-6 sm:h-6 sm:w-6 text-txt-strong" />
        <WebccLink itemprop="item" :to="item.target" :title="item.title" class="mx-1 text-txt sm:mx-2">
          <Text v-if="item.tr" :prefix="trPrefix" :key-prop="item.tr" component="span" />
          <span v-else>{{ item.text }}</span>
        </WebccLink>
        <span v-if="item.content" itemprop="name" :content="item.content"></span>
        <meta itemprop="position" :content="(lead ? i + 2 : i + 1).toString()" />
      </li>
      <li v-if="trail" itemprop="itemListElement" itemscope itemtype="https://schema.org/ListItem" class="flex items-center text-txt-weak">
        <WebccIcon filled name="site/chevron-right" class="h-6 w-6 stroke-current sm:h-6 sm:w-6 text-txt-strong" />
        <WebccLink itemprop="item" :to="trail.target" :title="trail.title" class="text-txt">
          <Text v-if="trail.tr" :prefix="trPrefix" :key-prop="trail.tr" component="span" />
          <span v-else>{{ trail.text }}</span>
        </WebccLink>
        <span v-if="trail.content" itemprop="name" :content="trail.content"></span>
        <meta itemprop="position" :content="(1 + items.length + (lead ? 1 : 0)).toString()" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const trPrefix = getCurrentInstance()?.parent?.exposed?.trPrefix || ''
defineExpose({ trPrefix })
defineProps<{ lead?: BreadcrumbItem; items: BreadcrumbItem[]; trail?: BreadcrumbItem }>()
</script>
