<template>
  <div class="flex items-center gap-2 text-xs font-medium">
    <div v-if="discount > 5" class="rounded bg-hlt px-1 py-0.5 text-hlt-contrast">{{ discount.toFixed() }}&percnt;</div>
    <div v-if="topRated">
      <Text component="span" key-prop="toprated" class="rounded bg-suc px-1 py-0.5 uppercase text-suc-contrast" />
    </div>
    <div v-if="isNew">
      <Text component="span" key-prop="new" class="rounded bg-suc px-1 py-0.5 uppercase text-suc-contrast" />
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Tags.'
defineExpose({ trPrefix })

const props = withDefaults(
  defineProps<{
    tags: string[]
    discount?: number
  }>(),
  { discount: 0 },
)

const topRated = computed(() => {
  return props.tags.includes('top')
})
const isNew = computed(() => {
  return props.tags.includes('new')
})
</script>
