/*
 * Tracking-Spezifikation:
 * https://wiki.hotelplan.com/display/TSICHDDWEBCC/05+-+Object+Detail
 */

export default {
  // navigateToSection,
  galleryNavigation, // OK
  sidebarArrivalDate, // OK
  sidebarDateClick, // OK
  calendarDurationOpened, // OK
  calendarDurationSelect, // OK
  datepickerInteraction, // noch nicht alle Events OK
  // backButtonClick, wird derzeit nicht verwendet
  ratingHeartsClick, // OK, noch unklar, auf welchem Element das Tracking liegen soll
  addToWishlist, // OK
  deleteFromWishlist, // OK
  openRecommendationLayer, // OK
  recommendByMail, // OK
  paxSelected, // OK
  contactClick, // OK
  generalRequestClick, // OK
  offerRequestClick, // OK
  labelImpression, // OK
  labelClick, // OK
  attributeImpression, // OK
  descriptionImpression, // OK
  showDescriptionText,
  mapImpression, // OK
  calendarImpression, // OK
  costOverviewImpression, // OK
  reviewsImpression, // OK
  // moreReviewsImpression, wird derzeit nicht verwendet
  footerImpression, // OK
  tooltipAdditionalCosts, // OK
  mouseoverCostoverviewInfo, // OK
  houseAccommodationsClick, // OK

  // 2019
  generalRequestSubmitSuccess,
  generalRequestSubmitError,
  monthlyPricesSelect,
  monthlyPricesNext,
  monthlyPricesPrevious,

  // IHGWEBCC-129
  allImages,
  imageClick,
  galleryImageClick,
  galleryAddFavorite,
  galleryRemoveFavorite,
  galleryOpenRecommend,
  galleryClose,
  lightboxNextImage,
  lightboxPreviousImage,
  lightboxAddFavorite,
  lightboxRemoveFavorite,
  lightboxOpenRecommend,
  lightboxClose,
  closeLayers,

  // IHGWEBCC-257
  cancelConditions,

  // IHGWEBCC-407
  externalUserSurvey,
}

/*
 *   1 - 9 On Page Navigation
 */
/*
function navigateToSection(section) {
  // Mapping laut Tracking-Spezifikation
  const labels = {
    description: 'Object Description',
    map: 'Map',
    datepicker: 'Calender Bottom',
    costoverview: 'Cost-Overview',
    reviews: 'Reviews',
  }

  return {
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'On Page Navigation',
    eventLabel: labels[section],
    eventNonInteraction: false,
  }
}
*/
/*   Picture Gallery
 *   10: main navigation
 *   11: thumbnail clickc
 *   12: thumbnail navigationslideChanged: (s) => {},
 */

function galleryNavigation() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Picture Galery',
    eventLabel: 'Arrow',
    eventNonInteraction: false,
  })
}

/*
 *   Sidebar: 13 / 14 / 15?
 */
function sidebarArrivalDate(date?: Date) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Date',
    eventLabel: date ? date.getFullYear() + '/' + ('00' + date.getMonth() + 1).slice(-2) : 'no date selected yet',
    eventNonInteraction: false,
  })
}

function sidebarDateClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Calendar Top',
    eventLabel: 'Date clicked',
    eventNonInteraction: false,
  })
}

/*
 *   Datepicker:  16, 17, 18
 */
function calendarDurationOpened() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Calendar Bottom',
    eventLabel: 'Duration Opened',
    eventNonInteraction: false,
  })
}
function calendarDurationSelect(duration: number) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: duration ? 'Duration of stay' : 'Calendar Top',
    eventLabel: duration || 'Duration Chosen',
    eventNonInteraction: false,
  })
}

/* wishlist:
 * - add: 23
 * - delete: 24
 */
function addToWishlist(accommodationCode: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Add to Wishlist',
    eventLabel: accommodationCode,
    eventNonInteraction: false,
  })
}

function galleryAddFavorite() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Add to Wishlist',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}
function galleryRemoveFavorite() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Delete from Wishlist',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}

/*
 *   Datepicker:  47, 48, 49 50
 */
function datepickerInteraction(eventLabel: 'dateSelected' | 'prevMonth' | 'nextMonth' | 'monthSelected') {
  const labels = {
    dateSelected: 'Date Chosen',
    prevMonth: 'Left scroll',
    nextMonth: 'Right scroll',
    monthSelected: 'Month selector used',
  }

  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Calendar Bottom',
    eventLabel: labels[eventLabel],
    eventNonInteraction: false,
  })
}

/*
 *   Back Button (vor Bread Crumb):  20
 */
// function backButtonClick() {
//   return {
//     event: 'event',
//     eventCategory: 'Object Details',
//     eventAction: 'BackButton',
//     eventLabel: 'not set',
//     eventNonInteraction: false,
//   }
// }

/* Ratings: 21 */
function ratingHeartsClick(rating: number) {
  if (rating > 0) {
    useTracking().send({
      event: 'event',
      eventCategory: 'Object Details',
      eventAction: 'Ratings Top',
      eventLabel: 'not set',
      eventNonInteraction: false,
    })
  }
}

function deleteFromWishlist(accommodationCode: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Delete from Wishlist',
    eventLabel: accommodationCode,
    eventNonInteraction: false,
  })
}

/* SocialShare:
 * - open Layer: 25
 * - mail click: 26
 */
function openRecommendationLayer() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Recommend Object',
    eventLabel: 'Layer opened',
    eventNonInteraction: false,
  })
}
function recommendByMail() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Recommend Object',
    eventLabel: 'mail',
    eventNonInteraction: false,
  })
}

/* PAX: 29 */
function paxSelected(n: number) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'PAX',
    eventLabel: n,
    eventNonInteraction: false,
  })
}

/* Contact:
 * - phone click: 30
 * - mail click: 31
 *  - link to general request: 32
 *  - link to offer request: 34
 */
function contactClick(contactType: 'Email' | 'Phone') {
  // Mapping laut Tracking-Spezifikation
  const contactTypes = {
    Email: 'Mail to info@',
    Phone: 'Phone Booking Module',
  }

  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Outbound',
    eventLabel: contactTypes[contactType],
    eventNonInteraction: false,
  })
}

function generalRequestClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'General Request',
    eventLabel: 'Layer opened',
    eventNonInteraction: false,
  })
}

function offerRequestClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Holiday Offer',
    eventLabel: 'Layer opened',
    eventNonInteraction: false,
  })
}

/*
 * Impressions:
 * - labels: 36
 * - attributes: 38
 * - description: 39
 * - map: 40
 * - datepicker: 41
 * - costoverview: 42
 *  - reviews: 44
 *  - footer: 46
 */

function labelImpression(params: { params: string; entry: { intersectionRatio: number } }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Label',
    eventLabel: 'displayed ' + params.params + ' at ScrollDepth ' + params.entry.intersectionRatio,
    eventNonInteraction: true,
  })
}
function labelClick(label: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Label',
    eventLabel: 'clicked ' + label,
    eventNonInteraction: true,
  })
}
function attributeImpression(entry: { intersectionRatio: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth ' + entry.intersectionRatio,
    eventLabel: 'Attributes Viewed',
    eventNonInteraction: true,
  })
}
function descriptionImpression(entry: { intersectionRatio: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth ' + entry.intersectionRatio,
    eventLabel: 'Description viewed',
    eventNonInteraction: true,
  })
}
function mapImpression() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth',
    eventLabel: 'Map viewed',
    eventNonInteraction: true,
  })
}
function calendarImpression(entry: { intersectionRatio: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth ' + entry.intersectionRatio,
    eventLabel: 'Calendar Bottom viewed',
    eventNonInteraction: true,
  })
}
function costOverviewImpression(entry: { intersectionRatio: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth ' + entry.intersectionRatio,
    eventLabel: 'Cost-Overview viewed',
    eventNonInteraction: true,
  })
}
function reviewsImpression(entry: { intersectionRatio: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth ' + entry.intersectionRatio,
    eventLabel: 'Ratings viewed',
    eventNonInteraction: true,
  })
}
// function moreReviewsImpression() {
//   // aktuell nicht verwendet
//   useTracking().send( {
//     event: 'event',
//     eventCategory: 'Object Details',
//     eventAction: 'Scroll Depth',
//     eventLabel: 'More Ratings viewed',
//     eventNonInteraction: true,
//   })
// }

function footerImpression(entry: { intersectionRatio: number }) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Scroll Depth ' + entry.intersectionRatio,
    eventLabel: 'Footer viewed',
    eventNonInteraction: true,
  })
}

// Costoverview: 51 - 56 unklar

// 51: mouseover sidebar nebenkosten tooltip
function tooltipAdditionalCosts() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Additional Cost',
    eventLabel: 'Layer Booking Module',
    eventNonInteraction: false,
  })
}

// 54 - bzw. 56?
function mouseoverCostoverviewInfo(code: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Additional Cost',
    eventLabel: 'Layer ' + code + ' viewed',
    eventNonInteraction: false,
  })
}

/* 59: Alle Wohungen eines Hauses anzeigen */
function houseAccommodationsClick(accommodationCode: string) {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Objects in House',
    eventLabel: 'Clicked ' + accommodationCode,
    eventNonInteraction: false,
  })
}

/*
 * Neue Spezifikation aus 2019:
 * https://wiki.hotelplan.com/display/TSICHDDWEBCC/Refactoring+Tracking+with+Vue+-+2019
 * Status unklar
 */

// Submitting a general request, successful or not
function generalRequestSubmitSuccess() {
  useTracking().send({
    event: 'General Request',
    eventCategory: 'Object Details',
    eventAction: 'General Request',
    eventLabel: 'Form sent',
    eventNonInteraction: false,
  })
}

// Successfully submitting a general request
function generalRequestSubmitError() {
  useTracking().send({
    event: 'General Request',
    eventCategory: 'Object Details',
    eventAction: 'General Request',
    eventLabel: 'Form sent error',
    eventNonInteraction: false,
  })
}

function monthlyPricesSelect(month: { formatted: string }) {
  const monthName = new Date(month.formatted).toLocaleString('en-us', { month: 'long' })
  useTracking().send({
    event: 'Pricetable',
    eventCategory: 'Object Details',
    eventAction: 'Pricetable',
    eventLabel: 'Month selected ' + monthName,
    eventNonInteraction: false,
  })
}
function monthlyPricesNext() {
  useTracking().send({
    event: 'Pricetable',
    eventCategory: 'Object Details',
    eventAction: 'Pricetable',
    eventLabel: 'Next month',
    eventNonInteraction: false,
  })
}
function monthlyPricesPrevious() {
  useTracking().send({
    event: 'Pricetable',
    eventCategory: 'Object Details',
    eventAction: 'Pricetable',
    eventLabel: 'Previous month',
    eventNonInteraction: false,
  })
}

function allImages() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Show picture page layer via button',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}
function imageClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Show picture page layer',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}
function galleryImageClick() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Show Page layer slider',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}

function galleryOpenRecommend() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Open recommend object',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}
function galleryClose() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Close picture page layer',
    eventLabel: 'Picture page layer',
    eventNonInteraction: false,
  })
}

function lightboxNextImage() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Next image',
    eventLabel: 'Page layer slider',
    eventNonInteraction: false,
  })
}
function lightboxPreviousImage() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Previous image',
    eventLabel: 'Page layer slider',
    eventNonInteraction: false,
  })
}
function lightboxAddFavorite() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Add to Wishlist',
    eventLabel: 'Page layer slider',
    eventNonInteraction: false,
  })
}
function lightboxRemoveFavorite() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Delete from Wishlist',
    eventLabel: 'Page layer slider',
    eventNonInteraction: false,
  })
}
function lightboxOpenRecommend() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Open recommend object',
    eventLabel: 'Page layer slider',
    eventNonInteraction: false,
  })
}
function lightboxClose() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Close page layer slider',
    eventLabel: 'Page layer slider',
    eventNonInteraction: false,
  })
}
function closeLayers() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Close layer',
    eventLabel: 'Page layer',
    eventNonInteraction: false,
  })
}

function cancelConditions() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Cancellation policy viewed',
    eventLabel: 'Object details section',
    eventNonInteraction: true,
  })
}

function showDescriptionText() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: 'Show full text',
    eventLabel: 'Description viewed',
    eventNonInteraction: false,
  })
}

function externalUserSurvey(status: Exclude<UserSurveyStatus, 'scheduled'>) {
  const { action, nonInteraction } = {
    visible: { action: 'Show modal', nonInteraction: true },
    opened: { action: 'Open external survey' },
    postponed: { action: 'Close modal temporarily' },
    refused: { action: 'Close modal permanently' },
  }[status]

  useTracking().send({
    event: 'event',
    eventCategory: 'Object Details',
    eventAction: action,
    eventLabel: 'Modal external user survey',
    eventNonInteraction: nonInteraction ?? false,
  })
}
