<template>
  <main>
    <div class="container-fluid-lg">
      <Blocks :blocks="blocks" />
    </div>
    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const blocks = computed(() => useConfdata().body as Block[])

onMounted(() => {
  window.scrollTo({ top: 0 })
})
</script>
