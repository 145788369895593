<template>
  <span
    class="mr-1 flex w-fit min-w-[4rem] items-center justify-between rounded-full bg-thm px-2 py-1 text-xs font-semibold uppercase text-thm-contrast last:mr-0 md:h-8"
  >
    <span>
      <Text :key-prop="name || filter" /><template v-if="value">: {{ value }}</template>
    </span>
    <button @click.stop="remove"><WebccIcon name="site/x" class="ml-1 h-4 w-4" /></button>
  </span>
</template>

<script setup lang="ts">
defineProps<{
  filter: string
  name: string
  value?: string | boolean | number
}>()
const emit = defineEmits<{ (e: 'remove'): void }>()
defineExpose({ trPrefix: 'www.components.views.search.filter.' })

function remove() {
  emit('remove')
}
</script>
