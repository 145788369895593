export const useStorageReady = () => {
  // Check if the storage is ready via nuxtApp
  if (import.meta.client) {
    try {
      if (window.localStorage) {
        return true
      }
      return false
    } catch (e) {
      // console.log('localStorage not available: ', e)
      return false
    }
  } else {
    return false
  }
}
