<template>
  <WebccModal v-if="show" @close="$emit('close')">
    <WebccLoaderOverlay v-if="loading" :scrolling="{ onclose: false, onopen: false }" />
    <div class="max-w-2xl rounded bg-bgr p-2" @click="useToggler().toggle('datepicker', { state: false })">
      <div class="flex items-center justify-end bg-bgr py-2">
        <div class="flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-bgr-200" @click="$emit('close')">
          <WebccIcon class="h-6 w-6" name="site/x" />
        </div>
      </div>
      <FavSuccess v-if="success" />
      <WebccForm v-if="!success" ref="formRef" v-model="formdata" class="flex max-h-[80vh] flex-col gap-4 overflow-y-auto px-4" @submit="sendCatalog">
        <Text key-prop="title" class="text-xl font-semibold text-thm" />
        <Text key-prop="fillForm" />
        <Text component="span" prefix="plugins.form.Formfield." key-prop="requiredInfo" class="mt-2 text-txt-weak" />
        <WebccFormfield
          component="WebccSelect"
          label="salutation"
          name="salutation"
          required
          class="max-w-xs"
          type="radio"
          :options="[{ F: translatep('ms') }, { M: translatep('mr') }]"
          @input="error = false"
        />
        <WebccFormfield component="WebccInput" label="firstname" name="firstname" required type="text" @input="error = false" />
        <WebccFormfield component="WebccInput" label="lastname" name="lastname" required type="text" @input="error = false" />
        <WebccFormfield
          component="WebccInput"
          label="email"
          name="email"
          required
          type="email"
          @input="
            () => {
              error = false
              emailError = false
            }
          "
        />
        <WebccFormfield component="WebccSelect" :options="paxOptions" label="pax" name="pax" required type="select" @input="error = false" />
        <WebccFormfield
          component="WebccDateRangepicker"
          :label="translatep('dates')"
          name="range"
          required
          inline
          type="daterange"
          :locale="useL10N().locale"
          @click.stop
          @input="error = false"
        />
        <WebccCaptcha v-if="!useRuntimeConfig().public.recaptcha.disabled" v-model="captcha" :locale="useL10N().locale" />
        <WebccFormfield
          v-if="useConfdata().signupEnabled"
          component="WebccCheckbox"
          label="newsletter"
          name="newsletter"
          type="checkbox"
          @input="error = false"
        />
        <hr />

        <TextHTML path="gdprNewsletter" tag="aside" class="text-sm" :prefix="trPrefix">
          <template #dse>
            <WebccLink anchor target="_blank" class="text-thm" :to="privacyPolicy">
              <Text :prefix="trPrefix" key-prop="privacyPolicy" />
            </WebccLink>
          </template>
        </TextHTML>

        <hr />
        <TextHTML path="gdprRegular" tag="aside" class="text-sm" :prefix="trPrefix">
          <template #dse>
            <WebccLink anchor target="_blank" class="text-thm" :to="privacyPolicy">
              <Text :prefix="trPrefix" key-prop="privacyPolicy" />
            </WebccLink>
          </template>
        </TextHTML>
        <div v-if="emailError" class="mt-4 border">
          <Text component="div" class="p-6 text-err" key-prop="emailError" />
        </div>
        <FavError v-if="error" />
        <WebccButton variant="cta" :disabled="!captcha">
          <Text :prefix="trPrefix" key-prop="send" />
        </WebccButton>
      </WebccForm>
    </div>
  </WebccModal>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.favorites.Actions.catalog.'
defineExpose({ trPrefix })

const props = defineProps<{
  show?: boolean
  accoms: ResultDocument[]
  language: string
  salesOfficeId: string
  privacyPolicy: string
}>()

defineEmits<{ (e: 'close'): void }>()

const formRef = ref<HTMLFormElement>()

const captcha = ref('')
const loading = ref(false)
const success = ref(false)
const error = ref(false)
const emailError = ref(false)

const formdata = ref({
  salutation: '',
  firstname: '',
  lastname: '',
  email: '',
  pax: 1,
  range: {} as DateRange,
  newsletter: false,
})

const paxOptions = computed(() => [...Array(48)].map((_, index) => index + 1))

async function sendCatalog() {
  loading.value = true
  try {
    // validation API
    const emailValidationResult = await useValidation().email(formdata.value.email)
    if (emailValidationResult!.score < 3) {
      loading.value = false
      emailError.value = true
      return
    }
  } catch (error) {
    console.error(error)
    emailError.value = true
    loading.value = false
    return
  }

  const response = await useBookmarks().createPersonalCatalog({
    ...formdata.value,
    checkin: formdata.value.range.start && toDate(formdata.value.range.start),
    checkout: formdata.value.range.end && toDate(formdata.value.range.end),
    captcha: captcha.value,
    accom: props.accoms.slice(0, 100).map(({ code }) => code),
  })

  loading.value = false
  success.value = !response.error.value
  error.value = !!response.error.value

  if (error.value) {
    nextTick(() => {
      formRef.value!.form.scrollTop = formRef.value!.form.scrollHeight
    })
  }
}
</script>
