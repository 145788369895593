<template>
  <div>
    <GoogleMaps ref="mapRef" class="h-full w-full" :locale="locale" :domain="domain" @loaded="init">
      <MapSearchInfoWindow v-show="infoWindowIsVisible" ref="infoWindowRef" @close="infoWindowIsVisible = false" />
    </GoogleMaps>
  </div>
</template>

<script setup lang="ts">
import type MapSearchInfoWindow from './MapSearchInfoWindow.vue'
import type GoogleMaps from '~/components/common/functional/GoogleMaps.vue'

const emit = defineEmits<{
  updateInfo: [MapUpdates]
  updateDest: [MapDestination]
}>()

const autoMaxZoom = 15
const deClusterLevel = 12
const mapBoundsPadding = 40

const mapRef = ref<InstanceType<typeof GoogleMaps>>()
const infoWindowRef = ref<InstanceType<typeof MapSearchInfoWindow>>()

const bounds = ref(getBoundsEmpty())
const zoom = ref(calcBoundsZoomLevel(bounds.value))
const infoWindow = ref<MapInfoWindow>()
const infoWindowIsVisible = ref(false)
const map = ref<google.maps.Map>()
let markers: MapMarker[] = reactive([])
const preventZoomChangeEvent = ref(false)
const searchresult = ref<Result>()
const searchresultInv = ref<Result>()

const { viewport, filters } = storeToRefs(useSearch())
const { language, locale } = storeToRefs(useL10N())
const { domain, salesoffice, currency } = storeToRefs(useConfdata())

/**
 * Need to set the shape variables to undefined,
 * otherwise Google Maps will not hide them despite setMap(null)
 * If make it reactive it will also not hide overlays.
 */
const debugInfo: {
  borderYellow?: google.maps.Rectangle
  borderRed?: google.maps.Rectangle
  circle?: google.maps.Circle
} = {}

const mapDim = computed(() => {
  if (!mapRef.value?.wrapper) return undefined

  return {
    height: mapRef.value.wrapper.offsetHeight - 2 * mapBoundsPadding,
    width: mapRef.value.wrapper.offsetWidth - 2 * mapBoundsPadding,
  }
})

watch(viewport, init, { deep: true })
watch(useSettings(), renderDebugInfo, { deep: true })
watch(bounds, renderDebugBorders)

async function init() {
  infoWindowIsVisible.value = false

  if (!map.value && mapRef.value?.wrapper) {
    map.value = new window.google.maps.Map(mapRef.value.wrapper, {
      center: getCoordEmpty(),
      zoom: zoom.value,
      gestureHandling: 'greedy',
      scrollwheel: false,
      clickableIcons: false /* map icons are not clickable (POI, ...) */,
    })

    map.value.addListener('zoom_changed', async () => {
      if (preventZoomChangeEvent.value) return
      await triggerMapBoundsSearch()
    })
    map.value.addListener('click', () => {
      infoWindowIsVisible.value = false
    })

    const { MapOverlay } = await import('./map/google-maps')
    infoWindow.value = new MapOverlay(infoWindowRef.value?.$el)
  }

  if (!map.value) return

  await searchForBounds(viewport.value && isViewportValid(viewport.value) ? getBoundsFromViewport(viewport.value) : getBoundsDefault())

  renderDebugInfo()
}

async function searchForBounds(newBounds: Bounds, newZoom?: number) {
  bounds.value = newBounds
  zoom.value = newZoom ?? calcBoundsZoomLevel(newBounds, mapDim.value)

  const hasFiltersByGeo = filters.value?.country || filters.value?.region || filters.value?.place
  const params = {
    ...(filters.value ?? {}),
    ...bounds.value,
    zoom: zoom.value + useSettings().mapAdditionalBucketZoom,
  }

  const [result, resultInv] = await Promise.all([
    search(params),
    hasFiltersByGeo ? search(inverseGeoParams(params)) : Promise.resolve(ref()), //
  ])

  searchresult.value = result.value ?? undefined
  searchresultInv.value = resultInv.value

  if (searchresult.value?.buckets?.length) {
    bounds.value = getBoundsFromViewport(searchresult.value?.viewport ?? {})
  }

  if (!newZoom) fitBounds()
  renderMarkers()
  clearProposal()
  if (!newZoom) geoAnalysis()
}

async function search(params: Params) {
  const { data } = await useSearchApi().search({ ...params, page: undefined })

  if (data.value) {
    data.value.buckets = data.value?._aggregations?.karte?.buckets ?? []
    data.value.docs = data.value?.docs ?? []
  }

  return data
}

function inverseGeoParams(params: Params) {
  const result = { ...params }

  if (result.place) {
    result.place = '-' + result.place
    result.region = undefined
    result.country = undefined
  } else if (result.region) {
    result.region = '-' + result.region
    result.country = undefined
  } else if (result.country) {
    result.country = '-' + result.country
  }

  return result
}

/**
 * passt die Karte an übergebene bounds an für search mit map bounds (Kartenausschnitt)
 */
function fitBounds() {
  preventZoomChangeEvent.value = true
  map.value?.setOptions({ maxZoom: autoMaxZoom })

  const { s, w, n, e } = bounds.value
  const sw = new window.google.maps.LatLng(s, w)
  const ne = new window.google.maps.LatLng(n, e)
  map.value?.fitBounds(new window.google.maps.LatLngBounds(sw, ne), {
    top: mapBoundsPadding,
    bottom: mapBoundsPadding,
    left: mapBoundsPadding,
    right: mapBoundsPadding,
  })

  map.value?.setOptions({ maxZoom: undefined })
  preventZoomChangeEvent.value = false
}

/**
 * items = docs / buckets to render
 */
function renderMarkers() {
  const items = prepareItems(searchresult.value, true)
  const itemsInv = prepareItems(searchresultInv.value, false)

  markers.forEach((marker) => marker.setMap(null)) // remove the old markers
  markers = [...itemsInv, ...items].map(createMarker)
  markers.forEach((marker) => marker.setMap(map.value ?? null))

  emit('updateInfo', { zoomMap: map.value?.getZoom(), autoMaxZoom, deClusterLevel, itemsLength: markers.length })
}

function prepareItems(res: Result | null | undefined, inbound: boolean): MapItem[] {
  if (!res?.docs) return []

  const docs = res.docs
  let buckets = res.buckets ?? []

  // Switch from buckets to docs wenn Anzahl von Accommodations in all buckets <= 20
  // Grund: Bei manchen Residenzen haben die Einzelwohnungen abweichende Koordinaten und werden auch bei hohen Zoomstufen nicht aufgelöst
  if (
    buckets.reduce((sum, arr) => sum + arr.count, 0) <= 20 &&
    useSettings().mapDeClustering === 'accosum' &&
    map.value?.getZoom() &&
    map.value.getZoom()! > deClusterLevel
  ) {
    // Residenzen in Einzel-Docs müssen wieder zusammengefasst werden, sonst werden sie überdeckt
    const distinctCoords = docs.map((doc) => doc.coords.lat + doc.coords.lon)
    buckets = docs.map((doc) => {
      return { count: distinctCoords.filter((c) => c === doc.coords.lat + doc.coords.lon).length, coords: doc.coords, key: '', cardinality: 1 }
    })
    buckets = Object.values(buckets.reduce((acc, obj) => ({ ...acc, [(obj.coords?.lat || NaN) + (obj.coords?.lon || NaN)]: obj }), {}))
  }

  return buckets.map(({ key, bounds, coords, count, cardinality }) => ({
    country: filters.value?.country,
    region: filters.value?.region,
    place: filters.value?.place,
    lat: Math.round(coords?.lat || NaN),
    lng: Math.round(coords?.lon || NaN),
    count: count || 1,
    cardinality: cardinality || 1,
    key: key || '',
    bounds,
    inbound,
    zoom: zoom.value,
    avgLat: coords?.lat,
    avgLng: coords?.lon,
  }))
}

function createMarker(item: MapItem) {
  const options: google.maps.MarkerOptions = {
    map: map.value,
    position: item.avgLat && item.avgLng ? { lat: item.avgLat, lng: item.avgLng } : undefined,
    // title: `${country}/${region}/${place} (${precision}: ${lat}-${lng} => ${count}`,
    label: {
      text: item.count.toString(),
      color: 'white',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  }

  const path = item.inbound ? '/map/inbound' : '/map/outbound'
  if (item.cardinality > 1) {
    options.icon = { url: `${path}/cluster2.png` }
  } else if (item.count > 1) {
    options.icon = { url: `${path}/pin-multiple.png`, labelOrigin: new window.google.maps.Point(30, 11) }
  } else {
    options.icon = { url: `${path}/pin.png` }
    options.label = undefined // delete option.label avoided consciously due to performance reasons
  }

  const marker: MapMarker = new google.maps.Marker(options)
  marker.key = item.key || ''
  marker.accommodationCount = item.count
  marker.coordsCardinality = item.cardinality
  marker.bounds = item.bounds
  marker.inbound = item.inbound
  marker.addListener('click', async () => await handleMarkerClick(marker))

  return marker
}

async function handleMarkerClick(marker: MapMarker) {
  if (marker.accommodationCount && marker.coordsCardinality && marker.accommodationCount > 1 && marker.coordsCardinality > 1) {
    zoomAndPanMapTo(marker)
  } else {
    await showAccommodation(marker)
  }
}

function zoomAndPanMapTo(marker: MapMarker) {
  if (marker.key) {
    triggerViewportSearch(marker)
  } else if (map.value?.getZoom()) {
    const zoom = Math.min(map.value.getZoom()! + 1, marker.coordsCardinality === 1 ? autoMaxZoom : Number.MAX_VALUE)
    map.value?.setZoom(zoom)
    map.value?.panTo(marker.getPosition() ?? getCoordEmpty())
    triggerMapBoundsSearch()
  }
}

async function triggerMapBoundsSearch() {
  await searchForBounds(getMapBounds(map.value!), map.value?.getZoom() ?? 0)
}

async function triggerViewportSearch(marker: MapMarker) {
  await searchForBounds(getBoundsFromCoords(marker.bounds ?? {}))
}

async function showAccommodation(marker: MapMarker) {
  if (infoWindowRef.value && map.value) {
    infoWindow.value?.close()
    infoWindow.value?.open(map.value, marker.getPosition() ?? getCoordEmpty(), true)
    infoWindow.value?.setLoading(true)
    infoWindowRef.value.accommodations = []
    infoWindowIsVisible.value = true
  }

  const data = await search({
    lat: marker.getPosition()?.lat(),
    lon: marker.getPosition()?.lng(),
    pageSize: marker.accommodationCount,
    language: language.value,
    currency: currency.value,
    salesoffice: salesoffice.value?.code,
    checkin: filters.value.checkin,
    checkout: filters.value.checkout,
    duration: filters.value.duration,
    pax: filters.value.pax,
  })

  if (infoWindowRef.value && data.value?.docs?.length) {
    infoWindowRef.value.accommodations = data.value?.docs
    infoWindow.value?.setLoading(false)
  }
}

/*
 * Functions for retrieving the map-zoom-level for specific bounds - called in init-map before rendering the map for the first time
 */

function clearProposal() {
  emit('updateDest', { country: { name: '', code: '' }, region: { name: '', code: '' }, place: { name: '', code: '' } })
}

async function geoAnalysis() {
  const destProposal = {
    country: { name: '', code: '' },
    region: { name: '', code: '' },
    place: { name: '', code: '' },
  }

  const geo = searchresult.value?._aggregations?.geo.buckets || []
  let geoProposal = searchresultInv.value?._aggregations?.geo.buckets || []
  let searchresultProp = searchresultInv.value

  // Radius-Suche
  const center = map.value?.getCenter()
  // TODO: Feintuning
  // const radius = this.filters.place !== undefined ? 4 : this.filters.region !== undefined ? 40 : 200 // alternativ: 100 - 4 * map.value?.getZoom()
  const radius =
    Math.round(((200 * Math.exp(-0.33 * (map.value?.getZoom() || 1)) * (23 - (map.value?.getZoom() || 1))) / (map.value?.getZoom() || 1)) * 1000) / 1000
  console.log('radius', radius)

  let geoRad: Bucket[] = []
  try {
    const { data, error } = await useSearchApi().search({ lat: center?.lat(), lon: center?.lng(), radius: `${radius}km` })
    geoRad = data.value?._aggregations?.geo.buckets || []

    if (error.value) {
      throw error.value
    }

    // Wenn die Radius-Suche etwas findet, dann nehmen wir dieses Ergebnis, ansonsten die Ergebnisse der Inv-Suche
    if (geoRad.length) {
      geoProposal = geoRad
      searchresultProp = data.value ?? undefined
      renderDebugCircle(center, radius)
    }
  } catch (error) {
    console.error(error)
  }

  if (geoProposal.length) {
    // geoProposal-Analysis

    if (filters.value?.place) {
      const placeRegex = '^[A-Z]{2}[0-9]{5}$'

      const geoPlace = geo
        .filter(({ key }) => key.match(placeRegex))
        .reduce((prev, curr) => (prev.count >= curr.count ? prev : curr), { key: '', count: 0, cardinality: 0 })

      const geoProposalPlace = geoProposal
        .filter(({ key }) => key.match(placeRegex))
        .reduce((prev, curr) => (prev.count >= curr.count ? prev : curr), { key: '', count: 0, cardinality: 0 })

      if (geoProposalPlace.key !== geoPlace.key) {
        const place = searchresultProp?.facets?.places?.find((c) => c.code === geoProposalPlace.key)
        if (place) {
          const placeRegion = place?.parentRegionCode || ''
          const placeCountry = place?.code.substring(0, 2)
          destProposal.place.name = place?.name || ''
          destProposal.place.code = place?.code || ''
          destProposal.region.name = searchresultProp?.facets?.regions?.find((c) => c.code === placeRegion)?.name || ''
          destProposal.region.code = placeRegion
          destProposal.country.name = searchresultProp?.facets?.countries?.find((c) => c.code === placeCountry)?.name || ''
          destProposal.country.code = placeCountry || ''
        }
      }
    } else if (filters.value?.region) {
      const regionRegex = '^[A-Z]{2}[0-9]{2}$'
      const geoRegion = geo
        .filter(({ key }) => key.match(regionRegex))
        .reduce((prev, curr) => (prev.count >= curr.count ? prev : curr), { key: '', count: 0, cardinality: 0 })

      const geoProposalRegion = geoProposal
        .filter(({ key }) => key.match(regionRegex))
        .reduce((prev, curr) => (prev.count >= curr.count ? prev : curr), { key: '', count: 0, cardinality: 0 })

      if (geoProposalRegion.key !== geoRegion.key) {
        const region = searchresultProp?.facets?.regions?.find((c) => c.code === geoProposalRegion.key)
        if (region) {
          const regionCountry = region?.code.substring(0, 2)
          destProposal.region.name = searchresultProp?.facets?.regions?.find((c) => c.code === geoProposalRegion.key)?.name || ''
          destProposal.region.code = region?.code || ''
          destProposal.country.name = searchresultProp?.facets?.countries?.find((c) => c.code === regionCountry)?.name || ''
          destProposal.country.code = regionCountry || ''
        }
      }
    } else if (filters.value?.country) {
      const countryRegex = '^[A-Z]{2}$'

      const geoCountry = geo
        .filter(({ key }) => key.match(countryRegex))
        .reduce((prev, curr) => (prev.count >= curr.count ? prev : curr), { key: '', count: 0, cardinality: 0 })

      const geoProposalCountry = geoProposal
        .filter(({ key }) => key.match(countryRegex))
        .reduce((prev, curr) => (prev.count >= curr.count ? prev : curr), { key: '', count: 0, cardinality: 0 })

      if (geoProposalCountry.key !== geoCountry.key) {
        destProposal.country.name = searchresultProp?.facets?.countries?.find((c) => c.code === geoProposalCountry.key)?.name || ''
        destProposal.country.code = geoProposalCountry.key
      }
    }

    emit('updateDest', destProposal)
  }
}

async function renderDebugInfo() {
  if (useSettings().mapShowBounds) {
    const { CoordMapType } = await import('./map/google-maps')
    const coordMapType = new CoordMapType(new window.google.maps.Size(256, 256))
    map.value?.overlayMapTypes.insertAt(0, coordMapType)
    hideDebugBorders()
    showDebugBorders(bounds.value, bounds.value)
  } else {
    map.value?.overlayMapTypes.removeAt(0)
    hideDebugBorders()
    hideDebugCircle()
  }
}

function renderDebugBorders(newBounds: Bounds, oldBounds: Bounds) {
  if (!useSettings().mapShowBounds) return

  hideDebugBorders()
  showDebugBorders(newBounds, oldBounds)
}

function renderDebugCircle(center: google.maps.LatLng | undefined, radius: number) {
  if (!useSettings().mapShowBounds) return

  hideDebugCircle()
  showDebugCircle(center, radius)
}

function showDebugBorders(newBounds: Bounds, oldBounds: Bounds) {
  debugInfo.borderYellow = new google.maps.Rectangle({
    bounds: new google.maps.LatLngBounds(new google.maps.LatLng(oldBounds.s, oldBounds.w), new google.maps.LatLng(oldBounds.n, oldBounds.e)),
    strokeColor: '#FFFF00',
    strokeWeight: 12,
    strokeOpacity: 0.8,
    clickable: false,
    map: map.value,
  })
  debugInfo.borderRed = new google.maps.Rectangle({
    bounds: new google.maps.LatLngBounds(new google.maps.LatLng(newBounds.s, newBounds.w), new google.maps.LatLng(newBounds.n, newBounds.e)),
    strokeColor: '#FF0000',
    strokeWeight: 2,
    strokeOpacity: 0.8,
    clickable: false,
    map: map.value,
  })
}

function hideDebugBorders() {
  debugInfo.borderYellow?.setMap(null)
  debugInfo.borderYellow = undefined
  debugInfo.borderRed?.setMap(null)
  debugInfo.borderRed = undefined
}

function showDebugCircle(center: google.maps.LatLng | undefined, radius: number) {
  debugInfo.circle = new google.maps.Circle({
    center,
    radius: radius * 1000,
    strokeColor: '#00FF00',
    strokeOpacity: 1.0,
    fillOpacity: 0,
    strokeWeight: 4,
    clickable: false,
    map: map.value,
  })
}

function hideDebugCircle() {
  debugInfo.circle?.setMap(null)
  debugInfo.circle = undefined
}

function getCoordEmpty() {
  return { lat: 0, lng: 0 }
}

function getMapBounds(map: google.maps.Map) {
  const ne = map.getBounds()?.getNorthEast()
  const sw = map.getBounds()?.getSouthWest()

  return {
    s: sw?.lat() ?? 0,
    w: sw?.lng() ?? 0,
    n: ne?.lat() ?? 0,
    e: ne?.lng() ?? 0,
  }
}
</script>
