<template>
  <div>
    <div class="relative">
      <div
        ref="slotContent"
        class="h-full overflow-hidden transition-height duration-700 ease-in"
        :style="contentHeight !== 0 ? { maxHeight: contentHeight + 'px' } : ''"
      >
        <slot />
      </div>
      <!-- Fade in div -->
      <div
        class="from-0 absolute inset-0 bg-gradient-to-t from-bgr via-bgr via-15% to-transparent to-40% transition-opacity duration-300"
        :class="fadeClasses"
      ></div>
    </div>
    <div class="mt-2 flex-row justify-center" :class="btnClasses">
      <WebccButton outline variant="theme" @click="expand">
        <Text key-prop="showMore" />
      </WebccButton>
    </div>
  </div>
</template>

<script setup lang="ts">
defineExpose({ trPrefix: 'www.components.ui.ExpandableContent.' })
const minContentHeight = 384
const expanded = ref(false)

const emit = defineEmits<{ (e: 'expand'): void }>()

const slotContent: Ref<null | HTMLDivElement> = ref(null)

const maxContentHeight = computed(() => {
  return slotContent.value?.scrollHeight
})

const fadeClasses = computed(() => {
  if (expanded.value) {
    return 'hidden'
  }

  if (maxContentHeight.value && maxContentHeight.value <= minContentHeight) {
    return 'hidden'
  }

  return 'flex'
})

const btnClasses = computed(() => {
  if (expanded.value) {
    return 'hidden'
  }

  if (maxContentHeight.value && maxContentHeight.value <= minContentHeight) {
    return 'hidden'
  }

  return 'flex'
})

const contentHeight = computed(() => {
  if (expanded.value) {
    return slotContent.value?.scrollHeight
  }

  return minContentHeight
})

function expand() {
  emit('expand')
  expanded.value = true
}
</script>
