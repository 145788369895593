<template>
  <main id="page-search" class="bg-bgr-50 shadow-inner" :data-filters-count="Object.keys(filters).length">
    <SearchHeader />

    <div v-if="useParams().iframe" class="container-fluid-lg flex flex-row items-center justify-between">
      <SearchBreadcrumb :geo="geo" />
      <BookmarkLink />
    </div>

    <!-- <SearchArea v-if="useAB().isActive('newSearchUI')" :currency="useConfdata().currency" :domain="useConfdata().domain" :locale="useL10N().locale" /> -->

    <SearchAreaV5 class="z-1" @interaction="onInteraction($event)">
      <DetailsTrustPilot
        v-if="!useConfdata().signupEnabled || !useConfdata().siteSettings?.includes('newsletter') || Math.random() > 0.5"
        :domain="useConfdata().domain"
        :resource="useConfdata().sites[0]"
      />
      <SearchNewsletterSignup v-else />
    </SearchAreaV5>

    <SearchRatingBox :key="filtersKey" />

    <SearchTeaser v-if="!useParams().iframe">
      <SearchBreadcrumb :geo="geo" />
      <template #searchteaser>
        <SearchObjectTeasers
          v-if="showObjectTeasers"
          :key="searchFilterKey"
          :language-prefix="useConfdata().languagePrefix!"
          :locale="useL10N().locale"
          :domain="useConfdata().domain"
          :currency="useConfdata().currency"
          :country="destination?.countryCode"
          :region="destination?.regionCode"
          :place="destination?.placeCode"
          :countryname="destination?.country"
          :regionname="destination?.region"
          :placename="destination?.place"
          :salesoffice="useConfdata().salesoffice?.code!"
          :filter="searchFilter"
        />
        <!-- Only show on canonical pages that have a geo filter and either nno other filters or one of ['sea', 'pool', 'pets', 'familyfriendly'] -->
      </template>
      <template #contentassets>
        <SearchContentAssets v-if="showContentAssets" :key="searchFilterKey" :filter="searchFilter" />
      </template>
    </SearchTeaser>

    <USPCards :why="additionalBody.USPCards.cards" />

    <USPWhy :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]" />

    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const seoBlacklist = [
  'www.interchalet.de/en',
  'www.interhome.de',
  'www.interhome.at',
  'www.interhome.at/en',
  'www.interhome.dk/en',
  'www.interhome.se/en',
  'www.interhome.cz/en',
  'www.interhome.no/en',
  'www.interhome.ch',
  'www.interhome.ch/en',
  'www.interhome.ch/it',
]

const geo = computed(() => useSearch().geoCurrent)
const filters = computed(() => removeFalsy(useSearch().filters))
const filtersKey = computed(() => JSON.stringify(filters.value))
const searchUrl = computed(() => useSearch().url)
const total = computed(() => useSearch().total ?? 0)
const flexTripFilter = computed(() => useFlexTrip().filter)
const additionalBody = computed(() => useConfdata().additionalBody as SearchPageBody)
const searchfilterconfs = computed(() => additionalBody.value.searchfilterconfs)
const destination = computed(() => useConfdata().destination)
const filter = computed(() => destination.value?.filters?.[0].name)

const searchFilterKey = computed(() => {
  const { countryCode, regionCode, placeCode } = destination.value
  return (countryCode || '') + (regionCode || '') + (placeCode || '') + (filter.value || '')
})

const searchFilter = computed(() => {
  return searchfilterconfs.value?.find((f) => f.translation === filter.value)?.filter
})

const showObjectTeasers = computed(() => {
  return (
    useAB().isActive('objectteaser') &&
    total.value &&
    total.value >= 10 &&
    // TODO: isCanonicalPage
    // this.$store.getters.isCanonicalPage &&
    destination.value?.countryCode &&
    (!filter.value || ['sea', 'pool', 'pets', 'skiing'].includes(searchfilterconfs?.value?.find((f) => f.translation === filter.value)?.filter || ''))
  )
})

const showContentAssets = computed(() => {
  return (
    !seoBlacklist.includes(useConfdata().domain + useConfdata().languagePrefix) &&
    // TODO: isCanonicalPage
    // this.$store.getters.isCanonicalPage &&
    destination.value?.countryCode &&
    (!filter.value || ['sea', 'pool', 'pets', 'familyfriendly'].includes(searchfilterconfs?.value?.find((f) => f.translation === filter.value)?.filter || ''))
  )
})

watch(useRoute(), () => window?.scrollTo({ top: 0 }), { deep: true, immediate: true })

watch(flexTripFilter, (newFilter, oldFilter) => {
  if (objectsEqual(newFilter, oldFilter) || Object.values(newFilter).every((v) => !v)) return
  nextTick(() => useSearch().setFilter(newFilter))
})

watch(filters, () => (useFlexTrip().range = tripToDateRange(filters.value)))

watch(searchUrl, async (newValue, oldValue) => {
  if (!newValue || newValue === oldValue) return

  useNavigation().navigateToURL(new URL(newValue))
  await useConfdata().load()
  usePageHead().update(useConfdata().head)
})

onMounted(() => {
  window.onbeforeunload = function () {
    // Before a page-reload the browser normally memorises the scroll-position, but on the reloaded searchpage this may have unwanted sideeffects cause of dynamic content.
    // So it is necessary to set the scroll-position to 0 before unload (which leads to position top on reload)
    window.scrollTo(0, 0)
  }
})

function onInteraction(event: {
  map?: boolean
  bookmark?: {
    accom: ResultDocument
    state: boolean
  }
}) {
  for (const key in event) {
    switch (key) {
      case 'map':
        useTracking().handlers?.search.objectMap(event.map!)
        break
      case 'bookmark':
        useTracking().handlers?.search.toggleBookmark({ accom: event.bookmark!.accom.code as string, state: event.bookmark!.state as boolean })
        break
    }
  }
}
</script>
