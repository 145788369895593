<template>
  <div>
    <SearchContentAssetsIntro v-if="assets?.intro" v-model="assets.intro" class="mb-14 first:mt-14 last:mb-0" />
    <SearchSightsAndContent v-if="assets?.sights" v-model="assets.sights" class="mb-14 first:mt-14 last:mb-0" />
    <SearchNaturalSights v-if="assets?.naturalSights" v-model="assets.naturalSights" class="mb-14 first:mt-14 last:mb-0" />
    <SearchWeatherInfo v-if="assets?.weather" v-model="assets.weather" class="mb-14 first:mt-14 last:mb-0" />
    <SearchArticleTeaser v-if="assets?.teasers" v-model="assets.teasers" class="mb-14 first:mt-14 last:mb-0" />
    <SearchSecretSpot v-if="assets?.secretSpots" v-model="assets.secretSpots" class="mb-14 first:mt-14 last:mb-0" />
    <SearchThreeDaySummary v-if="assets?.threeDaysSummary" v-model="assets.threeDaysSummary" class="mb-14 first:mt-14 last:mb-0" />
    <SearchTouristicActivities v-if="assets?.touristicActivities" v-model="assets.touristicActivities" class="mb-14 first:mt-14 last:mb-0" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  filter?: string
}>()

const assets = ref<ContentAssets>()

onMounted(async () => {
  assets.value = (await useContentAssets(props)).data.value ?? undefined
})
</script>
