<template>
  <div
    ref="dateRangeSearch"
    class="z-1 flex justify-center top-0 left-0"
    :class="flexTrip.flexibleSearch ? 'fixed h-screen w-screen md:absolute md:h-auto' : 'absolute'"
  >
    <WebccDateRangeSearchFlex v-if="flexTrip.flexibleSearch" @close="close" />

    <WebccDateRangeSearchSimple v-else @close="close" />
  </div>
</template>

<script setup lang="ts">
defineExpose({ focusOnFirstDate })

const emit = defineEmits<{ close: [] }>()

const flexTrip = useFlexTrip()

const dateRangeSearch = ref()

onMounted(async () => {
  await nextTick()
  dateRangeSearch.value.setAttribute('tabindex', '-1')
})

function close() {
  emit('close')
}

function focusOnFirstDate() {
  dateRangeSearch.value?.querySelector(`.id-${toDate(flexTrip.range.start ?? new Date())} .vc-day-content`)?.focus({ preventScroll: true })
}
</script>
