<template>
  <div :class="classes">
    <WebccLoader class="p-4" :message="message" :type="type" :size="size" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  absolute?: boolean
  rounded?: string
  message?: string
  type?: string
  opacity?: number
  scrolling?: { onopen: boolean; onclose: boolean }
  size?: string
}

const props = withDefaults(defineProps<Props>(), {
  absolute: false,
  rounded: 'none',
  message: '',
  type: 'circle',
  opacity: 30,
  scrolling: () => {
    return { onopen: true, onclose: true }
  },
  size: 'md',
})

const classes = computed(() => {
  let classes = `top-0 left-0 z-[15] flex h-full w-full flex-col items-center justify-center overflow-hidden`
  switch (props.opacity) {
    case 0:
      classes += ' bg-black/0'
      break
    case 10:
      classes += ' bg-black/10'
      break
    case 20:
      classes += ' bg-black/20'
      break
    case 40:
      classes += ' bg-black/40'
      break
    case 50:
      classes += ' bg-black/50'
      break
    case 60:
      classes += ' bg-black/60'
      break
    case 70:
      classes += ' bg-black/70'
      break
    case 80:
      classes += ' bg-black/80'
      break
    case 90:
      classes += ' bg-black/90'
      break
    case 30:
    default:
      classes += ' bg-black/30'
      break
  }
  if (props.absolute) {
    classes += ' absolute'
  } else {
    classes += ' fixed'
  }
  classes += ' rounded-' + props.rounded
  return classes
})

onMounted(() => {
  if (props.absolute) {
    return
  }
  if (props.scrolling.onopen) {
    const cW = document.body.clientWidth
    document.documentElement.classList.add('!overflow-y-hidden')
    document.body.style.width = cW + 'px'
  }
})

onUnmounted(() => {
  if (props.absolute) {
    return
  }
  if (props.scrolling.onclose) {
    document.documentElement.classList.remove('!overflow-y-hidden')
    document.body.style.width = ''
  }
})
</script>
