const STORAGE_KEY = 'CONSENT'

export const useConsent = defineStore('consent', () => {
  const consent = useLocalStorage<Consent>(STORAGE_KEY, {})
  const configOpen = ref(false)

  const open = computed(() => !consent.value.timestamp)

  function update(value: Consent) {
    consent.value = value
    useTracking().trackConsentUpdate(value)
  }

  return { consent, open, configOpen, update }
})
