<template>
  <div class="my-4 flex flex-row flex-wrap gap-2 align-baseline">
    <FilterTag
      v-for="(value, filter) in prettyFilters"
      :key="filter"
      :name="value.name || ''"
      :filter="filter.toString()"
      :value="value.label"
      :type="value.type"
      @remove="remove(filter.toString())"
    />
  </div>
</template>

<script setup lang="ts">
const distmap: { [key: number]: string } = {
  100: '100m',
  500: '500m',
  1000: '1km',
  2000: '2km',
  5000: '5km',
  10000: '10km',
}

defineProps<{
  currency: string
}>()

const prettyFilters = computed(() => {
  const pretty: { [key: string]: PrettyFilter } = {}
  for (const key in useSearch().filters) {
    if (!useSearch().filters[key]) {
      continue
    }
    switch (key) {
      case 'country':
        if (useSearch().filters.country) {
          pretty[key] = { type: 'dest', label: useConfdata()?.destination?.country as string }
        }
        break
      case 'region':
        if (useSearch().filters.region) {
          pretty[key] = { type: 'dest', label: useConfdata()?.destination?.region as string }
        }
        break
      case 'place':
        if (useSearch().filters.place) {
          pretty[key] = { type: 'dest', label: useConfdata()?.destination?.place as string }
        }
        break
      case 'maxPrice':
        pretty[key] = { type: 'price', label: formatCurrency(useSearch().filters.maxPrice as number, undefined, useConfdata().currency) }
        break
      case 'pax':
        pretty[key] = { type: 'number', label: useSearch().filters.pax as number }
        break
      case 'rooms':
        pretty[key] = { type: 'number', label: useSearch().filters.rooms as number }
        break
      case 'bedrooms':
        pretty[key] = { type: 'number', label: useSearch().filters.bedrooms as number }
        break
      case 'bathrooms':
        pretty[key] = { type: 'number', label: useSearch().filters.bathrooms as number }
        break
      case 'sea':
        pretty[key] = { type: 'distance', label: distmap[useSearch().filters.sea as number] }
        break
      case 'lake':
        pretty[key] = { type: 'distance', label: distmap[useSearch().filters.lake as number] }
        break
      case 'ski':
        pretty[key] = { type: 'distance', label: distmap[useSearch().filters.ski as number] }
        break
      case 'center':
        pretty[key] = { type: 'distance', label: distmap[useSearch().filters.center as number] }
        break
      case 'checkin':
        pretty[key] = {
          type: 'trip',
          name: 'trip',
          label: `${formatDatetime(useSearch().filters.checkin as string)} - ${formatDatetime(useSearch().filters.checkout as string)}`,
        }
        break
      case 'checkout':
      case 'duration':
      case 'sorting':
        break
      case 'q':
        pretty[key] = { type: 'text', label: useSearch().filters[key] as string }
        break
      default:
        pretty[key] = { type: 'filter', label: '' }
        break
    }
  }
  return pretty
})

function remove(filter: string) {
  if (filter === 'checkin') {
    useSearch().setFilter({ checkout: undefined, checkin: undefined, duration: undefined })
  } else if (filter === 'country') {
    useSearch().setFilter({ country: undefined, region: undefined, place: undefined })
  } else if (filter === 'region') {
    useSearch().setFilter({ region: undefined, place: undefined })
  } else {
    useSearch().setFilter({ [filter]: false })
  }
}
</script>
