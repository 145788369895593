import {
  addDays,
  addHours,
  addMonths,
  addYears,
  compareAsc,
  compareDesc,
  differenceInDays,
  format,
  formatISO,
  isAfter,
  isBefore,
  isDate,
  isFuture,
  isPast,
  isSameDay,
  max,
  parseISO,
  setMinutes,
  subDays,
  subMonths,
  subYears,
  eachDayOfInterval,
} from 'date-fns'

import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
export type UnparsedDate = string | Date

export function parse(date: UnparsedDate): Date {
  if (isDate(date)) {
    return date as Date
  } else {
    return parseISO(date as string)
  }
}

export function tryParseDate(date?: UnparsedDate): Date | undefined {
  return date ? parse(date) : undefined
}

export function compare(first: UnparsedDate, second: UnparsedDate, desc = true) {
  const parsedFirst = parse(first)
  const parsedSecond = parse(second)
  if (desc) {
    return compareDesc(parsedFirst, parsedSecond)
  } else {
    return compareAsc(parsedFirst, parsedSecond)
  }
}

export function sameDay(first: UnparsedDate, second: UnparsedDate) {
  return isSameDay(parse(first), parse(second))
}

export function dateBefore(first: UnparsedDate, second: UnparsedDate) {
  return isBefore(parse(first), parse(second))
}
export function dateAfter(first: UnparsedDate, second: UnparsedDate) {
  return isAfter(parse(first), parse(second))
}
export function dateBetween(date: UnparsedDate, start: UnparsedDate, end: UnparsedDate) {
  return !(dateBefore(date, start) || dateAfter(date, end))
}

export function datesEqual(first: UnparsedDate, second: UnparsedDate) {
  const f = parse(first)
  f.setHours(0)
  f.setMinutes(0)
  f.setSeconds(0)
  const s = parse(second)
  s.setHours(0)
  s.setMinutes(0)
  s.setSeconds(0)

  return s.getTime() === f.getTime()
}

export function inFuture(date: UnparsedDate) {
  return isFuture(parse(date))
}

export function inPast(date: UnparsedDate) {
  return isPast(parse(date))
}

/**
 * Input date strings will be converted to Dates via date-fns parseISO and are thus expected to match YYYY-MM-DD
 *
 * @param {string} date
 */
export function daysBefore(date: UnparsedDate) {
  return differenceInDays(parse(date), Date.now())
}

export function diff(first: UnparsedDate, second: UnparsedDate) {
  return differenceInDays(parse(first), parse(second))
}

/**
 * Input date strings will be converted to Dates via date-fns parseISO and are thus expected to match YYYY-MM-DD
 *
 * @param {string} date
 * @param {number} days
 */
export function daysAdd(date: UnparsedDate, days: number) {
  return addDays(parse(date), days)
}

export function monthsAdd(date: UnparsedDate, months: number) {
  return addMonths(parse(date), months)
}

export function monthsSub(date: UnparsedDate, months: number) {
  return subMonths(parse(date), months)
}

export function yearsAdd(date: UnparsedDate, years: number) {
  return addYears(parse(date), years)
}

export function yearsSub(date: UnparsedDate, years: number) {
  return subYears(parse(date), years)
}

/**
 * Input date strings will be converted to Dates via date-fns parseISO and are thus expected to match YYYY-MM-DD
 *
 * @param {string} date
 * @param {number} days
 */
export function daysSub(date: UnparsedDate, days: number) {
  return subDays(parse(date), days)
}

export function hoursAdd(date: UnparsedDate, hours: number) {
  return addHours(parse(date), hours)
}

export function toDate(date: UnparsedDate) {
  return formatISO(parse(date), { representation: 'date' })
}

export function toServerDate(date: UnparsedDate): string {
  return formatISO(parse(date), { representation: 'date' })
}

export function toDateTime(date: UnparsedDate) {
  return formatISO(parse(date), { representation: 'complete' })
}

export function toDateFormat(date: UnparsedDate, f: string) {
  return format(parse(date), f)
}

/**
 * Input date strings will be converted to Dates via date-fns parseISO and are thus expected to match YYYY-MM-DD
 *
 * @param {string} first
 * @param {string} second
 */
export function calculateDuration(first: UnparsedDate, second: UnparsedDate) {
  return differenceInDays(parse(second), parse(first))
}

export function calculateCheckoutDate(checkin: UnparsedDate, duration: number) {
  return addDays(parse(checkin), duration)
}

export function daysSubWithLimit(date: UnparsedDate, days: number, limit: UnparsedDate) {
  const parsedDate = parse(date)
  const parsedLimit = parse(limit)
  const pastDate = subDays(parsedDate, days)
  return max([pastDate, parsedLimit])
}

/**
 * Input date strings will be converted to Dates via date-fns parseISO and are thus expected to match YYYY-MM-DD
 * https://jira.hotelplan.com/browse/HHDWEBCC-2310
 *
 * @param {string} date
 */
export function isWinter(date: UnparsedDate) {
  if (date) {
    const parsedDate = parse(date)
    const year = parsedDate.getFullYear()
    return isBefore(parsedDate, new Date(year, 3, 1)) || isAfter(parsedDate, new Date(year, 9, 20))
  } else {
    const parsedDate = parse(new Date())
    const year = parsedDate.getFullYear()
    return isBefore(parsedDate, new Date(year, 1, 8)) || isAfter(parsedDate, new Date(year, 9, 20))
  }
}

export function addTimezoneOffset(date: UnparsedDate) {
  let parsedDate = parse(date)
  const timezoneOffset = parsedDate.getTimezoneOffset()
  parsedDate = setMinutes(parsedDate, Math.abs(timezoneOffset) >= 720 ? timezoneOffset : 0)
  return formatISO(parsedDate, { representation: 'date' })
}

export function formatDateRange(start: UnparsedDate, end: UnparsedDate) {
  return [start, end].map((date) => formatDatetime(toDate(date))).join(' - ')
}

export function toUTC(date: UnparsedDate, tz = 'Europe/Zurich') {
  return zonedTimeToUtc(parse(date), tz).getTime()
}

export function toLocaldate(date: UnparsedDate) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return utcToZonedTime(date, timezone)
}

export function isDateRangeValid(range: DateRange): range is ValidDateRange {
  return !!(range.start && range.end)
}

export function getDateRangeInDays(range: ValidDateRange): number {
  return differenceInDays(range.end, range.start)
}

export function toShortDate(date: UnparsedDate, locale?: string): string {
  return parse(date).toLocaleString(locale, { month: 'short', day: 'numeric' })
}

export function getDateRangeEachDay(range: ValidDateRange): Date[] {
  return eachDayOfInterval(range)
}
