import type { Session, LogoutFlow } from '@ory/client'
import { FrontendApi, Configuration } from '@ory/client'
import { isAxiosError } from 'axios'

export const useAuth = defineStore('auth', () => {
  const ory = new FrontendApi(
    new Configuration({
      basePath: getOryAPIUrl(),
      baseOptions: {
        // Ensures we send cookies and all other headers in the CORS requests.
        withCredentials: true,
      },
    }),
  )
  const pending = ref(true)
  const session = ref<Session>()
  const logoutFlow = ref<LogoutFlow>()

  const isAuthorized = computed(() => !!session.value)

  async function init() {
    if (!useAB().isActive('guestWorld')) return

    try {
      pending.value = true
      session.value = (await ory.toSession(useRequestHeaders(['cookie']))).data
      logoutFlow.value = (await ory.createBrowserLogoutFlow()).data
    } catch (error) {
      if (!isAxiosError(error) || error.response?.status !== 401) console.error('Authentication check failed', error)
    } finally {
      pending.value = false
    }
  }

  async function logout() {
    if (!logoutFlow.value) return

    try {
      pending.value = true
      await ory.updateLogoutFlow({ token: logoutFlow.value.logout_token })
      session.value = undefined
    } catch (error) {
      console.error('Logout failed', error)
    } finally {
      pending.value = false
    }
  }

  function getOryAPIUrl() {
    const { hostname } = useRequestURL()

    const routes = {
      localhost: 'http://localhost:4000', // Ory tunnel
      'k8s.hotelplan.net': 'https://ory-auth.k8s.hotelplan.net',
      'k8s.hotelplan.com': 'https://ory-auth.k8s.hotelplan.net',
      'webcc.hpk8s.com': 'https://ory-auth.webcc.hpk8s.com',
      '*': `https://ory-auth.${hostname.replace(/^www\./, '')}`,
    }

    return Object.entries(routes).find(([pattern]) => hostname.includes(pattern))?.[1] ?? routes['*']
  }

  return { isAuthorized, pending, init, logout, session }
})
