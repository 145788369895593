const trPrefix = 'www.components.elements.FlexibleDates.'

export const useFlexTrip = defineStore('flextrip', () => {
  const flexibleCalendar = ref(useSessionStorage('flexible-search', !!useParams().all.duration).value) // use flexible calendar tab when true
  const updateKey = ref('')
  const open = ref(false)
  const start = ref(tryParseDate(useParams().all.checkin?.toString()))
  const end = ref(tryParseDate(useParams().all.checkout?.toString()))
  const flexDuration = ref(tryParseInt(useParams().all.duration?.toString()) ?? DURATION_DEFAULT)

  const range = computed<DateRange>({
    get() {
      return { start: start.value, end: end.value }
    },
    set(r) {
      start.value = r.start
      end.value = r.end
      if (!flexibleSearch.value) open.value = false
    },
  })

  const valid = computed(() => isDateRangeValid(range.value) && !!getDateRangeInDays(range.value))

  const flexibleSearch = computed(() => flexibleCalendar.value || useAB().isActive('flexibleDateSearch'))

  const duration = computed(() => (flexibleCalendar.value ? flexDuration.value : undefined))

  const durationText = computed(() => (duration.value ? getDurationText(duration.value) : ''))

  const filter = computed(() =>
    start.value && end.value
      ? { checkin: toDate(start.value), checkout: toDate(end.value), duration: duration.value }
      : { checkin: undefined, checkout: undefined, duration: undefined },
  )

  const tripText = computed(() => {
    const { start, end } = range.value

    if (flexibleCalendar.value && valid.value) {
      return translate(`${trPrefix}night_between`, {
        duration: durationText.value,
        start: formatDatetime(toDate(start!), '2-digit-short'),
        end: formatDatetime(toDate(end!), '2-digit-short'),
      })
    }

    if (start) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      return [start, end].map((date) => (date ? formatDatetime(toDate(date), undefined, timezone) : '')).join(' - ')
    }

    return ''
  })

  function reset() {
    start.value = undefined
    end.value = undefined
    flexDuration.value = DURATION_DEFAULT
    updateKey.value = new Date().toISOString()
  }

  function changeDuration(d: number) {
    flexDuration.value = d
    if (start.value && end.value && d > calculateDuration(start.value, end.value)) {
      end.value = daysAdd(start.value, d)
    }
  }

  function getDurationText(duration: number) {
    return duration % 7 ? translate(`${trPrefix}night_s`, {}, duration) : translate(`${trPrefix}week_s`, {}, duration / 7)
  }

  return { flexibleCalendar, flexibleSearch, open, range, updateKey, valid, duration, filter, durationText, tripText, reset, changeDuration, getDurationText }
})
