<template>
  <nav class="flex min-h-[2rem] w-full items-center justify-center gap-6 text-txt-strongest" role="navigation" aria-label="pagination">
    <WebccLink v-show="active > 1 && active <= total" :to="previous" @click.prevent="selectPage(active - 1)">
      <WebccIcon class="text-txt h-5 w-5" name="site/chevron-left" filled />
    </WebccLink>
    <ul class="flex flex-wrap items-center justify-center gap-3 text-xl">
      <li v-for="(page, i) in items" :key="i">
        <span v-if="page === '...'">&hellip;</span>
        <WebccLink
          v-else
          :to="urls[i]"
          class="rounded border border-transparent px-2.5 py-1 transition-all hover:border-bgr-400"
          :class="{ 'border-txt-strongest bg-bgr hover:border-txt-strongest': page.toString() === active.toString() }"
          :aria-label="page"
          aria-current="page"
          :disabled="active === page"
          @click.prevent="selectPage(page)"
        >
          {{ page }}
        </WebccLink>
      </li>
    </ul>
    <WebccLink v-show="active < total" class="pagination-next" :to="next" @click.prevent="selectPage(active + 1)">
      <WebccIcon class="text-txt h-6 w-6" name="site/chevron-right" filled />
    </WebccLink>
  </nav>
</template>

<script setup lang="ts">
const props = defineProps({
  active: { type: Number, default: 1 },
  total: { type: Number, default: 0 },
  visible: { type: Number, default: 7 },
})

const emit = defineEmits<{ (e: 'select-page', value: number): void }>()

const items = computed(() => {
  const maxLength = props.visible
  if (props.total <= props.visible) {
    return range(1, props.total)
  }

  const even = maxLength % 2 === 0 ? 1 : 0
  const left = Math.floor(maxLength / 2)
  const right = props.total - left + 1 + even

  if (props.active > left && props.active < right) {
    const start = props.active - left + 2
    const end = props.active + left - 2 - even

    return [1, '...', ...range(start, end), '...', props.total]
  } else if (props.active === left) {
    const end = props.active + left - 1 - even
    return [...range(1, end), '...', props.total]
  } else if (props.active === right) {
    const start = props.active - left + 1
    return [1, '...', ...range(start, props.total)]
  } else {
    return [...range(1, left), '...', ...range(right, props.total)]
  }
})

const urls = computed(() => {
  return items.value.map((item) => {
    const base = useRequestURL()
    base.searchParams.set('page', item.toString())

    return base.pathname + base.search
  })
})

const next = computed(() => {
  let page = props.active + 1
  if (isNaN(props.active)) {
    page = 1
  }
  const url = useRequestURL()
  url.searchParams.set('page', page.toString())

  return url.pathname + url.search
})

const previous = computed(() => {
  let page = 1
  if (!isNaN(props.active)) {
    page = props.active - 1
  }
  const url = useRequestURL()
  url.searchParams.set('page', page.toString())

  return url.pathname + url.search
})

function selectPage(page: number | string) {
  console.log('selectPage page ', page)
  if (typeof page === 'string') {
    page = parseInt(page, 10)
  }
  if (page < 1 || page > props.total) {
    return
  }
  //   loadingPage = page
  emit('select-page', page)
}

function range(from: number, to: number) {
  from = from > 0 ? from : 1

  const range = []
  for (let i = from; i <= to; i++) {
    range.push(i)
  }
  return range
}
</script>
