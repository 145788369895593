<template>
  <section class="bg-bgr py-6">
    <div class="container-fluid-lg mb-5">
      <slot />
    </div>

    <div class="container-fluid-lg mb-12">
      <slot name="searchteaser" />
    </div>

    <div class="container-fluid-lg mb-12">
      <SearchTeaserFilters />
      <TeaserSubDestinations :destination="useConfdata().destination" />
      <slot name="contentassets" />
    </div>

    <div v-if="additionalBody.seotext.content_html" class="container-fluid-lg">
      <WebccExpandable :key="additionalBody.seotext.content_html">
        <div v-impression:search:seoTextImpression.100="'start'"></div>
        <article
          class="prose prose-richtext-searchpage mt-4 max-w-none prose-img:w-full"
          @click="intercept"
          v-html="additionalBody.seotext.content_html"
        ></article>
        <div v-impression:search:seoTextImpression.100="'end'"></div>
      </WebccExpandable>
    </div>
  </section>
</template>

<script setup lang="ts">
defineExpose({
  trPrefix: 'www.components.views.search.searchTeaser.',
})

const additionalBody = computed(() => useConfdata().additionalBody as SearchPageBody)

function intercept(e: MouseEvent) {
  const target = (e.target || e.srcElement) as Element
  const href = target.getAttribute('href')

  if (target.tagName !== 'A' || !href) return

  target.setAttribute('href', useURLs().buildValidUrl(href).toString())
}
</script>
