/*
 * Tracking-Spezifikation:
 * https://wiki.hotelplan.com/display/TSICHDDWEBCC/11+-+Newsletter
 *
 */

export default {
  registration,
  registrationHeID,
  registrationFailed,
  registrationFailedAdditional,
  optIn,
  optInHeID,
  unsubscribe,
  unsubscribeSubmit,
}

function registration(type: string) {
  let eventAction = 'Registration'
  if (type) {
    eventAction = 'Registration ' + type
  }
  useTracking().send({
    event: 'event',
    eventCategory: 'Newsletter',
    eventAction,
    eventLabel: '/contentsite/contact/newsletter-registration/',
  })
}
function registrationHeID({ type, heid }: { type: string; heid: string }) {
  useTracking().send({
    event: 'newsletter_subscription',
    eventPayload: {
      action: 'submit',
      source: type,
      heidi: heid,
    },
    _clear: true,
  })
}

function registrationFailed() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Newsletter',
    eventAction: 'Registration failure',
    eventLabel: '404-error?page=/contentsite/contact/newsletter-registration/',
  })
}
function registrationFailedAdditional() {
  useTracking().send({
    event: 'newsletter_subscription',
    eventPayload: {
      action: 'error',
    },
    _clear: true,
  })
}

function optIn() {
  useTracking().send({
    event: 'event',
    eventCategory: 'Newsletter',
    eventAction: 'Opt-In',
  })
}

function optInHeID(heid: string) {
  useTracking().send({
    event: 'newsletter_subscription',
    eventPayload: {
      action: 'complete',
      heidi: heid,
    },
    _clear: true,
  })
}

function unsubscribe(heid: string) {
  useTracking().send({
    event: 'newsletter_unsubscription',
    eventPayload: {
      action: 'view',
      heidi: heid,
    },
    _clear: true,
  })
}

function unsubscribeSubmit(heid: string) {
  useTracking().send({
    event: 'newsletter_unsubscription',
    eventPayload: {
      action: 'submit',
      heidi: heid,
    },
    _clear: true,
  })
}
