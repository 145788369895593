import { skipHydrate } from 'pinia'

const STORAGE_KEY = 'bookmarks'

export const useBookmarks = defineStore('bookmarks', () => {
  const raw: Ref<string[]> = useLocalStorage(STORAGE_KEY, useLocalStorage(STORAGE_KEY, []))
  const catalog: Ref<string[] | null> = ref(null)

  const count = computed(() => raw.value.length)

  function add(id: string) {
    raw.value.push(id)
  }

  function remove(id: string) {
    raw.value = raw.value.filter((e) => e !== id)
  }

  function toggle(id: string) {
    if (raw.value.includes(id)) {
      remove(id)
    } else {
      add(id)
    }
  }

  function clear() {
    raw.value = []
  }

  async function createPersonalCatalog(params: Omit<PersonalCatalogCreateParams, 'language' | 'salesoffice' | 'url' | 'parameters'>) {
    const partner = usePartner()
    const { language, salesoffice } = useConfdata()

    return await useWebccApi().personalCatalogCreate({
      language: language!,
      salesoffice: salesoffice?.code,
      url: useRequestURL().toString(),
      ...params,
      parameters: Object.entries({
        PartnerId: partner.id || undefined,
        retailerMode: partner.id && partner.jwt ? 'T1' : '06',
      })
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${value}`),
    })
  }

  async function loadPersonalCatalog(id: string) {
    catalog.value = (await useWebccApi().personalCatalog(id)).data.value
  }

  return { raw: skipHydrate(raw), count, catalog, add, remove, toggle, clear, loadPersonalCatalog, createPersonalCatalog }
})
