import { defineNuxtPlugin } from '#app'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import { IGNORED_ERROR_CODES } from '~/utils/errors'

export default defineNuxtPlugin((nuxtApp) => {
  const router = nuxtApp.$router as unknown as Router
  const shouldSendToSentry = (error: any) => !IGNORED_ERROR_CODES.includes(error.statusCode)

  router.onError((error: any) => {
    if (shouldSendToSentry(error)) {
      Sentry.captureException(error)
    } else {
      console.error(error)
    }
  })
})
