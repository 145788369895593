<template>
  <component v-bind="$attrs" :is="component" :class="classes" @click="edit">
    {{ translation }}
  </component>
</template>

<script setup lang="ts">
interface Props {
  /** Fully qualified path to a specific text resource */
  tr?: string
  /** Optional parameter to be used with pluralized text resources */
  count?: number
  /** Usually the prefix is taken from the parent component, in some cases this needs to be overwritten, which can be done with this prop. */
  prefix?: string
  /** Usually the prefix is taken from the parent component, in some cases this needs to be overwritten, which can be done with this prop. */
  keyProp?: string
  /** The element to render this component as @default span */
  component?: keyof HTMLElementTagNameMap
  /** A map of replacements */
  params?: { [key: string]: string }
}

const props = withDefaults(defineProps<Props>(), {
  tr: '',
  count: 0,
  prefix: '',
  keyProp: '',
  component: 'span',
  params: () => {
    return {}
  },
})
const editmode = computed(() => useRuntimeConfig().public.translations.renderEditorLinks)

const key = computed(() => {
  return props.tr || (props.prefix || getCurrentInstance()?.parent?.exposed?.trPrefix || '') + props.keyProp
})

const translation = computed(() => {
  return translate(key.value as TranslationKey, props.params, props.count)
})

const classes = computed(() => (editmode.value ? useRuntimeConfig().public.translations.linkClass : ''))

function edit() {
  if (editmode.value) {
    window.open(useRuntimeConfig().public.translations.linkPrefix + key.value, '_blank')
  }
}
</script>
