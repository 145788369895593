<template>
  <div
    v-if="data.totalHits >= 10"
    v-impression:search:objectTeaserViewed.100="{ type, filter }"
    class="flex flex-col gap-4 rounded-lg border border-bgr-200 bg-bgr p-6 shadow"
  >
    <Text key-prop="title" :params="{ destination }" class="text-2xl font-medium" component="h2" />
    <Text key-prop="description" :params="{ destination }" class="text-base font-normal" />
    <div class="flex flex-col gap-2 sm:grid sm:grid-cols-2 md:grid-cols-3 md:gap-10">
      <HitboxCard
        v-for="(accom, index) in data.docs.slice(0, 3)"
        :key="index"
        :breadcrumb-links="false"
        class="w-full"
        :language-prefix="languagePrefix"
        :accom="accom"
        :locale="locale"
        :domain="domain"
        :currency="currency"
        :transformations="transformations"
        @to-detailpage="useTracking().handlers?.search.objectTeaserClicked({ type, filter })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const transformations = { xl: 'tr:n-srp_hitbox_l', lg: 'tr:n-srp_hitbox_l', md: 'tr:n-srp_hitbox_l' }

const props = defineProps<{
  type: string
  filter: string
  data: Result
  destination: string
  languagePrefix: string
  locale: string
  domain: string
  currency: string
}>()

const trPrefix = ref('www.components.views.search.ObjectTeaser.' + props.type + '.' + props.filter + '.')
defineExpose({ trPrefix: trPrefix.value })
</script>
