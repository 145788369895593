/* eslint-disable no-use-before-define */
// ??? = Dinge, die nochmals überprüft werden sollten

const translations = {
  www: {
    components: {
      hitbox: {
        bookmarkAdd: 'Merken', // "16707"
        bookmarkDel: 'Gemerkt', // "16884"
        location: 'Karte', // "16665" - auch "18201"
        Tags: {
          toprated: 'Top-Rated', // "25199"
          new: 'Neu', // "16274"
        },
        Features: {
          pax_s: 'keine Person | eine Person | {count} Personen', // "37530", "37531", "18073"
          room_s: 'keine Zimmer | ein Zimmer | {count} Zimmer', // "37532", "37537", "18071"
          bedroom_s: 'keine Schlafzimmer | ein Schlafzimmer | {count} Schlafzimmer', // "37533", "37536", "18069"
          bathroom_s: 'kein Bad | ein Bad | {count} Bäder', // "37534", "37535", "38799"
        },
        Title: {
          type: {
            A: 'Ferienwohnung', // "15980"
            H: 'Ferienhaus', // "15984"
          },
        },
        Rating: {
          review_s: 'Aktuell liegen uns noch keine Kundenbewertungen zu diesem Objekt vor | eine Bewertung | {count} Bewertungen', // "18442","39815","37506"
          labels: {
            1: 'Mangelhaft', // "16371"
            2: 'Befriedigend', // "16372"
            3: 'Gut', // "18170"
            4: 'Sehr gut', // "16373"
            5: 'Ausgezeichnet', // "16369"
          },
        },
        Price: {
          night_s: 'eine Nacht|{count} Nächte', // "37500", "37501"
          from: 'ab', // "16618"
          saved: 'Sie sparen {amount}', // "39658"
          upToPax: 'bei Belegung bis {count} Pers.', // "37408"
          addCosts: 'zuzüglich ggf. Nebenkosten', // "18334"
          dependentPrice: 'Preis abhängig von Zeitraum und Personenzahl', // "35762"
          priceAfterInput: 'Ihr Mietpreis wird nach Eingabe der Anzahl Reisender sowie Ihres Reisezeitraumes angezeigt.', // "36318"
          addCostsLong: 'zuzüglich obligatorischer oder fakultativer Nebenkosten nach tatsächlicher Belegung beziehungsweise tatsächlichem Verbrauch', // "16633"
        },
        Gallery: {
          inside: 'Innenbereich', // "18186"
          outside: 'Haus/Residenz', // "18182"
        },
        discover: 'Entdecken', // "18157"
      },
      site: {
        Header: {
          availability: 'Persönlich für Sie da!', // "18088"
          popularDestinations: 'Beliebte Reiseziele', // 42205
          contactUs: 'Kontaktieren Sie uns', // "16607"
          hereForYou: 'Wir sind für Sie da', // 42206
          faq: 'FAQ', // "39659"
          labels: {
            favorites: 'Merkliste', // "18208"
            history: 'Zuletzt angesehen', // "16644"
            language: 'Sprache', // "16642"
            currency: 'Währung', // "16557"
            menu: 'Menü', // "20275"
          },
          teasers: {
            destinations: {
              title: 'Alle Reiseziele im Überblick', // "18102"
              text: "Bei {company} finden Sie rund 35'000 Ferienhäuser und Ferienwohnungen in den schönsten Ferienorten und Ferienregionen Europas, Südafrikas und der USA. Vom Chalet über die Villa mit Pool bis hin zur charmanten Stadtwohnung – es ist bestimmt auch für Sie das richtige Feriendomizil dabei!", // "40741"
            },
            inspirations: {
              title: 'Ferienideen von {company}', // "40740"
              text: 'Besuchen Sie eines der komfortabel ausgestatteten Ferienhäuser in den Bergen oder eine zentral gelegene Ferienwohnung in der Stadt und erleben Sie einen unvergesslichen Aufenthalt!', // "40742"
            },
            offers: {
              title: 'Last Minute & Sonderangebote', // "18276"
              text: 'Egal ob Last Minute Schnäppchen, Frühbucherrabatt oder unglaubliche Spezialangebote – entdecken Sie hier Ferienwohnungen und Ferienhäuser mit sensationellen Vergünstigungen und profitieren Sie.', // "18110"
              lastMinute: {
                title: 'Last Minute', // "15952"
                prefix: 'Last Minute', // "39656"
              },
              additional: {
                title: 'Weitere Angebote', // "39660"
              },
            },
          },
          user: {
            name: 'User',
            login: 'Sign in',
            account: 'My account',
            bookings: 'My bookings',
            logout: 'Log out',
          },
        },
        history: {
          heading: 'Zuletzt angesehen', // "18257"
          pricePerWeek: 'Preis pro Woche', // "39122"
          type: {
            A: 'Ferienwohnung', // "15980"
            H: 'Ferienhaus', // "15984"
          },
        },
        DomainLanguageSwitcher: {
          countryAndLanguage: 'Wählen Sie Ihr Land und Ihre Sprache', // "35064"
          europe: 'Europa', // "35066" - Achtung: der Identifier "europe" lässt sich über die CMS-Suche nicht finden
          international: 'International', // "35065"
        },
        FooterNewsletterSignup: {
          cta: 'Anmelden', // '40258',
          title: 'Melden Sie sich zu unserem Newsletter an', // '40257',
          text: 'Erhalten Sie regelmäßig Informationen über Neuigkeiten und besondere Angebote direkt in Ihren Posteingang.',
          emailPlaceholder: 'E-Mail', // "16136"
        },
        BookmarkLink: {
          favorites: 'Merkliste', // "18208"
        },
      },
      elements: {
        SearchBox: {
          where: 'Wo wollen Sie Ihren Urlaub verbringen?', // "18379"
          when: 'Wann & wie lange?', // "18375"
          with: 'Mit wem?', // "18380"
          placeholders: {
            geo: 'Land, Region, Ort, Objektnummer', // "36640"
            dates: 'Reisezeitraum', // "18292"
            pax: 'Gäste inkl. Kinder', // "18172"
          },
          search: 'Suchen', // "16972"
          country: 'Land', // "16481"
          region: 'Region', // "16881"
          place: 'Ort', // "16815"
          accommodation: 'Ferienwohnung', // "15980"
          quick: 'Unterkunft', // "16692"
          yourSearch: 'Ihre Suche', // "39538"
          C: 'Land', // "16481"
          R: 'Region', // "16881"
          P: 'Ort', // "16815"
          H: 'Ferienhaus', // "15984"
          A: 'Ferienwohnung', // "15980"
          B: 'Boot', // "18124"
          G: 'Gästezimmer', // "18171"
        },
        Search: {
          placeholder: 'Land, Region, Ort, Objektnummer', // "36640"
          search: 'Suchen', // "16972"
          country: 'Land', // "16481"
          region: 'Region', // "16881"
          place: 'Ort', // "16815"
          accommodation: 'Ferienwohnung', // "15980"
          quick: 'Ferienwohnung', // "15980"
          yourSearch: 'Ihre Suche', // "39538"
        },
        Success: {
          // ??? Grundsatzfragen noch zu besprechen, weil an anderen Stellen auch noch verwendet
          success: 'Erfolg', // "39661"
          transmission: 'Herzlichen Dank, Ihre Daten wurden erfolgreich übermittelt.', // "16606"
          continue: 'Sie können Ihre Suche hier fortsetzen.', // "39662"
          inspiration: 'Weitere Urlaubsinspirationen', // "25087"
        },
        Error: {
          errorText: 'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte später nochmals.', // "16605"
        },
        Loginbar: {
          id: 'Ihre PartnerID', // 17039
          portal: 'Partner Home', // 16337
          logout: 'Logout', // 16662
        },
        FlexibleDates: {
          custom: 'Andere',
          h_duration: 'Wie lange möchten Sie verreisen?',
          h_range: 'Wann verreisen Sie?',
          night_between: '{duration} von {start} - {end}',
          night_s: 'eine Nacht|{count} Nächte',
          week_s: 'eine Woche|{count} Wochen',
          tip_range: 'Wählen Sie den Zeitraum aus, in dem Sie verreisen möchten',
        },
      },
      footer: {
        contact: {
          heading: 'Kontakt', // "18140"
          support: 'Wir helfen Ihnen bei Fragen gerne persönlich weiter.', // "18374"
          availability: 'Montag - Freitag: 10:00 - 16:00 Uhr, Samstag: 15:00 - 20:00 Uhr, Sonntag: 10:00 - 14:00 Uhr', // "18187"
        },
        newsletter: {
          heading: 'Newsletter', // "18210"
          teaser: 'Ab sofort nichts mehr verpassen!', // "18159"
          email: 'E-Mail-Adresse', // "18161"
          submit: 'Newsletter abonnieren', // "18277"
        },
        social: {
          heading: 'Folgen Sie uns', // "18165"
        },
        about: {
          heading: {
            ic: 'Über Interchalet', // "39664"
            ih: 'Über Interhome', // "39663"
          },
        },
        payment: {
          heading: 'Zahlungsarten', // "18227"
          encrypted: 'Alle Daten werden verschlüsselt übertragen.', // "19229"
          MAST: 'MasterCard', // "39898" // NEU
          VISA: 'VISA', // "39899" // NEU
          INV: 'Buchung auf Rechnung', // "39900" // NEU
          PAYPAL: 'PayPal', // "39901" // NEU
          ID: 'iDeal', // "39902" // NEU
          ECV: 'chèque-vacances', // "39903" // NEU
        },
        partner: {
          heading: 'Partnerschaften', // "18224"
          migros: 'Ein Unternehmen der Migros', // "19228"
          domains: 'Region und Sprache ändern', // "35333"
        },
        copyright: '© HHD AG Schweiz {year}', // "18084"
        cookieSettings: 'Cookie Einstellungen', // "37986"
        siteFeedback: 'Feedback zur Website',
      },
      form: {
        FormField: {
          required: 'Bitte ausfüllen', // "18244"
          invalidEmail: 'Bitte geben Sie eine gültige E-Mail Adresse an', // "18232"
          unmatchedPattern: "Bitte verwenden Sie die Zeichen <>' nicht.", // "18231" - ??? Komponente reviewen, ob das so Sinn macht
        },
      },
      views: {
        Content: {
          Catalog: {
            order: 'Jetzt kostenfrei anfordern', // "24857"
            close: 'Schließen', // "39673"
            flip: 'Online blättern', // "38312"
            required: 'Bitte ausfüllen', // "18244"
            invalidEmail: 'Bitte geben Sie eine gültige E-Mail Adresse an', // "18232"
            gdpr: 'Wir verwenden Ihre eMail-Adresse, Anrede, Vor- und Nachnamen sowie Anschrift auf Grundlage von Art. 6 Abs. f, DSGVO zum Zweck der Zusendung des mit diesem Formular bestellten Kataloges oder Newsletters und zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO finden Sie in unserer {dse} (nach § 13 TMG, Art. 13 DSGVO).', // "39675"
            privacyPolicy: 'Datenschutzerklärung', // "38826"
            form: {
              salutation: 'Anrede', // "16144"
              firstname: 'Vorname', // "16139"
              lastname: 'Nachname', // "16140"
              email: 'E-Mail', // "16136"
              mr: 'Herr', // "16146"
              ms: 'Frau', // "16147"
              street: 'Strasse & Hausnr.', // "16148"
              addressextra: 'Adresszusatz', // "16124"
              zip: 'Postleitzahl', // "16149"
              place: 'Ort', // "16143"
              country: 'Land', // "16132"
              phone: 'Telefonnummer', // "16141"
              newsletter: 'Newsletter', // "18210"
              targetCountries: 'Ich möchte gezielt von Interchalet zu bestimmten Urlaubsthemen beraten und informiert werden.', // "24854"
              adsFrom: 'Ich möchte gezielt von {company} zu bestimmten Urlaubsthemen beraten und informiert werden.', // "40375"
              submit: 'Absenden', // "16614"
            },
          },
          GeneralRequest: {
            yourRequest: 'Ihre Anfrage', // "38050"
            details: 'Persönliche Angaben', // "16151"
            gdpr: 'Wir verwenden Ihre in diesem Formular eingegebenen Daten auf Grundlage von Art. 6 Abs. b) DSGVO zum Zweck des Abschlusses und zur Abwicklung eines Vertrags und auf Grundlage von Art. 6 Abs.1 Buchst f) zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO erhalten Sie in unserer {dse}.', // "39674"
            privacyPolicy: 'Datenschutzerklärung', // "38826"
            form: {
              salutation: 'Anrede', // "16144"
              firstname: 'Vorname', // "16139"
              lastname: 'Nachname', // "16140"
              email: 'E-Mail', // "16136"
              mr: 'Herr', // "16146"
              ms: 'Frau', // "16147"
              street: 'Strasse & Hausnr.', // "16148"
              addressextra: 'Adresszusatz', // "16124"
              zip: 'Postleitzahl', // "16149"
              place: 'Ort', // "16143"
              country: 'Land', // "16132"
              phone: 'Telefonnummer', // "16141"
              newsletter: 'Newsletter', // "18210"
              submit: 'Absenden', // "16614"
              request: 'Bitte teilen Sie uns besondere Wünsche mit', // "17193"
            },
          },
          OfferRequest: {
            generalInformation: 'Allgemeine Informationen', // "17185"
            requirements: 'Anforderungen', // "17187"
            rooms: 'Zimmer und Einrichtung', // "17146"
            activities: 'Aktivitäten', // "17136"
            pax: 'Anzahl der reisenden Personen', // "17183"
            address: 'Persönliche Angaben', // "16151" ??? key
            hobbies: 'Hobbies', // "17167"
            interests: 'Ihre Interessen / Motivation für den Urlaub', // "17178"
            newsletter: 'Newsletter', // "18210"
            specialRequests: 'Besondere Wünsche', // "17193"
            gdpr: 'Wir verwenden Ihre in diesem Formular eingegebenen Daten auf Grundlage von Art. 6 Abs. b) DSGVO zum Zweck des Abschlusses und zur Abwicklung eines Vertrags und auf Grundlage von Art. 6 Abs.1 Buchst f) zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO erhalten Sie in unserer {dse}.', // "39674"
            gdprNewsletter:
              'Wir verwenden Ihre eMail Adresse, Anrede sowie Vor- und Nachnamen auf Grundlage von Art. 6 Abs. f, DSGVO zum Zweck der Zusendung des mit diesem Formular bestellten Newsletters und zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO finden Sie in unserer {dse}', // "39676"
            privacyPolicy: 'Datenschutzerklärung', // "38826"
            form: {
              submit: 'Absenden', // "16614"
              country: 'Land', // "16481"
              region: 'Region', // "16881"
              place: 'Ort', // "16815"
              arrival: 'Anreisedatum', // "16357"
              duration: 'Dauer', // "17145"
              objectType: 'Objektart', // "17149"
              newsletter: 'Newsletter', // "18210"
              comment: 'Kommentar', // "17188"
              night_s: 'eine Nacht|{count} Nächte', // "37500", "37501"
              week_s: 'eine Woche|{count} Wochen', // "9217", "9218"
              rooms: {
                label: 'Zimmer', // "17152"
                0: 'Studio', // "15835" - Hinweis: identifier accommodation_attribute_bed_0
                1: '1+ Schlafzimmer', // "15836"
                2: '2+ Schlafzimmer', // "15837"
                3: '3+ Schlafzimmer', // "15838"
                4: '4+ Schlafzimmer', // "15839"
              },
              facilities: {
                label: 'Ausstattung', // "18163"
                aircondition: 'Klimaanlage', // "15827"
                balcony: 'Balkon / Terrasse', // "15828"
                bbq: 'Grill', // "15834"
                cots: 'Babybett', // "15843"
                dishwasher: 'Geschirrspüler', // "15848"
                fireplace: 'Kamin / Ofen', // "15880"
                internet: 'Internet', // "15893"
                wlan: 'WiFi', // "15896"
                hottub: 'Sprudelbad', // "15972"
                lift: 'Aufzug vorhanden', // "15878"
                nosmoker: 'Nichtraucher', // "15945", V5-Attribut nonsmoking
                carport: 'Carport', // "18130"
                singleGarage: 'Einzelgarage', // "18275"
                commonGarage: 'Gemeinschaftsgarage', // "18137"
                parking: 'Parkplatz', // "15905"
                coveredParking: 'überdachter Parkplatz', // "15844"
                pets1: '1 Hund erlaubt', // "18086"
                pets2: '2 Hunde erlaubt', // "18087"
                pets0: 'Haustiere nicht erlaubt', // "18214"
                pool: 'Pool', // "15932"
                poolChildren: 'Kinderpool', // "15932"
                poolIndoor: 'Innenpool', // "15892"
                poolPrivate: 'Privater Pool', // "15936"
                sauna: 'Sauna', // "15942"
                tv: 'TV', // "15967"
                washingmachine: 'Waschmaschine', // "15969"
                wheelchair: 'Rollstuhlgeeignet', // "18487", beachte jedoch V5-Attribut "suitable_for_wheelchairs"
              },
              activities: {
                skiarea: 'Skifahren / Snowboard', // "15944"
                bikingplains: 'Radfahren', // "15846"
                crosscountryskiings: 'Langlauf', // "15845"
                golf: 'Golf', // "15886"
                hiking: 'Wandern', // "15888"
                mountainbiking: 'Mountain-Biking', // "15900"
                riding: 'Reiten', // "15940"
                sailing: 'Segeln', // "15941"
                surfing: 'Surfen', // "15962"
                tennis: 'Tennis', // "15964"
              },
              pax: {
                adults: 'Erwachsene (ab 18 Jahren)', // "16279"  - ACHTUNG nicht "18329", weil dort mit Doppelpunkt am Ende
                children: 'Kinder', // "17143"
              },
              address: {
                salutation: 'Anrede', // "16144"
                firstname: 'Vorname', // "16139"
                lastname: 'Nachname', // "16140"
                email: 'E-Mail', // "16136"
                mr: 'Herr', // "16146"
                ms: 'Frau', // "16147"
                street: 'Strasse & Hausnr.', // "16148"
                addressextra: 'Adresszusatz', // "16124"
                zip: 'Postleitzahl', // "16149"
                place: 'Ort', // "16143"
                country: 'Land', // "16132"
                phone: 'Telefonnummer', // "16141"
              },
              hobbies: {
                skiarea: 'Skifahren / Snowboard', // "15944"
                bikingplains: 'Radfahren', // "15846"
                crosscountryskiings: 'Langlauf', // "15845"
                golf: 'Golf', // "15886"
                hiking: 'Wandern', // "15888"
                mountainbiking: 'Mountain-Biking', // "15900"
                riding: 'Reiten', // "15940"
                sailing: 'Segeln', // "15941"
                surfing: 'Surfen', // "15962"
                tennis: 'Tennis', // "15964"
                swimming: 'Schwimmen', // "17164"
                diving: 'Tauchen', // "17156"
              },
              interests: {
                nature: 'Natur', // "17171"
                nightlife: 'Nightlife', // "17172"
                wine: 'Weinliebhaber', // "17176"
                recreation: 'Erholung', // "17173"
                gourmet: 'Gourmet', // "17170"
                culture: 'Kultur (Museen, Sehenswürdigkeiten)', // "17169"
                sports: 'Sport', // "17174"
                wellness: 'Wellness', // "15970"
              },
              contact: {
                label: 'Über welchen Kanal soll die Kontaktaufnahme stattfinden?', // "17189"
                mail: 'per Post', // "17192"
                email: 'per Email', // "16136"
              },
              objectTypes: {
                A: 'Ferienwohnung', // "15980"
                H: 'Ferienhaus', // "15984"
                B: 'Boot', // "18124"
                G: 'Gästezimmer', // "18171"
              },
            },
          },
        },
        PageDetails: {
          headings: {
            description: 'Beschreibung', // "18156"
            map: 'Lage', // "18200"
            availability: 'Verfügbarkeit prüfen und buchen', // "16464"
            costoverview: 'Kostenübersicht', // "16480"
            reviews: 'Kundenbewertungen', // "16558"
            advantages: 'Weitere Vorteile', // "18097"
          },
          bookmarkAdd: 'Merken', // "16707"
          bookmarkDel: 'Gemerkt', // "16884"
          cookieSettings: 'Cookie Einstellungen', // "37986"
          invalidCode: 'Ungültiger Objektcode', // "40401"
          review_s: 'Aktuell liegen uns noch keine Kundenbewertungen zu diesem Objekt vor | eine Bewertung | {count} Bewertungen', // "18442","34497","37506"
        },
        reviews: {
          Summary: {
            labels: {
              1: 'Mangelhaft', // "16371"
              2: 'Befriedigend', // "16372"
              3: 'Gut', // "18170"
              4: 'Sehr gut', // "16373"
              5: 'Ausgezeichnet', // "16369"
            },
            overallRating: 'Gesamtbewertung', // "16793"
            overallCondition: 'Gesamtzustand der Unterkunft', // "16919"
            overallCleanliness: 'Sauberkeit insgesamt', // "16895"
            overallService: 'Kundenservice vor Ort / Freundlichkeit', // "16915"
            basedOn_s: 'Gesamtergebnis, basierend auf einer Kundenbewertung|Gesamtergebnis, basierend auf {count} Kundenbewertungen', // "39677", "39678"
          },
          Item: {
            condition: 'Gesamtzustand der Unterkunft', // "16919"
            cleanliness: 'Sauberkeit insgesamt', // "16895"
            service: 'Kundenservice vor Ort / Freundlichkeit', // "16915"
            reviewDate: 'Bewertet am {date}', // "37526"
            stay: 'Aufenthalt im {month}', // "16602"
            stayWith: 'Aufenthalt mit {with} im {month}', // "39679"
            response: 'Antwort von {company}', // "37528"
            travelTips: 'Reisetipps', // "38123"
            extendedFamily: 'Erweitertem Familienkreis', // "16941"
            familyKids: 'Familie mit kleinen Kindern', // "16942"
            familyTeens: 'Familie mit Teenagern', // "16943"
            friends: 'Freunden', // "16944"
            group: 'Großer Gruppe', // "16945"
            partner: 'Ehepartner/Partner', // "16946"
            other: 'Andere', // 16939
            unaccompanied: 'Alleinreisender', // "16947",
            translate: 'Übersetzung anzeigen', // "39178"
            backToOriginal: 'Originaltext anzeigen', // "39179"
            byDeepl: 'Automatische Übersetzung von DeepL', // "39180"
          },
          Breadcrumb: {
            allReviews: 'Alle Kundenbewertungen', // "33584"
          },
          SearchTeaserTop: {
            chooseFromObjects: 'Wählen Sie aus einem breiten Angebot von Ferienhäusern und Ferienwohnungen', // "39897"
            title: 'Kundenbewertungen', // "16558"
            cta: 'Zu den Unterkünften', // "28932"
            niceObjects: 'Die schönsten Ferienhäuser und Ferienwohnungen.', // "18355"
            objects: 'Ferienhäuser & Ferienwohnungen', // "16773"
          },
        },
        details: {
          Title: {
            labels: {
              toprated: 'Top-Rated', // "25199"
              new: 'Neu', // "16274"
            },
          },
          Features: {
            pax_s: 'keine Person | eine Person | {count} Personen', // "37530", "37531", "18073"
            room_s: 'keine Zimmer | ein Zimmer | {count} Zimmer', // "37532", "37537", "18071"
            bedroom_s: 'ein Schlafzimmer | {count} Schlafzimmer', // "37533", "37536", "18069",
            bathroom_s: 'kein Bad | ein Bad | {count} Bäder | {count} slavic Bäder', // "37534", "37535", "38799", ??? slavic
          },
          Rating: {
            review_s: 'Aktuell liegen uns noch keine Kundenbewertungen zu diesem Objekt vor | eine Kundenbewertung | {count} Kundenbewertungen', // "18442","34497","37506"
            labels: {
              1: 'Mangelhaft', // "16371"
              2: 'Befriedigend', // "16372"
              3: 'Gut', // "18170"
              4: 'Sehr gut', // "16373"
              5: 'Ausgezeichnet', // "16369"
            },
          },
          ModalGallery: {
            share: 'Teilen',
            facebook: 'Facebook',
            twitter: 'Twitter',
            email: 'E-Mail',
            whatsapp: 'WhatsApp',
            images: 'Galerie mit {n} Bildern',
            clickForFullsize: 'Klicken Sie auf ein Bild, um es in voller Größe anzuzeigen.',
            next: 'Nächstes Bild',
            previous: 'Vorheriges Bild',
            loadError: 'Das Bild konnte nicht geladen werden.',
            bookmarkAdd: 'Merken', // "16707"
            bookmarkDel: 'Gemerkt', // "16884"
            open: 'Alle Bilder', // "38253"
            close: 'Schliessen', // "38254"
            inside: 'Innenbereich', // "18186"
            outside: 'Haus/Residenz', // "18182"
          },
          Gallery: {
            inside: 'Innenbereich', // "18186"
            outside: 'Haus/Residenz', // "18182"
          },
          Attributes: {
            views: {
              heading: 'Aussicht', // "39665" - Text/Formulierung noch nicht abgestimmt, engl. "Views"
              country_view: 'Landschaftsblick', // "18144"
              lake_view: 'Seeblick', // "18197"
              mountain_view: 'Bergsicht', // "18207"
              sea_view: 'Meersicht', // "18266"
            },
            distances: {
              heading: 'Distanzen', // "16572" - evt. besser "Entfernungen"?
              closeby: 'in direkter Nähe', // "18078"
              center: 'Nächster Ort / Ortsmitte {distance}', // "39666"
              public_transport: 'Öffentliche Verkehrsmittel {distance}', // "39667"
              beach: 'Strand {distance}', // "39668"
              sea: 'Meer {distance}', // "39669"
              lake: 'See {distance}', // "39670"
              golf: 'Golfplatz {distance}', // "39671"
              ski: 'Skipiste / Bergbahn {distance}', // "39672"
            },
            equipment: {
              heading: 'Ausstattung ', // "18163"
              aircondition: 'Klimaanlage', // "15827"
              balcony: 'Balkon / Terrasse', // "15828"
              bbq: 'Grill', // "15834"
              boat: 'Boot', // "28413"
              cots: 'Babybett', // "15843"
              dishwasher: 'Geschirrspüler', // "15848"
              fireplace: 'Kamin / Ofen', // "15880"
              fishing: 'Angelhaus', // "28464"
              certified_fishing: 'Zertifiziertes Angelhaus', // "28638"
              garage: 'Gemeinschaftsgarage', // "18137"
              hottub: 'Sprudelbad', // "15972"
              no_pets_allowed: 'Haustiere nicht erlaubt', // "37895"
              nonsmoking: 'Nichtraucher', // "15945"
              parking: 'Parkplatz', // "15905"
              patio: 'Balkon / Terrasse', // "15828"
              pets: 'Haustiere erlaubt', // "15931"
              pool: 'Pool', // "15932"
              pool_children: 'Kinderpool', // "15841"
              pool_indoor: 'Innenpool', // "15892"
              pool_private: 'Privater Pool', // "15936"
              sauna: 'Sauna', // "15942"
              single_garage: 'Einzelgarage', // "18275"
              suitable_for_wheelchairs: 'Rollstuhlgeeignet', // "18487"
              terrace: 'Balkon / Terrasse', // "15828"
              tv: 'TV', // "15967"
              washingmachine: 'Waschmaschine', // "15969"
              wlan: 'WiFi', // "15896",
              workation: 'Workation', // "39991",
            },
            sustainability: {
              heading: 'Nachhaltigkeit', // "41662"
              charging_station: 'E-Ladestation', // "41666"
              double_glazed_windows: 'Doppelt verglaste Fenster', // "41670"
              ecological_heating_system: '100% ökologisches Heizsystem', // "41664"
              green_space_garden: 'Garten', // "41671"
              led_bulbs: 'LED-Lampen', // "41668"
              recycling_station: 'Recycling', // "41667"
              renewable_electricity: '100% erneuerbarer Strom', // "41663"
              solar_panels: 'Sonnenkollektoren / Photovoltaik-Anlage', // "41665"
              water_efficient_showers: 'Wassersparende Toiletten / Duschen', // "41669"
            },
          },
          Description: {
            from: 'von', // "27798"
            fromto: 'ab', // "27873"
            to: 'bis', // "27799"
            // moreDetails: 'Weitere Informationen', // "38838" - unklar, ob das verwendet wird - ist aber in CF-Detailpage.cfc für AccommodationModalDescription.vue angelegt
            headings: {
              inside: 'Innenbereich', // "18186"
              outside: 'Außenbereich', // "18182"
              destination: 'Ortsbeschreibung', // "16653"
              general: 'Generell', // "15987"
              summer: 'Sommer', // "15988"
              winter: 'Winter', // "15989"
            },
            trustMessage: 'Die genaue Adresse zu Ihrem Feriendomizil erhalten Sie rechtzeitig vor Ihrer Reise mit den Reiseunterlagen.', // "27756"
          },
          Residence: {
            showAllAccommodations: 'Alle {count} Wohnungen in diesem Haus/dieser Residenz anzeigen', // "39680"
          },
          Map: {
            accuracyHint: 'Es wird das Zentrum des Ortes angezeigt, nicht die Lage des Ferienhauses.', // "18358"
            activation: 'Lassen Sie sich hier die Lage der Unterkunft anzeigen.', // "28091"
            activate: 'Kartenansicht aktivieren', // "28092"
          },
          Services: {
            chooseTravelPeriod: 'Bitte wählen Sie zuerst Anreise - Abreise', // "33423"
            included: 'Im Preis enthaltene Leistungen', // "18426"
            // excluded: 'Nicht im Preis enthalten (obligatorisch)', // "16586" - ??? unklar, ob das noch verwendet wird
            extracostsOnPlace: 'Obligatorische Nebenkosten, zu zahlen an den lokalen Dienstleister, nach tatsächlicher Belegung bzw. tatsächlichem Verbrauch', // "18220"
            optionalServices: 'Optionale Zusatzleistungen', // "18425"
            selfOrganised: 'Selbst zu organisieren', // "18447"
            co2Information: 'Mehr Informationen zur CO2 Kompensation', // "39410"
            inszInformation: 'Mehr Informationen zur Reiseversicherung', // "18098"
            inszRecommendation: 'Wir empfehlen Ihnen den Abschluss des ERV Reiseschutzes.', // "18373"
            susuInformation: 'Mehr Information', // "39529"
            loading: 'Daten werden geladen', // "39681"
            to: 'bis', // "38980"
            from: 'von', // "38981"
          },
          Sidebar: {
            messages: {
              EX: 'Bitte wählen Sie Ihren Reisetermin aus.', // "25130"
              LOADING: 'Daten werden geladen', // "39681"
              NA: 'Ihr Termin ist nicht verfügbar', // "18216"
              OK: 'Ihr Termin ist verfügbar.', // "18147"
              RQ: 'Auf Anfrage', // "16382"
            },
            placeholder: 'Bitte auswählen', // "18246"
            labels: {
              checkin: 'Anreise', // "16465"
              checkout: 'Abreise', // "24926"
              pax: 'Erwachsene und Kinder', // "39531"
            },
            price: {
              base: 'Preis pro Person und Nacht', // "38786"
              total: 'Gesamt für {duration} Nächte', // "38787"
            },
            saved: 'gespart', // "18265"
            priceFrom: 'ab', // "16618" - ACHTUNG: ih_cms_from / search ("ab"), nicht durcheinanderbringen mit 27798 ih_cms_from / detailpage ("von")
            eg: 'z.B.', // "25180"
            additionalCosts: 'Nebenkosten', // "18448"
            toOverview: 'Zur Übersicht', // "38788"
            paxNoDuration: 'Bitte wählen Sie Ihren Reisetermin aus.', // "25130"
            paxAfterDuration: 'Danach können Sie die Zahl der Reisenden ändern', // "25130"
            priceOccupancy: 'Der angezeigte Preis ist von der Belegung abhängig', // "38789"
            person_s: 'eine Person|{count} Personen', // "37530", "37531", "18073" - "38701" mit Pluralization in der Textresource, besser neu zusammensetzen wie pax_s: 'keine Person | eine Person | {count} Personen'
            button: {
              labels: {
                EX: 'Verfügbarkeit prüfen', // "16463"
                LOADING: 'Daten werden geladen', // "39681"
                OK: 'Zur Buchung', // "18125"
                RQ: 'Auf Anfrage', // "16382"
                NA: 'Nicht verfügbar', // "16758"
                OP: 'Option erstellen', // "16392"
              },
            },
            summary: 'Vor der Buchung erhalten Sie eine übersichtliche Zusammenfassung', // "18058"
          },
          MobileBar: {
            priceFrom: 'ab', // "16618" // ACHTUNG: ih_cms_from / search ("ab"), nicht durcheinanderbringen mit 27798 ih_cms_from / detailpage ("von")
            eg: 'z.B.', // "25180"
            person_s: 'eine Person|{count} Personen', // "37530", "37531", "18073" - "38701" mit Pluralization in der Textresource, besser neu zusammensetzen wie pax_s: 'keine Person | eine Person | {count} Personen'
            upToPax: 'bei Belegung bis {count} Pers.', // "37408"
            changeDate: 'Reisedaten ändern', // "39723"
            additionalCosts: 'zuzüglich ggf. Nebenkosten', // "18334"
            button: {
              labels: {
                EX: 'Verfügbarkeit prüfen', // "16463"
                LOADING: 'Daten werden geladen', // "39681"
                OK: 'Zur Buchung', // "18125"
                RQ: 'Auf Anfrage', // "16382"
                NA: 'Nicht verfügbar', // "16758"
                OP: 'Option erstellen', // "16392"
              },
            },
          },
          SocialShare: {
            button: 'Teilen', // "18269"
            whatsApp: 'Hier ist eine tolle Unterkunft auf {company}! Wie gefällt sie Dir?', // "41661"
          },
          SidebarLabelCard: {
            advantages: 'Weitere Vorteile', // "18097"
            lso: 'Service-Vor-Ort', // "18730"
            swissTourism: 'Holiday Comfort Schweizer Tourismus-Verband', // "18279"
            swisspeak: 'Family Destination Schweizer Tourismus-Verband', // "37131"
            casa: 'Wohlfühl-Ambiente', // "33406" - Hinwweis: Identifier casa-text wird nicht über CMS-Suche gefunden
          },
          SidebarContactCard: {
            furtherQuestions: 'Haben Sie weitere Fragen?', // "18160"
            '24_7': 'Wir sind 24 Stunden für Sie erreichbar!', // "18372"
            contactUs: 'Kontaktieren Sie uns', // "16607"
            counsel:
              'Sie erreichen uns hier rund um die Uhr - zur Buchungsberatung, vor Ihrer Anreise oder wenn Sie direkt vor Ort sind, stehen wir Ihnen jederzeit mit Rat und Tat zur Seite.', // "18143"
            generalRequest: 'Anfrageformular', // "16476"
            order: 'Urlaubsangebot bestellen', // "16477"
          },
          TrustPilot: {
            userRating: 'Unsere Kunden bewerten uns wie folgt', // "38454"
          },
          Vacancy: {
            legend: {
              title: 'Legende', // "39682"
              arrival: 'Mögliches Anreisedatum', // "18247"
              departure: 'Mögliches Abreisedatum', // "24946"
              discount: 'Profitieren Sie: Anreisedatum mit Spezialpreis', // "24951"
              noarrival: 'Verfügbar, jedoch kein Anreisedatum', // "24942"
              nodeparture: 'Verfügbar, jedoch kein Abreisedatum', // "24943"
              disabled: 'Nicht verfügbar', // "16758"
              yourSelection: 'Ihre Auswahl: Anreise & Abreisedatum', // "24948"
              available: 'Verfügbar, jedoch kein Anreisedatum', // "24942"
              na: 'Nicht buchbar', // "39683"
            },

            headline: {
              arrival: 'Anreisedatum wählen', // "24940"
              departure: 'Abreisedatum wählen', // "24941"
            },
            night_s: 'eine Nacht|{count} Nächte', // "37500", "37501"
          },
          Calendar: {
            arrivalDeparture_s: 'Anreise- und Abreisedatum (min. eine Nacht)|Anreise- und Abreisedatum (min. {count} Nächte)', // "39686", "39687"
            arrival_s: 'Mögliches Anreisedatum (min. eine Nacht)|Mögliches Anreisedatum (min. {count} Nächte)', // "39690", "39691"
            noArrival: 'Verfügbar, jedoch kein Anreisedatum', // "24942"
            departureOnly: 'Nur Abreise', // "18153"
            notAvailable: 'Nicht verfügbar', // "16758"
            selectedArrival_s:
              'Ihr gewählter Anreisetag mit einer minimalen Aufenthaltsdauer von einer Nacht|Ihr gewählter Anreisetag mit einer minimalen Aufenthaltsdauer von {count} Nächten', // "39692", "37525"
            dateInPast: 'Gewählter Tag liegt in der Vergangenheit', // "39693"
            arrivalBeforeDeparture: 'Anreisetag muss vor Abreisetag liegen', // "39694"
            noDepartureMinStay_s: 'Keine Abreise möglich, Mindestaufenthalt eine Nacht|Keine Abreise möglich, Mindestaufenthalt {count} Nächte', // "39695", "39696"
            noDepartureMaxStay_s: 'Keine Abreise möglich, maximale Aufenthaltsdauer eine Nacht|Keine Abreise möglich, maximale Aufenthaltsdauer {count} Nächte', // "39698","39697"
            noDeparture: 'Keine Abreise möglich', // "24933"
            departureAvailable: 'Abreise möglich', // "24931"
          },
          CancelConditions: {
            heading: 'Stornobedingungen', // '18129'
            until: 'Bis', // '17005'
            daysBefore: 'Tage vor Mietbeginn', // '18152'
            ofRental: 'des Mietpreises', // '18222'
            full: 'Tag vor Mietbeginn und am Anreisetag ist der gesamte Rechnungsbetrag geschuldet.', // '18151'
            moreThan: 'Bis zu', // '38908'
          },
          Reviews: {
            review_s: 'Aktuell liegen uns noch keine Kundenbewertungen zu diesem Objekt vor | eine Bewertung | {count} Bewertungen', // "18442","34497","37506"
            filters: 'Bewertungen von', // "39917"
            all: 'allen Gruppen', // "39699"
            choose: 'Bitte wählen', // "16940"
            extendedFamily: 'Erweitertem Familienkreis', // "16941"
            familyKids: 'Familie mit kleinen Kindern', // "16942"
            familyTeens: 'Familie mit Teenagern', // "16943"
            friends: 'Freunden', // "16944"
            group: 'Großer Gruppe', // "16945"
            partner: 'Ehepartner/Partner', // "16946"
            unaccompanied: 'Alleinreisender', // "16947"
            other: 'Andere', // 16939
            loading: 'Daten werden geladen', // "39681"
          },
          Breadcrumbs: {
            home: 'Home', // "16732"
          },
          MonthlyPrices: {
            tooltip: 'Die Grafik zeigt den durchschnittlichen Mietpreis für jeden Monat als Index. Kleinere Balken bedeuten niedrigere Preise.',
            title: 'Preisindex',
            info: 'Preise abhängig von Zeitraum und Personenzahl',
          },
          UserSurvey: {
            title: 'Was ist Ihnen besonders wichtig?', // 42820
            text: 'Gerne würden wir mehr über Ihre Bedürfnisse erfahren. Worauf legen Sie beim Urlaub im Ferienhaus besonderen Wert? Teilen Sie uns Ihre Vorlieben in einer kurzen Umfrage mit (Dauer: ca. 5 Minuten)', // 42821
            buttons: {
              open: 'Jetzt teilnehmen', // 42822
              postpone: 'Vielleicht später', // 42824
            },
          },
        },
        newsletter: {
          Success: {
            // ??? Grundsatzfragen noch zu besprechen, weil an anderen Stellen auch noch verwendet
            success: 'Erfolg', // "39661"
            transmission: 'Die Daten wurden erfolgreich übermittelt. Vielen Dank!', // "16606"
            continue: 'Sie können Ihre Suche hier fortführen.', // "39662"
            inspiration: 'Suchen Sie nach Inspiration?', // "25087"
          },
          Error: {
            errorText: 'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte später nochmals.', // "16605"
          },
        },
        favorites: {
          Actions: {
            catalog: {
              privacyPolicy: 'Datenschutzerklärung', // "38826"
              create: 'Erstellen Sie Ihren persönlichen Katalog (PDF)', // "16556"
              title: 'Persönlicher Katalog', // "17053"
              fillForm: 'Füllen Sie folgendes Formular aus. Ihr persönlicher Katalog wird Ihnen dann innerhalb weniger Minuten als PDF per eMail zugestellt.', // "17263"
              emailError: 'Bitte geben Sie eine gültige E-Mailadresse an.', // "18232"
              salutation: 'Anrede', // "16144"
              firstname: 'Vorname', // "16139"
              lastname: 'Nachname', // "16140"
              email: 'E-Mail', // "16136"
              mr: 'Herr', // "16146"
              ms: 'Frau', // "16147"
              pax: 'Gäste inkl. Kinder', // "18172"
              dates: 'Reisezeitraum', // "18292"
              newsletter: 'Melden Sie sich zu unserem Newsletter an', // "41154"
              gdprNewsletter:
                'Wir verwenden Ihre eMail Adresse, Anrede sowie Vor- und Nachnamen auf Grundlage von Art. 6 Abs. f, DSGVO zum Zweck der Zusendung des mit diesem Formular bestellten Newsletters und zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO finden Sie in unserer {dse}.', // "39676"
              gdprRegular:
                'Wir verwenden Ihre in diesem Formular eingegebenen Daten auf Grundlage von Art. 6 Abs. b) DSGVO zum Zweck des Abschlusses und zur Abwicklung eines Vertrags und auf Grundlage von Art. 6 Abs.1 Buchst f) zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO erhalten Sie in unserer {dse}.', // "39674"

              send: 'Absenden', // "16614"
            },
            sendToFriend: 'Merkliste an Freunde senden', // "16980"
            mailSubject: 'Meine liebsten {company} Objekte', // "40753"
            removeAll: 'Alle Objekte von der Merkliste entfernen', // "17260"
          },
          Breadcrumbs: {
            home: 'Home', // "16732"
            favorites: 'Merkliste', // "18208"
          },
          Error: {
            text: 'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte später nochmals.', // "16605"
          },
          Filters: {
            country: 'Land', // "16481"
            region: 'Region', // "16881"
            place: 'Ort', // "16815"
            pax: 'Reisende', // "39218"
            all: 'Alle', // "39700"
            any: 'beliebig', // "16356"
          },
          Infobox: {
            heading: 'Ihre Merkliste enthält Unterkünfte mit belegungsabhängigen Preisen.', // "35764"
            info: 'Die hier dargestellten Preise beziehen sich auf eine Belegung mit einer Person und können bei anderer Belegung abweichen.', // "39702"
            ok: 'OK', // "39703"
          },
          Success: {
            text: 'Herzlichen Dank, Ihre Daten wurden erfolgreich übermittelt.', // "16606"
          },
        },
        search: {
          newFilter: {
            filterTitle: {
              results: 'Ein Ergebnis|{count} Ergebnisse',
              for: 'für',
              sort: 'Sortierung:',
              recommendation: 'Unsere Empfehlung',
            },
            filterBlock: {
              filter: 'Filter',
            },
            filterPopup: {
              heading: 'Suche verfeinern',
              clearAll: 'Alles löschen',
              facets: 'Über 1000 Ergebnisse',
              results: 'Ein Ergebnis|{count} Ergebnisse',
              apply: 'Anwenden',
            },
            filterTagSection: {
              facets: 'Über 1000 Ergebnisse',
              results: 'Ein Ergebnis|{count} Ergebnisse',
              apply: 'Anwenden',
              rooms: 'Räume',
              facilities: 'Ausstattung',
              price: 'Preis',
              houseType: 'Housetyp',
              distances: 'Distanzen',
              activities: 'Aktivitäten',
            },
            filterSection: {
              rooms: 'Räume',
              facilities: 'Ausstattung',
              price: 'Preis',
              houseType: 'Haustyp',
              distances: 'Distanzen',
              activities: 'Aktivitäten',
            },
            filterTag: {
              rooms: 'Räume',
              facilities: 'Ausstattung',
              price: 'Preis',
              houseType: 'Haustyp',
              distances: 'Distanzen',
              activities: 'Aktivitäten',
            },
            filterSections: {
              activities: {
                skiing: 'Skifahren / Snowboard',
                hiking: 'Wandern',
                golfing: 'Golf',
                cycling: 'Radfahren',
                wellness: 'Wellness',
                tennis: 'Tennis',
                surfing: 'Surfen',
                sailing: 'Segeln',
                mountainbiking: 'Mountain-Biking',
                riding: 'Reiten',
                crosscountryskiing: 'Langlauf',
                holidayTypeHeading: 'Holidaytyp',
                familyfriendly: 'Familienfreundlich',
                holiday_resort: 'Ferienanlage',
                residence: 'Residenzen',
                citytrips: 'Städtereise',
                utoring: 'Utoring Residenzen',
                fishingHeading: 'Fishing',
                fishing: 'Angelhaus',
                fishing_certified: 'Zertifiziertes Angelhaus',
                showMore: 'Alle anzeigen',
                showLess: 'Weniger anzeigen',
              },
              distances: {
                sea: 'Nähe zum Meer',
                lake: 'Nähe zu einem See',
                ski: 'Nähe zu Skipiste/Bergbahn',
                center: 'Nächster Ort/Ortsmitte',
                any: 'Egal',
              },
              facilities: {
                wlan: 'WiFi',
                aircondition: 'Klimaanlage',
                parking: 'Parkplatz',
                garage: 'Garage',
                'balcony-or-terrace': 'Balkon/Terrasse',
                dishwasher: 'Geschirrspüler',
                washingmachine: 'Waschmaschine',
                tv: 'TV',
                sea_or_lake_view: 'Meerblick/Seeblick',
                bbq: 'Grill',
                boat: 'Boot',
                cots: 'Babybett',
                hottub: 'Sprudelbad',
                fireplace: 'Kamin/Ofen',
                sauna: 'Sauna',
                poolHeading: 'Pool',
                pool: 'Pool',
                pool_children: 'Kinderpool',
                pool_indoor: 'Innenpool',
                pool_private: 'Privater Pool',
                showMore: 'Alle anzeigen',
                showLess: 'Weniger anzeigen',
              },
              houseType: {
                apartment: 'Ferienwohnung',
                house: 'Ferienhaus',
                villa: 'Villa',
                chalet: 'Chalet',
                farmhouse: 'Landhaus',
                detached: 'Alleinstehendes Haus',
                specialsHeading: 'Specials',
                cheapcheap: 'Gut und günstig',
                particularHeading: 'Suchen Sie das Besondere?',
                casa: 'CASA Entdecken',
                swiss_peak: 'SWISSPEAK Resorts',
                workation: 'Workation',
                sustainable: 'Nachhaltige Unterkünfte',
                starsHeading: 'Stars',
              },
              price: {
                priceMin: 'Min',
                priceMax: 'Max',
                specialsHeading: 'Specials',
                last_minute: 'Last Minute',
                special_offer: 'Alle Sonderangebote',
                early_booker: 'Frühbucher Rabatt',
                'discount-20': '≥20% Rabatt',
              },
              rooms: {
                bedrooms: 'Schlafzimmer',
                bathrooms: 'Badezimmer',
                rooms: 'Räumlichkeiten',
                pets: 'Haustiere Erlaubt',
              },
            },
          },
          filter: {
            country: 'Land', // "16481"
            region: 'Region', // "16881"
            place: 'Ort', // "16815"
            checkin: 'Anreise', // "24947"
            checkout: 'Abreise', // "24926"
            trip: 'Reisezeitraum', // "18292"

            pax: 'Erwachsene und Kinder', // "39531"
            bedrooms: 'Schlafzimmer', // "16379"
            bathrooms: 'Badezimmer', // "17373"

            house: 'Ferienhaus', // "15984"
            apartment: 'Ferienwohnung', // "15980"
            villa: 'Villa', // "15986"
            chalet: 'Chalet', // "15981"
            farmhouse: 'Landhaus', // "15983"
            // residence: 'Sterne',
            // holiday_resort: 'Sterne',
            studio: 'Studio', // "15835"
            detached: 'Alleinstehendes Haus', // "15847"

            casa: 'CASA entdecken', // "28908"
            swiss_peak: 'SWISSPEAK Resorts', // "28909"
            workation: 'Workation', // "39991",
            sustainable: 'Nachhaltige Unterkünfte', // "41647"

            stars: 'Sterne-Klassifizierung', // "15961"

            pool: 'Pool', // "15932"
            pool_children: 'Kinderpool', // "15841"
            pool_indoor: 'Innenpool', // "15892"
            pool_private: 'Privater Pool', // "15936"

            pets: 'Haustiere erlaubt', // "18229"

            sea: 'Nähe zum Meer', // "15875"
            lake: 'Nähe zum See', // "15874"
            ski: 'Nähe zu Skipiste / Bergbahn', // "15876"
            center: 'Nächster Ort / Ortsmitte', // "15872"

            wlan: 'WLAN', // "15896"
            aircondition: 'Klimaanlage', // "15827"
            parking: 'Parkplatz', // "15905"
            garage: 'Garage', // "28461"
            'balcony-or-terrace': 'Balkon / Terrasse', // "15828"
            dishwasher: 'Spülmaschine', // "15848"
            washingmachine: 'Waschmaschine', // "15969"
            tv: 'Fernseher', // "15967"
            sea_or_lake_view: 'Seeblick', // "15943"
            bbq: 'Grill', // "15834"
            boat: 'Boot', // "28413"
            cots: 'Babybett', // "15843"
            hottub: 'Sprudelbad', // "15972"
            fireplace: 'Kamin / Ofen', // "15880"
            sauna: 'Sauna', // "15942"
            wheelchair: 'Rollstuhlgeeignet', // "15971"
            charging_station: 'E-Ladestation', // "41666"

            last_minute: 'Last Minute', // "15952"
            special_offer: 'Alle Sonderangebote', // "15948"
            'discount-20': '≥ 20% Rabatt', // "15950"
            cheapcheap: 'Gut und günstig', // "15949"
            early_booker: 'Frühbucher Rabatt', // "15951"

            familyfriendly: 'Familienfreundlich', // "8515"
            holiday_resort: 'Ferienanlage', // "8526"
            residence: 'Residenzen', // "8574"
            citytrips: 'Städtereise', // "15842"
            utoring: 'Utoring Residenzen', // "8604"

            fishing: 'Angelhaus', // "28464"
            fishing_certified: 'Zertifziertes Angelhaus', // "28638"

            skiing: 'Skifahren / Snowboard', // "15944"
            hiking: 'Wandern', // "15888"
            golfing: 'Golf', // "15886"
            cycling: 'Radfahren', // "15846"
            wellness: 'Wellness', // "15970"
            tennis: 'Tennis', // "15964"
            surfing: 'Surfen', // "15962"
            sailing: 'Segeln', // "15941"
            mountainbiking: 'Mountainbiken', // "15900"
            riding: 'Reiten', // "15940"
            crosscountryskiing: 'Langlaufen', // "15845"

            q: 'Ihre Suche', // "39538"

            buckets: {
              distances: {
                100: '100m', // "39910" NEU
                500: '500m', // "39911" NEU
                1000: '1km', // "39912" NEU
                2000: '2km', // "39913" NEU
                5000: '5km', // "39914" NEU
                10000: '10km', // "39915" NEU
              },
            },
            labels: {
              refine: 'Suche verfeinern', // "18258"
              where: 'Wohin möchten Sie?', // "18377"
              when: 'Wann möchten Sie verreisen?', // "18376"
              pax: 'Wie viele Personen verreisen?', // "39918"

              accomtype: 'Ferienhaustyp', // "18369"
              particular: 'Suchen Sie das besondere?', // "28910"

              pool: 'Swimming-Pool', // "15933"
              pet: 'Möchten Sie Ihr Haustier mitnehmen?', // "18382"
              distances: 'Distanzen', // "16572"

              facilities: 'Ausstattung', // "15965"
              specials: 'Angebote', // "15954"
              maxPrice: 'Max. Preis pro Aufenthalt', // "15978"
              fishing: 'Planen Sie einen Angelurlaub?', // "28514"
              holidaytype: 'Urlaubsart', // "15889"
              activities: 'Aktivitäten vor Ort', // "39894"
              atleast: 'mind. {value}', // "39895"
            },
          },
          InfoWindowLegacy: {
            pax_s: 'keine Person | eine Person | {count} Personen', // "37530", "37531", "18073"
            room_s: '{count} Raum | {count} Räume', // "37532", "37537", "18071"
            night_s: 'eine Nacht|{count} Nächte', // "37500", "37501"
            discover: 'Entdecken', // "18157"
            objects: '{count} Angebote', // "39916"
            priceFrom: 'ab', // "16618"
          },
          RatingBox: {
            title: 'Kundenbewertungen', // "16558"
            labels: {
              1: 'Mangelhaft', // "16371"
              2: 'Befriedigend', // "16372"
              3: 'Gut', // "18170"
              4: 'Sehr gut', // "16373"
              5: 'Ausgezeichnet', // "16369"
            },
            ratingText: 'Kunden haben diese Unterkünfte mit {ratingaverage} von maximal 5 Punkten bewertet.', // "24893"
            numberRatingsText: 'Basiert auf {ratingcount} Bewertungen.', // "24897"
          },
          area: {
            heading: 'Suche', // "16972" ??? Suchen
            noResults: 'Leider konnten wir kein passendes Suchresultat finden.', // "18427"
            modifyFilters: 'Bitte versuchen Sie es erneut, indem Sie Ihre Suchkriterien anpassen bzw. entfernen.', // "18428"
            addFilters: 'Filter hinzufügen', // "39896"
            filters: 'Filter',
            removeAllFilters: 'Alle Filter entfernen', // 18260
            map: 'Karte', // "16665" - auch "18201"
            list: 'Liste', // "16648"
            sorting: {
              heading: 'Sortierung', // "39718"
              recommendation: 'Unsere Empfehlung', // "18223"
              bestsellers: 'Bestseller', // "18120"
              age: 'Neueste zuerst', // "18209"
              rating: 'Kundenbewertungen', // "18148"
              paxAsc: 'Anzahl Personen (aufsteigend)', // "18217"
              paxDesc: 'Anzahl Personen (absteigend)', // "18218"
              priceAsc: 'Preis (aufsteigend)', // "39719"
              priceDesc: 'Preis (absteigend)', // "39720"
            },
          },
          Breadcrumbs: {
            home: 'Home', // "16732"
          },
          searchTeaser: {
            headingDestination: '{geoname} - Ferienhaus & Ferienwohnung mieten für Ihren Urlaub:', // "20278",
            headingDestinationNoPlaceholder: 'Ferienhaus & Ferienwohnung mieten für Ihren Urlaub:', // "42062",
            headingFilter: '{destandfilter} - Ferienhaus und Ferienwohnung in:', // "39930"
            headingFilterNoPlaceholder: 'Ferienhaus und Ferienwohnung in:', // "42061"
          },
          NewsletterSignup: {
            cta: 'Anmelden',
            text: 'Melden Sie sich zu unserem Newsletter an',
          },
          ObjectTeaser: {
            destination: {
              reviews: {
                title: 'Top-rated holiday houses in {destination} for unforgettable getaways',
                description:
                  'Experience the best-rated holiday houses in {destination} for an unforgettable holidays, blending superior quality, top service, and outstanding reviews.',
              },
              sustainable: {
                title: 'Sustainable holiday homes in {destination} for nature-friendly retreats',
                description:
                  'Embrace sustainable living in our eco-friendly vacation rentals in {destination}, combining green practices and environmental harmony for perfect vacation',
              },
              wlan: {
                title: 'Vacation rentals in {destination} with high-speed WiFi connectivity',
                description: 'Stay connected in our holiday homes in {destination} boasting high-speed WiFi connectivity during your stay.',
              },
              parking: {
                title: 'Holiday homes with convenient parking access in {destination}',
                description: 'Holiday homes with convenient parking access in {destination}',
              },
              aircondition: {
                title: 'Cool comfort: accommodations with refreshing air-conditioning in {destination}',
                description: 'Enjoy cool comfort in our holiday houses in {destination} equipped with refreshing air conditioning for ultimate relaxation.',
              },
            },
            pool: {
              reviews: {
                title: 'Pool paradise: top-rated holiday accommodations with pool in {destination}',
                description: 'Discover top-rated holiday houses with pool in {destination} - your perfect retreat for unforgettable, leisure-filled vacations!',
              },
              sustainable: {
                title: 'Discover eco-friendly accommodations with pool in {destination} for perfect holiday',
                description: 'Indulge in our sustainable vacation rentals with pools in {destination} for an unforgettable, eco-conscious escape.',
              },
              wlan: {
                title: 'Vacation rentals with pool and WiFi in {destination}',
                description:
                  'Stay connected and refreshed in our vacation homes in {destination}, offering poolside relaxation and high-speed WiFi for your convenience!',
              },
              parking: {
                title: 'Holiday homes with pool and convenient parking in {destination}',
                description:
                  'Unwind in our holiday houses with swimming pools in {destination}, each providing your own parking space for ultimate convenience.',
              },
              aircondition: {
                title: 'Refreshing comfort: apartments & cottages with pool & air-conditioning in {destination}',
                description:
                  'Discover comfort and coolness in our apartments in {destination}, featuring refreshing pools and air-conditioning for your ultimate holiday!',
              },
            },
            sea: {
              reviews: {
                title: 'Seaside relaxation: top-rated accommodations by the sea in {destination}',
                description:
                  'Immerse yourself in the serene beauty of our top-rated holiday accommodations by the sea in {destination} for an unforgettable retreat!',
              },
              sustainable: {
                title: 'Eco-sea holidays: sustainable homes near a coastline in {destination}',
                description:
                  'Embrace eco-friendly living in our sustainable accommodations in {destination} close to the sea, blending comfort with care for nature!',
              },
              wlan: {
                title: 'Vacation rentals by the sea with WiFi in {destination}',
                description: 'Stay connected in our seaside holiday homes in {destination}, offering high-speed WiFi to enhance your relaxing escape!',
              },
              parking: {
                title: 'Holiday homes by the sea with parking in {destination}',
                description: 'Relish in our seaside holiday rentals in {destination}, providing you with unparalleled views and your own parking convenience.',
              },
              aircondition: {
                title: 'House rentals by the sea with air-conditioning in {destination}',
                description: 'Experience refreshing sea breezes and cool comfort in our holiday apartments by the sea in {destination} with air-conditioning.',
              },
            },
            pets: {
              reviews: {
                title: 'Pet paradise: top-rated holiday homes for furry friends in {destination}',
                description:
                  'Your pet-friendly paradise awaits in our top-rated holiday accommodations in {destination}, perfectly tailored for your furry friends.',
              },
              sustainable: {
                title: 'Green getaways: sustainable, pet-welcome holiday homes await in {destination}',
                description: 'Share eco-friendly comfort with your pet in our sustainable holiday houses in {destination}, where nature and nurture coexist.',
              },
              wlan: {
                title: 'Perfect pet-friendly homes with WiFi in {destination}',
                description: 'Stay connected in our perfect pet-welcoming holiday homes in {destination}, blending comforts with high-speed WiFi for you.',
              },
              parking: {
                title: 'Pet-Friendly accommodations with convenient parking in {destination}',
                description: 'Delight in our pet-friendly apartments & cottages in {destination}, offering ample parking for a seamless and comfortable stay.',
              },
              hiking: {
                title: 'Dog-friendly apartments & houses for hiking adventures in {destination}',
                description:
                  'Discover our pet-friendly holiday accommodations in {destination}, ideally located for unforgettable dog-friendly hiking adventures.',
              },
            },
            skiing: {
              reviews: {
                title: 'Top-rated accommodations for skiing holidays in {destination}',
                description:
                  'Embrace the winter fun with our top-rated holiday accommodations in {destination}, perfectly situated for your unforgettable skiing holidays.',
              },
              sustainable: {
                title: 'Eco-friendly retreats: sustainable homes for skiing holidays in {destination}',
                description:
                  'Choose our sustainable holiday homes in {destination} for your skiing adventures, where eco-friendly living meets mountain thrill!',
              },
              wlan: {
                title: 'Stay connected: perfect skiing holiday homes with WiFi in {destination}',
                description:
                  'Stay connected on the slopes in our perfect vacation rentals for skiing in {destination}, featuring high-speed WiFi for your convenience.',
              },
              fireplace: {
                title: 'Winter comfort: apartments & chalets with cozy fireplaces in {destination}',
                description: 'Warm up in our apartments & chalets at the ski area in {destination}, offering cozy fireplaces for your perfect winter retreat.',
              },
              cheapcheap: {
                title: 'Affordable skiing retreats: cheap holiday apartments and houses in {destination}',
                description:
                  'Experience affordable comfort in our cheap holiday apartments and houses in {destination}, ideally located for your exciting skiing adventures.',
              },
            },
          },
          content: {
            WeatherInfo: {
              hr_s: '{count} Std. | {count} Std. | {count} Std.', // NEU
              day_s: '{count} Tage | {count} Tag | {count} Tage', // NEU
              min: 'min. {value}°', // NEU
              max: 'max. {value}°', // NEU
            },
            SightsAndContent: {
              showMore: 'Mehr anzeigen', // "16989"
              introHeading: 'Entdecke Urlaubsziel {destination}', // 42537
            },
          },
        },
        PageHome: {
          recommendations: 'Unsere Empfehlungen', // "39777"
          topCountries: 'Top Länder', // "18362"
          topRegions: 'Top Regionen', // "18363"
          inspirations: 'Ferienideen wie für Sie gemacht', // "18180"
          all_countries: 'Alle Reiseländer im Überblick', // "18271"
          all_ideas: 'Alle Urlaubsideen anschauen', // "18272"
          searchTeaser: 'Beliebte Reiseziele & Urlaubsideen', // "42012"
        },
        PageContent: {
          menu: 'Menu', // "20275"
        },
        PageReviews: {
          userReviews: 'Kundenbewertungen', // "16558"
          guaranteedGenuine: 'Garantiert echt', // "28964"
          guaranteedGenuineText:
            'Nur Kunden, die tatsächlich in einer {company} Unterkunft ihren Urlaub verbracht haben, können auch eine Bewertung für diese Unterkunft abgeben. Dazu bekommen unsere Kunden nach ihrer Rückreise eine E-Mail mit einem individuellen Link gesendet, der zum passenden Bewertungsformular führt.', // "28965"
        },
      },
      common: {
        BrowserUpdate: {
          outdated: 'Ihr Browser ist nicht aktuell.', // "39684"
          switch: 'Um diese Seite bestmöglich nutzen zu können, wechseln Sie bitte zu einem aktuellen Browser.', // "39685"
        },
        Chatbot: {
          header: 'Wie kann ich Ihnen helfen?', // "40658"
          introduction:
            'Ich bin Ihr digitaler Chat-Assistent und unterstütze Sie rund um die Uhr bei Ihren Fragen. Hier geht es zu unseren <a href="{link}" target="_blank">Datenschutzhinweisen</a>.', // "40659"
          placeholder: 'Nachricht hier eingeben...', // "40743"
          sending: 'Wird gesendet...', // "40744"
          delivered: 'Gesendet', // "40745"
          days: 'vor {value} Tag(en)', // "40746"
          hours: 'vor {value} Std.', // "40747"
          minutes: 'vor {value} Min.', // "40748"
          now: 'Gerade eben', // "40749"
          send: 'Senden', // "40750"
        },
      },
      topCountries: {
        headline: 'Top Länder', // "18362"
        countries: 'Alle Reiseländer im Überblick', // "18271"
        ideas: 'Alle Urlaubsideen anschauen', // "18272"
      },
      ui: {
        alert: {
          note: 'Hinweis', // "39813"
          importantNote: 'Wichtiger Hinweis', // "39814"
          importantObjectNote: 'Wichtiger Hinweis zu diesem Objekt', // "16273"
        },
        BookingTeaser: {
          completeBooking: 'Buchung abschließen',
          pax_s: 'keine Person | eine Person | {count} Personen',
        },
      },
      notifications: {
        signedIn: {
          text: 'Sie sind angemeldet',
        },
      },
    },
    pages: {
      catalogues: {
        ok: 'OK', // "39704" - ??? nur de und en
        alert: 'Hinweis', // "39705" - ??? nur de und en
        errors: {
          wrongFormat: 'Falsches Format eines AccommodationCode im Katalog-Event: {code}', // "39706" - nur de und en
          notFound: 'Keine Informationen zu folgender Accommodation gefunden: {code}', // "39707" - nur de und en
          parseError: 'Fehler beim JSON.parse des Katalog-Events: {error}', // "39708" - nur de und en
          convertError: 'Katalog-Event konnte nicht in Array umgewandelt werden: {json}', // "39709" - nur de und en
          general: 'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte später nochmals.', // "39710" - nur de und en
        },
      },
      emails: {
        _hash: {
          optin: {
            title: 'Anmeldebestätigung', // "39783"
            success: {
              heading: 'Ihre Anmeldung war erfolgreich.', // "39784"
              text: 'Sie können sich nun weiter umsehen.', // "39785"
            },
            error: {
              heading: 'Leider ist bei der Bestätigung ein Fehler aufgetreten.', // "39786"
              text: 'TODO: Hier sollte Text zum nächsten Schritt folgen', // "39787"
            },
            recommendations: 'Unsere Empfehlungen', // "39777"
          },
          unsubscribe: {
            title: 'Newsletter Abmeldung', // "39711"
            heading: 'Ihre Abmeldung ist fast abgeschlossen. Würden Sie uns verraten, warum Sie keinen Newsletter mehr von uns erhalten möchten?', // "39712"
            options: {
              noreason: 'Ich möchte keine Angaben machen', // "39808"
              frequency: 'Newsletter kommt zu oft', // "39713"
              topics: 'Themenwahl entspricht nicht meiner Vorstellung', // "39809"
              needs: 'Ferienhäuser/-wohnungen entsprechen nicht meinen Bedürfnissen', // "39810"
              newsletters: 'Ich erhalte generell zu viele Newsletter', // "39811"
              // newemail: 'Neue E-Mail-Adresse', // "39714" // wird derzeit nicht verwendet, aber behalten, da evtl wieder relevant in Zukunft
            },
            submit: 'Jetzt abmelden', // "39807"
            success: {
              heading: 'Ihre Newsletter-Abmeldung war erfolgreich', // "39788"
              text: 'Sie können sich nun weiter umsehen.', // "39789"
            },
            error: {
              heading: 'Leider ist  ein Fehler aufgetreten.', // "39790"
              note: 'Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal.', // "39791"
              button: 'Zurück zur Abmeldung', // "39792"
            },
            recommendations: 'Unsere Empfehlungen', // "39777"
          },
          confirm: {
            title: 'E-Mail-Bestätigung', // "39801"
            success: {
              heading: 'Ihre E-Mail-Adresse konnte erfolgreich bestätigt werden', // "39793"
              text: 'Sie können sich nun weiter umsehen.', // "39794"
            },
            error: {
              heading: 'Leider ist bei der Bestätigung Ihrer E-Mail-Adresse ein Fehler aufgetreten', // "39795"
              text: 'Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal.', // "39796"
            },
            recommendations: 'Unsere Empfehlungen', // "39777"
          },
        },
      },
      newsletter: {
        error: 'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte später nochmals.', // "16605"
        emailError: 'Bitte geben Sie eine gültige E-Mail Adresse an', // "18232"
        title: 'Newsletter', // "18210"
        sub: 'Ab sofort nichts mehr verpassen!', // "18159"
        orderText:
          'Möchten Sie gerne regelmäßig über Aktionen, Neuigkeiten und besondere Angebote informiert werden? Dann abonnieren Sie jetzt unseren Newsletter.', // "39772"
        orderUnsubscribePossible: 'Sie können ihn jederzeit wieder abbestellen.', // "39797"
        gdpr: 'Wir verwenden Ihre eMail Adresse, Anrede sowie Vor- und Nachnamen auf Grundlage von Art. 6 Abs. f, DSGVO zum Zweck der Zusendung des mit diesem Formular bestellten Newsletters und zur Zusendung weiterer Informationen zu unserem Angebot. Weitere Informationen nach Art. 13 DSGVO finden Sie in unserer {dse}.', // "39676"
        privacyPolicy: 'Datenschutzerklärung', // "38826"
        submit: 'Absenden', // "16614"
        salutation: 'Anrede', // "16144"
        firstname: 'Vorname', // "16139"
        lastname: 'Nachname', // "16140"
        email: 'E-Mail', // "16136"
        mr: 'Herr', // "16146"
        ms: 'Frau', // "16147"

        success: {
          heading: 'Vielen Dank für Ihre Anmeldung', // "39773"
          transmission: 'Ihre Daten wurden erfolgreich übermittelt.', // "39774"
          continue: 'Sie erhalten in Kürze eine E-Mail, um die angegebene E-Mail-Adresse zu bestätigen.', // "39775"
          inspiration: 'Weiter zur Startseite', // "39776"
        },
        recommendations: 'Unsere Empfehlungen', // "39777"
      },
      favorites: {
        title: 'Merkliste', // "18208"
        loading: 'Daten werden geladen', // "39681"
        noList: 'Sie haben noch keine Objekte auf Ihre Merkliste gesetzt.', // "39715"
        filters: 'Liste filtern', // "39716"
        close: 'Schließen', // "34706"
        options: 'Weitere Optionen', // "39717"
        sorting: {
          heading: 'Sortierung', // "39718"
          // ??? recommendation: "Unsere Empfehlung" // "18223"
          // ??? bestsellers: "Bestseller" // "18120"
          age: 'Neueste zuerst', // "18209"
          rating: 'Kundenbewertungen', // "18148"
          paxAsc: 'Anzahl Personen (aufsteigend)', // "18217"
          paxDesc: 'Anzahl Personen (absteigend)', // "18218"
          priceAsc: 'Preis (aufsteigend)', // "39719"
          priceDesc: 'Preis (absteigend)', // "39720"
        },
        removeAll: 'Sind Sie sicher, dass Sie alle Elemente von Ihrer Merkliste entfernen wollen?', // "39721"
        notAvailable: 'Das Objekt {accomId} ist leider nicht mehr verfügbar.', // "39722"
      },
    },
  },
  components: {
    cookieConsent: {
      cookiesTitle: 'Wir gehen sorgfältig mit Ihren Daten um', // "42266"
    },
  },
  plugins: {
    components: {
      FreeCancellationInfo: {
        title: 'Aktion', // 42825
        text: 'kostenlose Stornierung bis', // 42826
        tooltipTitle: 'Kostenlose Stornierung möglich', // 42827
        tooltipText:
          'Bis {date} können Sie kostenlos stornieren und erhalten den gezahlten Betrag erstattet. Diese Regelung gilt nur für Anreisetermine, die mehr als 60 Tage in der Zukunft liegen. Für alle übrigen Reisetermine gelten die untenstehenden Stornobedingungen.', // 42828
      },
    },
  },
}

export default translations

export type { translations as TranslationsType }

export type RecursiveKeyOf<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `${TKey}`>
}[keyof TObj & (string | number)]

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<TObj[TKey], `['${TKey}']` | `.${TKey}`>
}[keyof TObj & (string | number)]

type RecursiveKeyOfHandleValue<TValue, Text extends string> = TValue extends any[]
  ? Text
  : TValue extends object
    ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
    : Text
