<template>
  <section v-if="!useParams().iframe" class="container-fluid-lg" :class="sectionclasses">
    <div class="flex items-center md:justify-center">
      <h2 class="text-large mr-2 grow text-thm sm:text-xl md:text-center md:text-2xl" :class="headlineclasses">{{ icons.headline }}</h2>
      <WebccButton variant="theme" size="sm" class="ml-auto" :class="buttonclasses" @click="toInfos"> {{ icons.linkLabel }} </WebccButton>
    </div>

    <ul class="mt-2 grid min-h-[3rem] w-full justify-center space-x-2 bg-bgr-100 px-1 sm:min-h-[13rem] sm:grid-cols-3 md:min-h-[8rem] md:grid-cols-6">
      <li
        v-for="(usp, i) in icons.items"
        :key="i"
        class="items-center py-1 sm:flex sm:flex-col md:py-4"
        :class="showRandomField === usp.key ? 'flex flex-row' : 'hidden'"
      >
        <WebccIcon :name="`usp/${usp.key}`" class="m-2 h-9 w-9 fill-current stroke-none text-thm sm:h-10 sm:w-10 md:h-11 md:w-11" />
        <span class="px-1 text-center text-sm sm:text-sm">{{ usp.label }}</span>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.elements.USPCorona.'
defineExpose({ trPrefix })

const props = defineProps<{ icons: USPBar; boxed?: boolean }>()

const fields = ref([
  { field: 'car', mobileAllowed: false },
  { field: 'privacy', mobileAllowed: true },
  { field: 'independence', mobileAllowed: true },
  { field: 'cleaning', mobileAllowed: false },
  { field: 'key', mobileAllowed: true },
  { field: 'support', mobileAllowed: true },
])

const showRandomField = ref('')

onMounted(() => {
  const allowed = fields.value.filter((f) => f.mobileAllowed)
  showRandomField.value = allowed[Math.floor(Math.random() * allowed.length)].field
})

const sectionclasses = computed(() => {
  return props.boxed ? 'pt-2 mt-2 md:mt-8 rounded shadow' : 'mt-2 md:mt-4'
})
const headlineclasses = computed(() => {
  return props.boxed ? 'ml-2 md:ml-0' : ''
})
const buttonclasses = computed(() => {
  return props.boxed ? 'mr-2 md:mr-0 md:right-2' : 'md:right-0'
})

function toInfos() {
  window.scrollTo(0, 0)
  navigateTo(props.icons.link.href)
}
</script>
